import { useState, useEffect } from "react";
import { API } from "aws-amplify";

export default function useProfileAccounts(userId) {
    
    const [userAccounts, setUserAccounts] = useState([]);
    
    useEffect(() => {
        async function fetchData() {
            const path = userId ? `/profile/${userId}` : '/profile';
            const { accounts } = await API.get('AccountGatewayApi', path, { withCredentials: true });
            setUserAccounts(accounts);
        }
        fetchData();
    },[userId]);
    
    return userAccounts;
    
}