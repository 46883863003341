// An input has changed on the form.
// Copy the new input value to the
// inputs state.
const handleInputChange = (event, setInputs) => {
  event.persist();
  setInputs(prevInputs => ({
    ...prevInputs,
    [event.target.name]: event.target.value
  }));
};

const updateArray = (arr, id, checked) => {
  if (checked && !arr.includes(id)) {
    return [...arr, id];
  }
  if (!checked && arr.includes(id)) {
    return arr.filter(item => item !== id);
  }
  return [...arr];
};

export const handleDateChange = (date, inputKey, setInputs) => {
  setInputs(prevInputs => ({
    ...prevInputs,
    [inputKey]: date
  }));
};

export const handleInputChangeArray = (event, setInputs, arrayName) => {
  event.persist();
  setInputs(prevInputs => ({
    ...prevInputs,
    [arrayName]: updateArray(
      prevInputs[arrayName],
      event.target.id.toUpperCase(),
      event.target.checked
    )
  }));
};

export default handleInputChange;
