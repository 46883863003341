import React, { memo } from "react";
import PropTypes from "prop-types";
import AccountAdminAccount from "./AccountAdminAccount";
import AccountAdminUsers from "./AccountAdminUsers";
import AccountAdminContracts from "./AccountAdminContracts";
import AccountAdminConfigs from "./AccountAdminConfigs";
import classes from "./AccountAdmin.module.scss";

/**
 * Pure component that displays the AccountAdmin component.
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const AccountAdminView = ({ accountId, account }) => {
  
  return (
    <>
      <div className="row">
        <div className="col">
          <AccountAdminAccount accountId={accountId} account={account} />
        </div>
      </div>
      <div className={`row ${classes.content}`}>
        <div className="col-6 p-0">
          <AccountAdminUsers accountId={accountId} accountProfiles={ account ? account.profiles : [] } />
        </div>
        <div className="col-6 p-0">
          <AccountAdminConfigs accountId={accountId} accountBrand={account.brand ? account.brand.name : '' } />
          <AccountAdminContracts accountId={accountId} accountName={account.name} accountContracts={ account ? account.contracts : [] } />
        </div>
      </div>
    </>
  );
};

AccountAdminView.propTypes = {
  accountId: PropTypes.string.isRequired
};

export default memo(AccountAdminView);
