import React, { memo } from "react";
import PropTypes from "prop-types";
import AccountAdminContractView from "./AccountAdminContract-view";
import useAccountAdminContract from "./AccountAdminContract-logic";

/* eslint no-unused-vars: 0  */

/**
 * Control the creation of the AccountAdminContract component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const AccountAdminContractContainer = ({
  accountContractId,
  contractId,
  name,
  dates,
  products,
  accountId
}) => {
  const { manageContract, terminateContract } = useAccountAdminContract(contractId, accountId);

  return (
    <AccountAdminContractView name={name} dates={dates} products={products} manageContract={manageContract} terminateContract={terminateContract} />
  );
};

AccountAdminContractContainer.defaultProps = {
  accountContractId: "",
  name: ""
};

AccountAdminContractContainer.propTypes = {
  accountContractId: PropTypes.string,
  contractId: PropTypes.string.isRequired,
  name: PropTypes.string
};

export default memo(AccountAdminContractContainer);
