import React, { memo } from "react";
import PropTypes from "prop-types";
import classes from "./ContractAdmin.module.scss";
import ContractDetails from "./ContractDetails";
import ContractAccounts from "./ContractAccounts";
import Scopes from "./Scopes";

/**
 * Pure component that displays the ContractAdmin component.
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const ContractAdminView = ({ contractId }) => {
  return (
    <div className={`${classes["flex-container"]}`}>
      <div className={`w-100 ${classes.contract}`}>
        <div className="row">
          <div className="col-7">
            <ContractDetails contractId={contractId} />
          </div>
          <div className="col-5">
            <ContractAccounts contractId={contractId} />
          </div>
        </div>
      </div>
      <div className={`${classes["team-scopes"]}`}>
        <Scopes />
      </div>
    </div>
  );
};

ContractAdminView.propTypes = {
  contractId: PropTypes.string.isRequired
};

export default memo(ContractAdminView);
