import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { logError } from "util/logging";

/**
 * Contains the logic for Header
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const useHeader = () => {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    authenticate(setUserName);
  }, []);

  const signOut = () => {
    handleSignOut();
  };

  return { userName, signOut };
};

const handleSignOut = async () => {
    try {
        await Auth.signOut({ global: true });
        window.location.href = "/";
    } catch (error) {
        console.log('error signing out: ', error);
    }
};

const authenticate = setUserName => {
  Auth.currentAuthenticatedUser()
    .then(user => {
      setUserName(user.attributes.name || user.attributes.email);
    })
    .catch(e => {
      logError("HeaderView error", e);
      setUserName("");
    });
};

export default useHeader;
