import React from "react";
import PropTypes from "prop-types";
import UserAdminAccountsView from "./UserAdminAccounts-view";
import useUserAdminAccounts from "./UserAdminAccounts-logic";

const UserAdminAccountsContainer = () => {
  const {
    accounts,
    addNewUser,
    addNewAccount,
    onSave,
    onCancelAddNewUserToAccount
  } = useUserAdminAccounts();

    return (
      <UserAdminAccountsView
        accounts={accounts}
        addNewUser={addNewUser}
        addNewAccount={addNewAccount}
        onSave={onSave}
        onCancelAddNewUserToAccount={onCancelAddNewUserToAccount}
      />
    );
};

UserAdminAccountsContainer.propTypes = {
  accounts: PropTypes.array,
  addNewUser: PropTypes.func,
  addNewAccount: PropTypes.func,
  onSave: PropTypes.func,
  onCancelAddNewUserToAccount: PropTypes.func
};

export default UserAdminAccountsContainer;
