import React from "react";
import PropTypes from "prop-types";
import ContractAccountsView from "./ContractAccounts-view";
import useContractAccounts from "./ContractAccounts-logic";

/**
 * Control the creation of the ContractAccounts component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const ContractAccountsContainer = ({ contractId }) => {
  const { accounts } = useContractAccounts(contractId);

  return accounts ? <ContractAccountsView accounts={accounts} /> : <div />;
};

ContractAccountsContainer.propTypes = {
  contractId: PropTypes.string.isRequired
};

export default ContractAccountsContainer;
