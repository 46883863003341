import React, { memo } from "react";
import PropTypes from "prop-types";
import classes from "./ContractAccount.module.scss";

const ContractAccountView = ({ account, history }) => {
  return (
    <>
      <div className={`pt-2 ${classes["account-row"]}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <span className="white-text">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => history.push(`/AccountAdmin/${account.id}`)}
                >
                  {account.name}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ContractAccountView.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
    accountContractId: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  history: PropTypes.object.isRequired
};

export default memo(ContractAccountView);
