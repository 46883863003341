import PropTypes from "prop-types";

const userPropType = PropTypes.shape({
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  userPhone: PropTypes.string,
  userOldPassword: PropTypes.string,
  userNewPassword: PropTypes.string,
  userConfirmPassword: PropTypes.string
});

export default userPropType;
