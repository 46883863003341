import Sidebar from "./Sidebar-container";

/**
 * Export the top level component Sidebar-container
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export default Sidebar;
