/* eslint-disable no-console */
export const logError = (...args) => {
  console.error(...args);
};

export const logInfo = (...args) => {
  console.log(...args);
};

export const consoleClear = () => {
  console.clear();
};

export default logError;
