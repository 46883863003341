import { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";
import { ModalContext } from "contexts/Modal";

/**
 * Contains the logic for UserBrands
 *
 * @version 0.0.1
 * @author Josh Lloyd
 *
 */

const useUserBrands = () => {
  const [userBrands, setBrands] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [inputs, setInputs] = useState(emptyInputs);
  const { showModal } = useContext(ModalContext);

  useEffect(() => {
    fetchUserBrands();
  }, []);
  
  const fetchUserBrands = async () => {
    try {
        const brandsData = await API.get('AccountGatewayApi', '/brands', { withCredentials: true });
        const brandAccounts = await Promise.all(brandsData.map(brand => {
            return API.get('AccountGatewayApi', `/brand/${brand.name}`, { withCredentials: true });
        }));
        
        setBrands(brandsData.map((brand, idx) => {
            return {
                brandName: brand.name,
                accountName: brandAccounts[idx].accounts.map(account => account.name).join(', ')
            };
        }));
        
    } catch (err) {
        console.error('fetchUserBrands error', err);
    }
  };
  
  const handleSave = async event => {
    try {
      await API.post('AccountGatewayApi', `/brand`, {
        body: {
          name: inputs.brandName,
        },
        withCredentials: true
      });
      
      setModalOpen(false);
      showModal("Brand Save", "Save was successful.");
      fetchUserBrands();
    } catch (err) {
      console.error('Error saving new brand', err);
      showModal('Brand save unsuccessful', 'Error occurred while saving new brand.');
    }
  };

  return { userBrands, modalOpen, setModalOpen, inputs, setInputs, handleSave };
};

const emptyInputs = {
  brandName: ""
};

export default useUserBrands;
