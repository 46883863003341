import { useState, useEffect } from "react";
import { API } from "aws-amplify";

const useUserAdmin = (userId) => {
  const [userProfile, setUserProfile] = useState({});
  const [shouldUserUpdate, updateUser] = useState(false);
  
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await API.get('AccountGatewayApi', `/profile/${userId}`, { withCredentials: true });
        
        setUserProfile(userProfile);
        updateUser(false);
      } catch (err) {
        console.error('error fetching user profile', err);
      }
    };
    fetchUserProfile();
  },[userId, shouldUserUpdate]);

  return {
    userProfile,
    updateUser
  };
};

export default useUserAdmin;
