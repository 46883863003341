import React from "react";

/**
 * Export the top level component Spinner
 *
 * @version 0.0.1
 * @author Josh Lloyd
 *
 */
 
export default function() {
    return (
        <div className="spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
}

export function SpinnerSmall(props) {
    return (
        <div className={`spinner-border spinner-border-sm text-${props.color ? props.color : 'secondary'}`} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    );
}

export function InlineSpinner() {
    return (
        <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span className="sr-only">Loading...</span>
        </>
    );
}