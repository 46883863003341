import React from "react";
import UserAdminView from "./UserAdmin-view";
import useUserAdmin from "./UserAdmin-logic";

/**
 * Control the creation of the UserAdmin component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export const UserAdminContext = React.createContext({});

const UserAdminContainer = ({match}) => {
  const { userId } = match.params;
  
  const {
    setHasUserAdminUserRendered,
    hasUserAdminUserRendered,
    userProfile,
    updateUser
  } = useUserAdmin(userId);

  return (
    <UserAdminContext.Provider value={{ userProfile, updateUser }}>
      <UserAdminView
        setHasUserAdminUserRendered={setHasUserAdminUserRendered}
        hasUserAdminUserRendered={hasUserAdminUserRendered}
      />
    </UserAdminContext.Provider>
  );
};

export default UserAdminContainer;
