import React, { useState, useEffect } from "react";
import { DashboardsEmbedWrapper } from "./dashboardEmbed";

function QueryVizualizationReportPage(props) {
    
    return (
        <div className="card">
          <div className="card-header text-light font-weight-bold container-fluid" style={{backgroundColor: '#828282'}}>
            <div className="row">
              <div className="col-12">Query Visualization {props.match.params.visualizationId}</div>
            </div>
          </div>
          <div>
            { props?.match?.params?.jobId && <DashboardsEmbedWrapper dashboardId={props?.match?.params?.jobId} />}
          </div>
        </div>
    );
}

export default QueryVizualizationReportPage;