import React, { useState, useEffect, useCallback } from "react";
import { API, Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

async function getToken() {
  try {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    return token;
  } catch (err) {
      console.error('error getting token', err);
  }
}

function OpenQueryQuery(props) {
  let history = useHistory();
  const [ job, setJob ] = useState(null);

  useEffect(() => {
    async function getQueries() {
      const jobs = await API.get('OpenQueryAPI',`/${process.env.REACT_APP_TARGET}/openquery/jobs`);
      const resubmitData = jobs.requests.filter(job => job.id === props.match.params.jobId)[0];
      setJob(resubmitData);
    }
    getQueries();
  },[props.match.params.jobId]);
  
  const handleIframeRequests = useCallback( e => {
    const getAndSendToken = async (contentWindow) => {
      const token = await getToken();
      contentWindow.postMessage(token, "*");
    };
    const sendQueryPayload = (contentWindow) => {
      contentWindow.postMessage(job, "*");
    };
    if (e.data === 'TOKEN_REQUEST') {
      const ifrm = document.querySelector('iframe').contentWindow;
      getAndSendToken(ifrm);
    } else if (e.data === 'IFRAME_LOADED') {
      const ifrm = document.querySelector('iframe').contentWindow;
      sendQueryPayload(ifrm);
    } else if (e.data === 'QUERY_SUCCESSFULLY_SUBMITTED') {
        history.push('/openquery');
    }
  }, [job, history]);
  
  useEffect(() => {
    window.addEventListener('message', handleIframeRequests);
    return () => {
        window.removeEventListener('message', handleIframeRequests);
    };
  },[handleIframeRequests]);
  
  return (
    <div className="card">
      <div className="card-header text-light font-weight-bold container-fluid" style={{backgroundColor: '#439945'}}>
        <div className="row">
          <div className="col-12">Query</div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 embed-responsive embed-responsive-16by9">
            { job && process.env.REACT_APP_TARGET === 'dev' && (<iframe
                src={`https://d15s63ubzw2ryy.cloudfront.net/${job['category-id']}/index.html`}
                title="Perception Health Report Request"
                className="embed-responsive-item"
            ></iframe>)}
            { job && process.env.REACT_APP_TARGET === 'prod' && (<iframe
                src={`https://d3gl0b8z52wx8l.cloudfront.net/${job['category-id']}/index.html`}
                title="Perception Health Report Request"
                className="embed-responsive-item"
            ></iframe>)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OpenQueryQuery;