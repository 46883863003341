import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import {
    Route,
    Link, 
    useRouteMatch,
    useHistory,
    Switch,
    Redirect,
    useParams
} from "react-router-dom";

const childTargetOrigin = {
    local: 'http://localhost:8081',
    dev: 'https://d15s63ubzw2ryy.cloudfront.net',
    prod: 'https://d3gl0b8z52wx8l.cloudfront.net'
};

const targetOrigin = process.env.REACT_APP_ENV === 'local' ? childTargetOrigin.local : childTargetOrigin[process.env.REACT_APP_TARGET];

function Report(props) {
    let history = useHistory();
    return (
        <div className="row">
            <div className="col-12 pb-3">
                <button type="button" className="close" aria-label="Close" onClick={e => history.push('/openquery')}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="col-12 embed-responsive embed-responsive-16by9">
                
                { process.env.REACT_APP_TARGET === 'dev' && process.env.REACT_APP_ENV === 'local' && (<iframe
                    src={`http://localhost:8081/${props.reportId}/index.html`}//local stack
                    title="Perception Health Report Request"
                    className="embed-responsive-item"
                ></iframe>)}
                { process.env.REACT_APP_TARGET === 'dev' && !process.env.REACT_APP_ENV && (<iframe
                    src={`https://d15s63ubzw2ryy.cloudfront.net/${props.reportId}/index.html`}//dev stack
                    title="Perception Health Report Request"
                    className="embed-responsive-item"
                ></iframe>)}
                { process.env.REACT_APP_TARGET === 'prod' && !process.env.REACT_APP_ENV && (<iframe
                    src={`https://d3gl0b8z52wx8l.cloudfront.net/${props.reportId}/index.html`}//prod stack
                    title="Perception Health Report Request"
                    className="embed-responsive-item"
                ></iframe>)}
            </div>
        </div>
    );
}

async function getToken() {
    try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        return token;
    } catch (err) {
        console.error('error getting token', err);
    }
}

function OpenQueryContainer(props) {
    const [ dynamicReportList, setDynamicReportList ] = useState([]);
    const [ activeTab, setActiveTab ] = useState(null);
    
    let history = useHistory();
    let { path, url } = useRouteMatch();
    let { payerMix } = useParams();

    useEffect(() => {
        async function getReports() {
            const reports = await API.get('OpenQueryAPI',`/${process.env.REACT_APP_TARGET}/openquery/reports`);
            const shapedReports = reports.reports.category.reduce((tabsArr, category) => {
                const categoryContents = category.reports.reduce((reportSet, report) => {
                    if (report.name.includes('.Medicare')) {
                        reportSet.add('medicare');
                    } else {
                        reportSet.add('commercial');
                    }
                    return reportSet;
                }, new Set());
                categoryContents.forEach(cat => {
                    tabsArr.push({
                        name: category.name,
                        description: category.description,
                        id: category.id,
                        type: cat
                    });
                });
                return tabsArr;
            },[]);
            setDynamicReportList(shapedReports);
        }
        getReports();
    },[]);
    
    useEffect(() => {
        function handleIframeMessage(e) {
            const getAndSendToken = async (contentWindow) => {
                var token = await getToken();
                contentWindow.postMessage(token, targetOrigin);
            };
            
            const sendPayerMix = (contentWindow, payerMix) => {
                contentWindow.postMessage({payerMix: payerMix}, targetOrigin);
            };
            
            if (e.data === 'TOKEN_REQUEST') {
                let ifrm = document.querySelector('iframe').contentWindow;
                getAndSendToken(ifrm);
            } else if (e.data === 'QUERY_SUCCESSFULLY_SUBMITTED') {
                history.push('/openquery');
            } else if (e.data === 'GET_PAYER_MIX') {
                let ifrm = document.querySelector('iframe').contentWindow;
                sendPayerMix(ifrm, payerMix);
            } else {
                return;
            }
        }
        window.addEventListener('message', handleIframeMessage);
        return () => {
            window.removeEventListener('message', handleIframeMessage);
        };
    },[history, payerMix]);
    
    return (<>
        {(['commercial','medicare'].indexOf(payerMix) === -1 )
            ? <Redirect to="/openquery/commercial" />
            : null
        }
        <Route exact path="/openquery">
            <Redirect to="/openquery/commercial" />
        </Route>
        <div className="card">
            <div className="card-header text-light font-weight-bold container-fluid" style={{backgroundColor: '#439945'}}>
                <ul className="nav nav-tabs card-header-tabs">
                    <li
                        className="nav-item"
                        style={{cursor: 'pointer'}}
                    >
                        <div
                            className={payerMix === 'commercial' ? 'nav-link active' : 'nav-link' }
                        >
                            <Link to="/openquery/commercial" style={{color: payerMix === 'commercial' ? '#000' : '#fff'}}>Commercial</Link>
                        </div>
                    </li>
                    <li
                        className="nav-item"
                        style={{cursor: 'pointer'}}
                    >
                        <div
                            className={payerMix === 'medicare' ? 'nav-link active' : 'nav-link' }
                        >
                            <Link to="/openquery/medicare" style={{color: payerMix === 'medicare' ? '#000' : '#fff'}}>Medicare</Link>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="">
                <div className="row">
                    <div className="col-3">
                        <p className="pl-3 pt-3 mb-0" style={{color: '#439945'}}><b>Export Type</b></p>
                    </div>
                    <div className="col-9">
                        <p className="pl-3 pt-3 mb-0" style={{color: '#439945'}}><b>Export Description</b></p>
                    </div>
                </div>
                <hr />
                <ul className="list-group-flush container-fluid" style={{padding: 0}}>
                    { dynamicReportList.filter(item => payerMix === item.type).map(item => (
                        <li key={item.name} className="list-group-item">
                            <div className="row">
                                <div className="col-3">
                                    <Switch>
                                        <Route path={`${path}/${item.id}`}>
                                            <Link to={`/openquery/${payerMix}`}>
                                                <p className="font-weight-bold">{item.name}</p>
                                            </Link>
                                        </Route>
                                        
                                        <Route path='/openquery'>
                                            <Link to={`${url}/${item.id}`}>
                                                <p className="font-weight-bold">{item.name}</p>
                                            </Link>
                                        </Route>
                                    </Switch>
                                </div>
                                <div className="col-9">
                                    <p>{item.description}</p>
                                </div>
                            </div>
                            <Route path={`${path}/${item.id}`}>
                                <Report reportId={item.id} />
                            </Route>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </>);
}

export default OpenQueryContainer;