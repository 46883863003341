import { useState, useEffect, useContext } from "react";
import { ContractAdminContext} from "components/ContractAdmin/ContractAdmin-container";

/**
 * Contains the logic for ContractAccounts
 *
 * @version 0.0.1
 * @author Josh Lloyd
 *
 */

const useContractAccounts = contractId => {
  const [accounts, setAccounts] = useState([]);
  const { contract } = useContext(ContractAdminContext);
  
  useEffect(() => {
    setAccounts(contract.accounts);
  }, [contract]);

  return {
    accounts
  };
};

export default useContractAccounts;
