import React, { useState, useEffect, useCallback, useMemo } from "react";
import { API, Auth } from "aws-amplify";
import Modal from "react-bootstrap/Modal";
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Spinner from "components/Spinner";
import OpenQueryReminderModal from "components/OpenQueryReminderModal";
import { PhGridPagination, PhGridFilter, usePagination, PhGridSort } from 'components/PhGrid';


function QueryRow(props) {

  const handleShowResubmitModal = () => {
    props.setModalCategoryId(props['category-id']);
    props.setModalResubmitData({
      definition: props.definition,
      categoryId: props['category-id'],
      reportId: props.reportId,
      description: props.description
    });
    props.setShowResubmitModal(true);
  };
  
  const handleShowReminderModal = () => {
    props.setReminderJob(props.job);
    props.setShowReminderModal(true);
  };
  
  return (
    <tr key={props.id}>
      <th scope="row">{props.description || ''}</th>
      <td>{props.id}</td>
      <td>{props.report.split('.').join(', ')}</td>
      <td>{props.dateCreated.toLocaleDateString()}</td>
      <td>{props.status}</td>
      <td>{props.status === 'PROCESSED'
        ? (<ButtonGroup>
          <DropdownButton as={ButtonGroup} title="Actions" id="bg-nested-dropdown">
            <Dropdown.Item eventKey="1" onClick={handleShowResubmitModal}>Resubmit Query</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={handleShowReminderModal}>Schedule Query Reminder</Dropdown.Item>
          </DropdownButton>
        </ButtonGroup>)
        : null
      }</td>
    </tr>
  );
}

async function getToken() {
    try {
        const session = await Auth.currentSession();
        const token = session.getIdToken().getJwtToken();
        return token;
    } catch (err) {
        console.error('error getting token', err);
    }
}

function OpenQueryQueriesContainer(props) {
  
  const {
    paginationOffset, setPaginationOffset,
    paginationSize, setPaginationSize,
    filterString, setFilterString,
    sortColumn, setSortColumn,
    sortKey, setSortKey,
    sortDirection, setSortDirection
  } = usePagination();
  
  const [ queries, setQueries ] = useState(null);
  
  const [ showResubmitModal, setShowResubmitModal ] = useState(false);
  const [ showReminderModal, setShowReminderModal ] = useState(false);
  const [ modalCategoryId , setModalCategoryId ] = useState('');
  const [ modalResubmitData, setModalResubmitData ] = useState(null);

  const [ errorMsg, setErrorMsg ] = useState('');
  
  const [ reminderJob, setReminderJob ] = useState(null);
  
  const handleCloseResubmitModal = () => setShowResubmitModal(false);
  const handleCloseReminderModal = () => setShowReminderModal(false);

  useEffect(() => {
    async function getQueries() {
      const jobs = await API.get('OpenQueryAPI',`/${process.env.REACT_APP_TARGET}/openquery/jobs`);
      setQueries(jobs.requests.map(job => {
        job.dateCreated = new Date(job.dateCreated);
        return job;
      }));
    }
    getQueries();
  },[]);
  
  const handleIframeRequests = useCallback( e => {
    const getAndSendToken = async (contentWindow) => {
      const token = await getToken();
      contentWindow.postMessage(token, "*");
    };
    const sendQueryPayload = (contentWindow) => {
      contentWindow.postMessage(modalResubmitData, "*");
    };
    if (e.data === 'TOKEN_REQUEST') {
      const ifrm = document.querySelector('iframe').contentWindow;
      getAndSendToken(ifrm);
    } else if (e.data === 'IFRAME_LOADED') {
      const ifrm = document.querySelector('iframe').contentWindow;
      sendQueryPayload(ifrm);
    } else if (e.data === 'QUERY_SUCCESSFULLY_SUBMITTED') {
        handleCloseResubmitModal();
    }
  }, [modalResubmitData]);
  
  useEffect(() => {
    window.addEventListener('message', handleIframeRequests);
    return () => {
        window.removeEventListener('message', handleIframeRequests);
    };
  },[handleIframeRequests]);
  
  const displayList = useMemo(() => {
    if (!queries) return [];
    return queries
      .filter(query => [
        query.description || '',
        query.id,
        query.report.split('.').join(', '),
        query.dateCreated.toLocaleDateString(),
        query.status
      ].join('').toLowerCase().includes(filterString.toLowerCase()))
      .sort((a,b) => {
        if (sortColumn) {
          if (sortDirection === 'ascending') {
            return sortKey(a) > sortKey(b) ? 1 : sortKey(a) < sortKey(b) ? -1 : 0 ;
          } else {
            return sortKey(a) > sortKey(b) ? -1 : sortKey(a) < sortKey(b) ? 1 : 0 ;
          }
        } else {
          return;
        }
      })
      .slice(paginationOffset, (paginationOffset + paginationSize));
  },[
    queries,
    paginationOffset,
    paginationSize,
    filterString,
    sortColumn,
    sortKey,
    sortDirection
  ]);
  
  return (<>
    <div className="card">
      <div className="card-header text-light font-weight-bold container-fluid" style={{backgroundColor: '#439945'}}>
        <div className="row">
          <div className="col">Queries</div>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="row m-0">
          <div className="col"></div>
          <div className="col-auto mt-3">
            { queries && (
              <PhGridFilter
                filterString={filterString}
                setFilterString={setFilterString}
                setPaginationOffset={setPaginationOffset}
              />
            )}
          </div>
        </div>
        <table className="table table-striped table-responsive" style={{padding: 0}}>
          <thead>
            <tr>
              <th scope="col">
                <PhGridSort
                  columnLabel='File Name'
                  columnName={d => d?.description}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col">
                <PhGridSort
                  columnLabel='Query Id'
                  columnName={d => d?.id}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col">
                <PhGridSort
                  columnLabel='Report Name'
                  columnName={d => d?.report.split('.').join(', ')}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col">
                <PhGridSort
                  columnLabel='Created'
                  columnName={d => d?.dateCreated}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col">
                <PhGridSort
                  columnLabel='Status'
                  columnName={d => d?.status}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            { !queries && <tr><td colSpan="6" className="text-center"><Spinner/></td></tr> }
            { displayList && displayList.map(item => (
              <QueryRow
                {...item}
                key={item.id}
                setShowResubmitModal={setShowResubmitModal}
                setModalCategoryId={setModalCategoryId}
                setModalResubmitData={setModalResubmitData}
                setShowReminderModal={setShowReminderModal}
                job={item}
                setReminderJob={setReminderJob}
              />
            ))}
          </tbody>
        </table>
        { queries && (
        <div className="row m-0 justify-content-end" style={{paddingRight: '.75rem'}}>
          <PhGridPagination
            fullList={queries}
            paginationOffset={paginationOffset}
            setPaginationOffset={setPaginationOffset}
            setPaginationSize={setPaginationSize}
            paginationSize={paginationSize}
            filterString={filterString}
          />
        </div>
        )}
      </div>
    </div>
    <Modal
      show={errorMsg}
      onHide={() => setErrorMsg('')}
    >
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{errorMsg}</p>
      </Modal.Body>
    </Modal>
    <Modal
      size="lg"
      show={showResubmitModal}
      onHide={handleCloseResubmitModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Resubmit Query</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 embed-responsive embed-responsive-16by9">
              { process.env.REACT_APP_TARGET === 'dev' && (
              <iframe
                  src={`https://d15s63ubzw2ryy.cloudfront.net/${modalCategoryId}/index.html`}//dev stack
                  title="Perception Health Report Request"
                  className="embed-responsive-item"
              ></iframe>
              )}
              { process.env.REACT_APP_TARGET === 'prod' && (
              <iframe
                  src={`https://d3gl0b8z52wx8l.cloudfront.net/${modalCategoryId}/index.html`}//prod stack
                  title="Perception Health Report Request"
                  className="embed-responsive-item"
              ></iframe>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    <OpenQueryReminderModal
      title="Schedule Reminder"
      job={reminderJob}
      showReminderModal={showReminderModal}
      handleCloseReminderModal={handleCloseReminderModal}
    />
  </>);
}

export default OpenQueryQueriesContainer;