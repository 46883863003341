import React, { memo } from "react";
import PropTypes from "prop-types";
import AccountAdminView from "./AccountAdmin-view";
import useAccountAdmin from "./AccountAdmin-logic";


/**
 * Control the creation of the AccountAdmin component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

export const AccountAdminContext = React.createContext({});

const AccountAdminContainer = ({ match }) => {
  const { accountId } = match.params;
  const { account, setAccount, shouldUpdateAccount, updateAccount, currentUserProfile, setCurrentUserProfile, accountFetched } = useAccountAdmin(match);
  
  return (
    <AccountAdminContext.Provider value={{ account, setAccount, shouldUpdateAccount, updateAccount, currentUserProfile, setCurrentUserProfile, accountId, accountFetched }}>
      <AccountAdminView match={match} accountId={accountId} account={account}/>
    </AccountAdminContext.Provider>
  );
};

AccountAdminContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired
};

export default memo(AccountAdminContainer);
