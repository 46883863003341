import ContractAccount from "./ContractAccount-container";

/**
 * Export the top level component ContractAccount-container
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export default ContractAccount;
