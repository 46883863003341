import { Auth } from "aws-amplify";

const amplifyConfig = {
    Auth: {
        // Amazon Cognito Region
        region: process.env.REACT_APP_AWS_REGION, 
        // Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID, 
        // Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        cookieStorage: process.env.REACT_APP_ENV === 'local' ? undefined : {
          "domain": `.perceptionhealth.${process.env.REACT_APP_TARGET === 'prod' ? 'com' : 'dev' }`,
          "expires": 30,
          "path": "/",
          "sameSite": "strict",
          "secure": true
        }
    },
    API: {
        endpoints: [
            {
                name: "AccountGatewayApi",
                endpoint: `https://api.account.perceptionhealth.${process.env.REACT_APP_TARGET === 'prod' ? 'com' : 'dev' }`,
                custom_header: async () => { 
                    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
                    return {
                        Authorization: `Bearer ${idToken}`,
                        'Content-Type': 'application/json'
                    };
                }
            },
            {
                name: "OpenQueryAPI",
                endpoint: `${process.env.REACT_APP_TARGET === 'prod' ? 'https://iohyqk8nwb.execute-api.us-east-1.amazonaws.com' : 'https://rsfp7bfr8h.execute-api.us-east-1.amazonaws.com' }`,
                custom_header: async () => {
                    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
                    return {
                        authorization: `bearer ${idToken}`,
                        'content-type': 'application/json'
                    };
                }
            },
            {
                name: "FileApi",
                // endpoint: "https://ms5td1xqb4.execute-api.us-east-1.amazonaws.com",// path for files -> /Dev/filemanagement/files
                // endpoint: "https://4ggcr8jssb.execute-api.us-east-1.amazonaws.com",// path for files -> /Dev/filemanagement/files
                endpoint: `${process.env.REACT_APP_TARGET === 'prod' ? 'https://ms5td1xqb4.execute-api.us-east-1.amazonaws.com' : 'https://4ggcr8jssb.execute-api.us-east-1.amazonaws.com' }`,// path for files -> /Dev/filemanagement/files
                custom_header: async () => {
                    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
                    return {
                        Authorization: idToken,
                        'content-type': 'application/json'
                    };
                }
            },
            {
                name: "MonitoringServiceApi",
                endpoint: `${process.env.REACT_APP_TARGET === 'prod'
                    ? 'https://5aoriyhsfe.execute-api.us-east-1.amazonaws.com/prod'
                    : 'https://9hxpema1w1.execute-api.us-east-1.amazonaws.com/dev'
                }`,
                custom_header: async () => {
                    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
                    return {
                        Authorization: `Bearer ${idToken}`,
                        'content-type': 'application/json'
                    };
                }
            },
            {
                name: "CommunityApi",
                endpoint: `https://community.api.account.perceptionhealth.${process.env.REACT_APP_TARGET === 'prod' ? 'com' : 'dev'}`,// path for communities -> /communities
                custom_header: async () => {
                    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
                    return {
                        Authorization: `Bearer ${idToken}`,
                        'content-type': 'application/json'
                    };
                }
            },
            {
                name: "NpiApi",
                endpoint: `${process.env.REACT_APP_TARGET === 'prod'
                    ? 'https://api.npi.perceptionhealth.com'
                    : 'https://c5zg0wm435.execute-api.us-east-1.amazonaws.com/dev'
                }`, //path -> /npi_t
                custom_header: async () => {
                    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
                    return {
                        Authorization: `Bearer ${idToken}`,
                        'content-type': 'application/json'
                    };
                }
            }
        ]
    },
    oauth: {},
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};

export default amplifyConfig;