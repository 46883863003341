import React, { memo } from "react";
import AccountAdminConfigsView from "./AccountAdminConfigs-view";
import useAccountAdminConfigs from "./AccountAdminConfigs-logic";

/**
 * Control the creation of the AccountAdminConfigs component
 *
 * @version 0.0.1
 * @author Joshua Lloyd
 *
 */

const AccountAdminConfigsContainer = ({ accountId, accountBrand }) => {
  const {
    modalOpen,
    setModalOpen,
    inputs,
    setInputs,
    getAccountConfigs,
    handleConfigSave,
    config,
    handleConfigDelete,
    getConfigKey
  } = useAccountAdminConfigs(accountId);

  return (<AccountAdminConfigsView 
    accountId={accountId}
    accountBrand={accountBrand}
    modalOpen={modalOpen}
    setModalOpen={setModalOpen}
    inputs={inputs}
    setInputs={setInputs}
    getAccountConfigs={getAccountConfigs}
    handleConfigSave={handleConfigSave}
    config={config}
    handleConfigDelete={handleConfigDelete}
    getConfigKey={getConfigKey}
  />);
};

export default memo(AccountAdminConfigsContainer);
