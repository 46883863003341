import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import Auth from '@aws-amplify/auth';

async function getHeaders() {
    try {
        const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
        const headers = {
            Authorization: `Bearer ${idToken}`,
            'content-type': 'application/json'
        };
        return headers;
    } catch (err) {
        console.log('error getting headers', err);
    }
}

export async function getQuicksightDashboardId(queryJobId) {
    try {
        const headers = await getHeaders();
        const req = await fetch(`https://LAMBDA-ID.execute-api.us-east-1.amazonaws.com/queryviz/queryjobid/${queryJobId}`, headers);
        const resp = await req.json();
        return resp.embedUrl;
    } catch (err) {
        console.log('error requesting dashboard id', err);
    }
}

export async function getDashboardEmbedURL(dashboardId) {
    try {
        const headers = await getHeaders();
        const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
        const req = await fetch(`${process.env.REACT_APP_TARGET === 'prod' ? `https://iohyqk8nwb.execute-api.us-east-1.amazonaws.com/prod/queryviz/queryjobid/${dashboardId}?openIdToken=${idToken}` : `https://rsfp7bfr8h.execute-api.us-east-1.amazonaws.com/dev/queryviz/queryjobid/${dashboardId}?openIdToken=${idToken}` }`, {headers});
        const resp = await req.json();
        return resp;
    } catch (err) {
        console.log('error requesting dashboard embed url', err);
    }
}

export async function initializeDashboardEmbed(urlStr, config) {
    
    const embeddingContext = await createEmbeddingContext();
    
    const frameOptions = {
        url: urlStr, // url generated via embedding API
        container: "#embeddingContainer"
    };
    
    const contentOptions = {
        locale: "en-US"
    };
    
    return await embeddingContext.embedDashboard(frameOptions, contentOptions);
}