import React from "react";
import PropTypes from "prop-types";
import UserAdminAccount from "./UserAdminAccount";
import classes from "./UserAdminAccounts.module.scss";
import Spinner from "components/Spinner";

const UserAdminAccountsView = ({ accounts }) => {
  const alphabetizedAccounts = [...accounts]
  alphabetizedAccounts.sort(function (a, b) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });
  const listAccounts = alphabetizedAccounts.map(account => (
    <UserAdminAccount key={account.accountId} account={account} />
  ));

  return (
    <div className={classes.container}>
      <div className={classes["accounts-bar"]}>
        <div className="container-fluid">
          <div className="row pt-2 pb-2">
            <div className="col-auto">
              <span className="white-text bar-text">Accounts</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes["column-headers-bar"]}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <span className="white-text">Account</span>
            </div>
            <div className="col-3">
              <span className="white-text">Role</span>
            </div>
            <div className="col" />
            <div className="col-2">
              <span className="white-text">Add/Modify Role</span>
            </div>
          </div>
        </div>
      </div>
      { accounts.length > 0
        ? <div className={classes.content}>{listAccounts}</div>
        : <div className="p-3 text-center"><Spinner/></div>
      }
    </div>
  );
};

UserAdminAccountsView.defaultProps = {
  accounts: []
};

UserAdminAccountsView.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      name: PropTypes.string,
      brand: PropTypes.string,
      role: PropTypes.string,
      accountProfileId: PropTypes.string
    })
  )
};

export default UserAdminAccountsView;
