import React, { useContext, useMemo, useState } from "react";
import classes from "./AccountAdminConfigs.module.scss";
import GenericModal from "components/GenericModal";
import Spinner from "components/Spinner";
import { AccountAdminContext } from "components/AccountAdmin/AccountAdmin-container";
import { LoggedInUserContext } from "contexts/LoggedInUser";
import handleInputChange from "util/forms";
import { InlineSpinner } from "components/Spinner";

function isValidJSON(text) {
  try {
    JSON.parse(text);
    return true;
  } catch (err) {
    return false;
  }
}

function validConfigName(inputtxt) { 
  var tester = /^[0-9a-zA-Z.-]+$/;
  if (inputtxt.match(tester)) {
    return true;
  } else {
    return false;
  }
}

function AccountAdminConfig({
  accountId,
  configKey,
  getConfigKey,
  setInputs,
  setModalOpen,
  handleConfigDelete
}) {
  
  const [ manageClickProgress, setManageClickProgress ] = useState(false);
  const [ deleteClickProgress, setDeleteClickProgress ] = useState(false);
  
  const manageConfig = async (setSpinner) => {
    try {
      const configByKey = await getConfigKey(accountId, configKey);
      setSpinner(false);
      setInputs({
        name: configKey,
        content: JSON.stringify(configByKey)
      });
      setModalOpen(true);
    } catch (err) {
      console.error('error fetching configs', err);
    }
  };
  
  const terminateConfig = async (setSpinner) => {
    await handleConfigDelete(accountId, configKey);
    setSpinner(false);
  };
  
  return (
    <div key={configKey} className="w-100 row pt-3 pb-3">
      <div className="col-6">
        <span>{configKey}</span>
      </div>
      <div className="col">
      </div>
      <div className="col-auto pl-0 pr-2 m-0">
        <button
          type="button"
          className="button-primary float-right"
          onClick={ e => {
            e.preventDefault();
            setManageClickProgress(true);
            manageConfig(setManageClickProgress);
          }}
        >
          { manageClickProgress && <InlineSpinner/>}
          Manage
        </button>
      </div>
      <div className="col-auto p-0 m-0">
        <button
          type="button"
          className="button-red float-right"
          onClick={e => {
            e.preventDefault();
            setDeleteClickProgress(true);
            terminateConfig(setDeleteClickProgress);
          }}
        >
          { deleteClickProgress && <InlineSpinner/>}
          Remove
        </button>
      </div>
    </div>
  );
}

export default function AccountAdminConfigsView({
  accountId,
  accountBrand,
  modalOpen,
  setModalOpen,
  inputs,
  setInputs,
  getAccountConfigs,
  handleConfigSave,
  config,
  handleConfigDelete,
  getConfigKey
}) {
    
  const { loggedInUserProfile } = useContext(LoggedInUserContext);
  
  const userHasRole = useMemo(() => {
    
    return loggedInUserProfile.accounts.reduce((acc, currentAccount) => {
      if (acc) {
        return acc;
      } else if (currentAccount.role === 'GlobalAdmin') {
        return true;
      } else if (accountBrand && currentAccount.role === 'BrandAdmin' && currentAccount.brand === accountBrand) {
        return true;
      } else {
        return false;
      }
    }, false);
    
  }, [accountBrand, loggedInUserProfile.accounts]);
  
  const { accountFetched } = useContext(AccountAdminContext);

  const listConfigs = config ? Object.keys(config).map(configKey => {
    return (
      <AccountAdminConfig
        key={configKey}
        accountId={accountId}
        configKey={configKey}
        getConfigKey={getConfigKey}
        setInputs={setInputs}
        setModalOpen={setModalOpen}
        handleConfigDelete={handleConfigDelete}
      />
    );
  }) : [] ;

  return ( userHasRole &&
    <div className={`${classes["flex-container"]}`}>
      <div className={`w-100 row ${classes["account-configs-bar"]}`}>
        <div className="col-auto pt-2">
          <span className="white-text bar-text">Account Configs</span>
        </div>
        <div className="col" />
        <div className={`col-auto pr-3 ${classes["add-config-div"]}`}>
          <button
            type="button"
            className={classes["add-config-button"]}
            onClick={() => {
              setInputs({
                name: "",
                content: ""
              });
              setModalOpen(true);
            }}
          >
            Add Config 
          </button>
        </div>
      </div>
      <div className={`w-100 row ${classes["col-headers-bar"]}`}>
        <div className="col-6">
          <span className="white-text">Name</span>
        </div>
        <div className="col-6">
          
        </div>
      </div>
      <div className={`w-100 row ${classes.content}`}>
        { config
          ? <div className="col pr-0">{listConfigs}</div>
          : <div className="col p-3 text-center">{ accountFetched ? '' : <Spinner/> }</div>
        }
      </div>
      
      <GenericModal
        title="Create Config to Add to Account"
        modalOpen={modalOpen}
        onSave={event => handleConfigSave(event, accountId, inputs, setModalOpen)}
        onCancel={() => setModalOpen(false)}
      >
        <div className="container-fluid">
          <div className="row pt-3">
            <div className="col-md-2 pr-0">
              <span className="float-right pt-2">Name</span>
            </div>
            <div className="col-md-10">
              <div className="form-group">
                <input
                  type="text"
                  className={ `form-control border rounded w-75 pl-2 ${ (inputs.name && validConfigName(inputs.name) ) ? 'is-valid' : 'is-invalid' }` }
                  id="name"
                  name="name"
                  placeholder="Enter name"
                  value={inputs.name}
                  onChange={e => handleInputChange(e, setInputs)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-2 pr-0">
              <span className="float-right pt-2">Content</span>
            </div>
            <div className="col-md-10">
              <div className="form-group">
                <textarea
                  rows="3"
                  className={ `form-control border rounded w-75 pl-2 ${ isValidJSON(inputs.content) ? 'is-valid' : 'is-invalid' }` }
                  id="content"
                  name="content"
                  placeholder="Enter JSON"
                  value={inputs.content}
                  onChange={e => handleInputChange(e, setInputs)}
                  required
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </GenericModal>
    </div>
  );
}