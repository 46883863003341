import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import BrandAccountView from "./BrandAccount-view";

const BrandAccountContainer = ({ account, brand, handleDeactivateAccount, updateBrandAccounts }) => {
  const history = useHistory();
  return <BrandAccountView account={account} history={history} handleDeactivateAccount={handleDeactivateAccount} brand={brand} updateBrandAccounts={updateBrandAccounts} />;
};

BrandAccountContainer.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default BrandAccountContainer;
