import { useContext, useState, useEffect } from "react";
import { UserAdminContext } from "components/UserAdmin/UserAdmin-container";
import { LoggedInUserContext } from "contexts/LoggedInUser";
import useGenericModal from "components/GenericModal/GenericModal-logic";
import PropTypes from "prop-types";
import useEditProfile from "hooks/useEditProfile";
import { getAvailableRoles, highestUserRole } from "util/role";

// eslint-disable-next-line no-unused-vars
const useUserAdminAccount = account => {
  const { userProfile, updateUser } = useContext(UserAdminContext);
  const { loggedInUserProfile } = useContext(LoggedInUserContext);
  const { handleSaveProfile } = useEditProfile(account.accountId);
  const [inputs, setInputs] = useState();
  const [initInputs, setInitInputs] = useState();
  const { modalOpen, setModalOpen } = useGenericModal();
  const availableRoles = getAvailableRoles(highestUserRole(loggedInUserProfile.accounts.map(account => account.role)));

  useEffect(() => {
    const newInputs = {
      userRole: account.role || ''
    };
    setInitInputs(newInputs);
  }, [account.accountId, account.role]);

  // Whenever the modal opens, update
  // inputs with the current initInputs
  useEffect(() => {
    if (modalOpen) {
      setInputs(initInputs);
    }
  }, [modalOpen, initInputs]);

  return {
    setModalOpen,
    modalOpen,
    inputs,
    setInputs,
    userProfile,
    handleSaveProfile,
    initInputs,
    setInitInputs,
    updateUser,
    loggedInUserProfile,
    availableRoles
  };
};

useUserAdminAccount.propTypes = {
  accountId: PropTypes.string.isRequired
};

export default useUserAdminAccount;
