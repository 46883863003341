import React, { memo } from "react";
import handleInputChange from "util/forms";
import PropTypes from "prop-types";
import classes from "./AccountAdminAccount.module.scss";
import { InlineSpinner } from "components/Spinner";

/**
 * Pure component that displays the AccountAdminAccount component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const AccountAdminAccountView = ({ inputs, setInputs, handleSave, availableBrands, saving }) => {
  
  return (
    <div className="col">
      <div className="row">
        <div className={`col ${classes["account-header"]}`}>
          <div className={`bar-text ${classes["account-header-name"]}`}>
            Account
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`col ${classes.content}`}>
          <div className="row pt-3">
            <div className="col-md-2 pr-0">
              <span className="float-right pt-2">Name</span>
            </div>
            <div className="col-md-10">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control-plaintext border rounded w-75 pl-2"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                  value={inputs.name}
                  onChange={e => handleInputChange(e, setInputs)}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2 pr-0">
              <span className="float-right pt-2">Brand</span>
            </div>
            <div className="col-md-10">
              <div className="form-group">
                { inputs.brand && (
                  <select
                    id="brand"
                    name="brand"
                    className="form-control w-75"
                    
                    value={inputs.brand}
                    onChange={e => handleInputChange(e, setInputs)}
                  >
                    {availableBrands.map(brand => <option key={brand} value={brand}>{brand}</option>)}
                  </select>
                )}
              </div>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-10 offset-8">
              <button
                type="button"
                className="button-primary mr-2"
                onClick={handleSave}
              >{ saving && <InlineSpinner/> }
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AccountAdminAccountView.propTypes = {
  inputs: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired
  }).isRequired,
  setInputs: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

export default memo(AccountAdminAccountView);
