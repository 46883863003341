import React, { useState, useMemo, useCallback, useEffect } from "react";
import { API } from "aws-amplify";
import Modal from "react-bootstrap/Modal";
import { InlineSpinner } from "components/Spinner";

function isValidReminderName(reminderName) {
  var rg1=/^[a-zA-Z0-9 ]+$/; // forbidden characters \ / : , . * ? " < > |
  return  rg1.test(reminderName);
}

export default function OpenQueryReminderModal(props) {
  
  const [ errorMsg, setErrorMsg ] = useState('');
  
  const [ reminderName, setReminderName ] = useState('');
  const [ dateType, setDateType] = useState('');
  const [ dateValue, setDateValue ] = useState('');
  const [ savingReminder, setSavingReminder ] = useState(false);

  useEffect(() => {
    if (props.reminder) {
      setReminderName(props.reminder.REMINDER_DESCRIPTION.split(' -- ').length > 1 ? props.reminder.REMINDER_DESCRIPTION.split(' -- ')[0] : '');
      setDateType(props.reminder.DATE_TYPE);
      setDateValue(props.reminder.DATE_VALUE);
    }
  }, [props.reminder]);

  const reminderDescription = useMemo(() => {
    if (props.reminder) {//edit
      if (props.reminder.REMINDER_DESCRIPTION.split(' -- ').length > 1) {
        return `${reminderName} -- ${props.reminder.REMINDER_DESCRIPTION.split(' -- ')[1]}`
      } else {
        return `${reminderName} -- ${props.reminder.REMINDER_DESCRIPTION}`
      }
    } else if (props.job) {//create
      return `${reminderName} -- Click here to submit a new ${props.job.report.split('.').join(' / ')}  report:  https://account.perceptionhealth.${process.env.REACT_APP_TARGET === 'dev' ? 'dev' : 'com'}/openquery/job/${props.job.id}`;
    }
  }, [ props.job, props.reminder, reminderName ]);
  
  const handleCreateReminder = useCallback(() => {
    const createReminder = async () => {
      try {
        setSavingReminder(true);
        await API.post('OpenQueryAPI',`/${process.env.REACT_APP_TARGET}/reminders`, {
          body: {
            "DATE_TYPE": dateType,
            "DATE_VALUE": dateValue,
            "REMINDER_DESCRIPTION": reminderDescription
          },
        });
        setSavingReminder(false);
        props.handleCloseReminderModal();
      } catch (err) {
        setSavingReminder(false);
        setErrorMsg('error creating new reminder');
        console.error('error creating new reminder', err);
      }
    };
    createReminder();
  }, [ props, reminderDescription, dateType, dateValue]);
  
  const handleEditReminder = useCallback(() => {
    if (props.reminder) {
      const editReminder = async () => {
        try {
          setSavingReminder(true);
          await API.put('OpenQueryAPI',`/${process.env.REACT_APP_TARGET}/reminders/${props.reminder ? props.reminder.REMINDER_ID : ''}`, {
            body: {
              "DATE_TYPE": dateType,
              "DATE_VALUE": dateValue,
              "REMINDER_DESCRIPTION": reminderDescription
            },
          });
          
          // update rendered list
          const reminders = await API.get('OpenQueryAPI',`/${process.env.REACT_APP_TARGET}/reminders`);
          props.setReminders(reminders.Items);
          
          setSavingReminder(false);
          props.handleCloseReminderModal();
        } catch (err) {
          setSavingReminder(false);
          setErrorMsg('error saving reminder changes');
          console.error('error saving reminder changes');
        }
      };
      editReminder();
    }
  }, [ props, reminderDescription, dateType, dateValue ]);
  
  return (<>
    <Modal
      show={props.showReminderModal}
      onHide={props.handleCloseReminderModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          { props.job && (<>
            <div className="row">
              <h5>{props.job.report}</h5>
              <p>{props.job.id}</p>
            </div>
            <div className="row">
              <div className="col pl-0">
              <h6>Parameters</h6>
                <ul className="list-unstyled">
                  {Object.keys(props.job.definition.parameters).map(key => <li><strong>{key}</strong>: <span>{props.job.definition.parameters[key]}</span></li>)}
                </ul>
              </div>
              <div className="col pl-0">
              <h6>Output Fields</h6>
                <p>
                  {props.job.definition.outputFields.join(', ')}
                </p>
              </div>
            </div>
          </>)}
          <div className="form-group row">
            <label htmlFor="providerType" className="col-4 col-form-label">Reminder Name:</label>
            <input type="text" className={`form-control col-8 ${(reminderName && !isValidReminderName(reminderName)) ? 'is-invalid' : null }`} placeholder="Input Reminder Name" value={reminderName} onChange={e => setReminderName(e.target.value)} />
            { reminderName && !isValidReminderName(reminderName) && <div className="invalid-feedback text-right">
              Must be a valid name (only letters, numbers, spaces)
            </div>}
          </div>
          <div className="form-group row">
            <label htmlFor="providerType" className="col-4 col-form-label">Date Type:</label>
            <select className="form-control col-8"
              onChange={e => {
                setDateType(e.target.value);
                setDateValue('');
              }}
            >
              <option value="">Select Type</option>
              <option value="DAY OF WEEK" selected={dateType === 'DAY OF WEEK'}>Day of Week</option>
              <option value="DAY OF MONTH" selected={dateType === 'DAY OF MONTH'}>Day of Month</option>
            </select>
          </div>
          { dateType && (<div className="form-group row">
            <label htmlFor="providerType" className="col-4 col-form-label">Date Value:</label>
            <select className="form-control col-8"
              onChange={e => setDateValue(e.target.value)}
            >
              <option value="">Select Value</option>
              { dateType === 'DAY OF WEEK' && ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY'].map(weekDay => (
                <option value={weekDay} key={weekDay} selected={dateValue === weekDay}>
                  {`${weekDay.slice(0,1).toUpperCase()}${weekDay.slice(1).toLowerCase()}`}
                </option>
              ))}
              { dateType === 'DAY OF MONTH' && Array.from({length:30},(v,i)=>`${i+1}`).map(monthDay => (
                <option value={monthDay} key={monthDay} selected={dateValue === monthDay}>
                  {monthDay}
                </option>
              ))}
            </select>
          </div>)}
          { reminderName && dateType && dateValue && (<div className="row float-right">
            <button
              className="btn btn-primary mr-1"
              onClick={() => {
                if (props.job) {
                  handleCreateReminder();
                } else {
                  handleEditReminder();
                }
              }}
              disabled={!isValidReminderName(reminderName)}
            >
              { savingReminder && <InlineSpinner/> }Save
            </button>
            <button
              className="btn btn-secondary"
              onClick={props.handleCloseReminderModal}
            >Cancel</button>
          </div>)}
        </div>
      </Modal.Body>
    </Modal>
    <Modal
      show={errorMsg}
      onHide={() => setErrorMsg('')}
    >
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{errorMsg}</p>
      </Modal.Body>
    </Modal>
  </>);
}