import { useState, useEffect, useContext } from "react";
// import { API } from "aws-amplify";
import { logError } from "util/logging";
import { strNonEmpty } from "util/strings";
import { ModalContext } from "contexts/Modal";

import { UserAdminContext } from "components/UserAdmin/UserAdmin-container";

/**
 * Contains the logic for UserAdminUser
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

//TODO: wait to see if needed for user modification

// const emptyInputs = {
//   id: "",
//   userName: "",
//   userEmail: "",
//   userPhone: "",
//   userRole: "",
//   userOldPassword: "",
//   userNewPassword: "",
//   userConfirmPassword: ""
// };

// Validate that the inputs on the form are valid.
const validateInputs = (inputs, showModal) => {
  // If only some of the password inputs are specified,
  // then inputs are not valid.
  const oldPasswordNonEmpty = strNonEmpty(inputs.userOldPassword);
  const newPasswordNonEmpty = strNonEmpty(inputs.userNewPassword);
  const confirmPasswordNonEmpty = strNonEmpty(inputs.userConfirmPassword);
  if (oldPasswordNonEmpty || newPasswordNonEmpty || confirmPasswordNonEmpty) {
    if (
      !(oldPasswordNonEmpty && newPasswordNonEmpty && confirmPasswordNonEmpty)
    ) {
      showModal(
        "Save User",
        "If you specify any of the passwords, you must specify all of them."
      );
      return false;
    }
  }

  // New password and confirm password must be the same.
  if (inputs.userNewPassword !== inputs.userConfirmPassword) {
    showModal(
      "Save User",
      "New Password and Confirm Password must be the same."
    );
    return false;
  }

  return true;
};

// Update the profile record with the inputs from the form.
// const updateProfile = async (inputs, showModal) => {
//   try {
    
//     // const updatedProfile = await API.patch('AccountGatewayApi', `/account/${accountId}/profile/${inputs.id}` )
    
//   } catch (err) {
    
//     console.error("ProfileEdit-logic.js: Error occurred while updating profile (3)", err);
//     showModal("Save User", "Error occurred while saving user. (2)");
//     return false;
//   }

//   return true;
// };

// Update a Profile record.
const updateUser = async (inputs, showModal) => {
  // Update the profile
  // const retUpdateProfile = await updateProfile(inputs, showModal);

  // If updated successfully, then go back to the home page.
  // if (retUpdateProfile) {
  //   showModal("Save User", "Save Successful");
  // }
};

const deactivateUser = async showModal => {
  try {
    
  // should we even do this?
    
  } catch (err) {
    logError("UserAdminUser-logic.js deactivateUser Error", err);
  }
};

const useUserAdminUser = () => {
  
  const { userProfile } = useContext(UserAdminContext);
  
  // const [inputs, setInputs] = useState(emptyInputs);
  const [inputs, setInputs] = useState({});

  // const [profileId, setProfileId] = useState();

  const { showModal, showConfirm } = useContext(ModalContext);

  const handleSave = async event => {
    if (event) {
      event.preventDefault();
    }

    try {
      // Validate the inputs
      if (!validateInputs(inputs, showModal)) return;

      // if (!profileId) {
      if (!inputs.id) {
        logError(
          "UserAdminUser-logic.js: Error occurred while updating profile (3)",
          null
        );
        showModal("Save User", "Error occurred while saving user. (3)");
      } else {
        updateUser(inputs, showModal);
      }
    } catch (err) {
      logError(
        "UserAdminUser-logic.js: Error occurred while updating profile (2)",
        err
      );
      showModal("Save User", "Error occurred while saving user. (4)");
    }
  };

  const handleDeactivate = async event => {
    if (event) {
      event.preventDefault();
    }

    try {
      showConfirm(
        "Deactivate User",
        "Do you really want to deactivate the user?",
        () => {
          deactivateUser(showModal);
        }
      );
    } catch (err) {
      logError(
        "UserAdminUser-logic.js handleDeactivate error: Unknown error",
        err
      );
    }
  };

  useEffect(() => {
    if (userProfile && userProfile.id) {
      setInputs({
        id: userProfile.id,
        userName: userProfile.name || '',
        userEmail: userProfile.email,
        userPhone: userProfile.phone || '',
        userOldPassword: '',
        userNewPassword: '',
        userConfirmPassword: ''
      });
    }
    
  }, [userProfile]);

  return { inputs, setInputs, handleSave, showModal, handleDeactivate };
};

export default useUserAdminUser;
