import React, {useRef, useEffect} from 'react';

import { initializeDashboardEmbed, getDashboardEmbedURL } from 'util/dashboard';

export function DashboardsEmbedWrapper(props) {
    
    const quicksightRef = useRef();

    useEffect(() => {
        if (props.dashboardId) {
            async function init() {
                try {
                    const {EmbedUrl} = await getDashboardEmbedURL(props.dashboardId);
                    quicksightRef.current = await initializeDashboardEmbed(EmbedUrl);
                } catch (err) {
                    console.log('error getting dashboard embed url', err);
                }
            }
            init(props.dashboardId);
        }
    }, [props.dashboardId]);

    return <div className="dashboard-graph-full" id="embeddingContainer" style={{ height: '750px' }} ></div>;
}