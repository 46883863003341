import React, { memo, useState, useEffect, useContext, useRef } from "react";
import { ContractAdminContext} from "components/ContractAdmin/ContractAdmin-container";

function notSimpleProductScope(product) {
  switch (product) {
    case 'openquery':
    case 'vocal':
    case 'path':
    case 'predict':
    case 'npi':
      return false;
    default:
      return true;
  }
}

const ScopeView = ({
  scope,
  scopeIdx,
  handleRemoveScope,
  handleScopeChange
}) => {
  
  const { setScopesValidity } = useContext(ContractAdminContext);
  const [ isValidScope, setIsValidScope ] = useState(true);
  const pathInputRef = useRef(null);
  
  const setFocus = () => {
    if (pathInputRef.current) {
      pathInputRef.current.focus();
    }
  };
  
  useEffect(() => {
    if (!scope.product || !scope.permit) {
      setIsValidScope(false);
      setScopesValidity(prevArr => {
        let newArr = [...prevArr];
        newArr[scopeIdx] = false;
        return newArr;
      });
    } else {
      setIsValidScope(true);
      setScopesValidity(prevArr => {
        let newArr = [...prevArr];
        newArr[scopeIdx] = true;
        return newArr;
      });
    }
  },[ scope.product, scope.permit, scopeIdx, setScopesValidity ]);

  return (
    <>
      <div className="pl-3 pr-3 row">
        <div className="col">
          <select
            id="product"
            name="product"
            className={`form-control ${scope.product ? '' : 'is-invalid' }`}
            value={scope.product}
            onChange={e => handleScopeChange(e, scopeIdx, 'product')}
          >
            <option key="select" value="">Select Product</option>
            <option key="team" value="team">TEAM</option>
            <option key="code" value="code">CODE</option>
            <option key="openquery" value="openquery">Open Query</option>
            <option key="vocal" value="vocal">CARE - VOCAL</option>
            <option key="path" value="path">CARE - PATH</option>
            <option key="predict" value="predict">CARE - PREDICT</option>
            <option key="npi" value="npi">NPI</option>
          </select>
        </div>
        <div className="col">
          <select
            id="permit"
            name="permit"
            className={`form-control ${scope.permit ? '' : 'is-invalid' }`}
            value={scope.permit}
            onChange={e => handleScopeChange(e, scopeIdx, 'permit')}
          >
            <option key="choose" value="">Choose</option>
            <option key="allow" value="allow">Allow</option>
            <option key="deny" value="deny">Deny</option>
          </select>
        </div>
        <div className="col">
        { isValidScope && notSimpleProductScope(scope.product) && (
          <select
            as="select"
            id="methods"
            name="methods"
            className="form-control"
            value={scope.methods}
            onChange={e => handleScopeChange(e, scopeIdx, 'methods')}
            size="3"
            multiple={true}
          >
            <option key="*" value="*">ALL</option>
            <option key="get" value="get">GET</option>
            <option key="post" value="post">POST</option>
            <option key="delete" value="delete">DELETE</option>
            <option key="put" value="put">PUT</option>
            <option key="patch" value="patch">PATCH</option>
            <option key="options" value="options">OPTIONS</option>
          </select>
        )}
        </div>
        <div className="col-4">
        { isValidScope && notSimpleProductScope(scope.product) && (
          <input
            type="text"
            id="path"
            name="path"
            className="form-control"
            placeholder="e.g. /latest/*/(shared|market)"
            value={scope.path}
            ref={pathInputRef}
            onChange={e => {
              handleScopeChange(e, scopeIdx, 'path');
              setFocus();
            }}
          />
        )}
        </div>
        <div className="col">
          <button type="button" className="button-red" onClick={() => {
            setScopesValidity(prevArr => {
              let newArr = [...prevArr];
              newArr.splice(scopeIdx, 1);
              return newArr;
            });
            handleRemoveScope(scopeIdx);
          }}>
            Remove Scope
          </button>
        </div>
      </div>
    </>
  );
};

export default memo(ScopeView);
