import React from "react";
import HeaderView from "./Header-view";
import useHeader from "./Header-logic";

/**
 * Control the creation of the Header component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const HeaderContainer = () => {
  const {
    userName,
    signOut
  } = useHeader();

  return (
    <HeaderView
      userName={userName}
      signOut={signOut}
    />
  );
};

export default HeaderContainer;