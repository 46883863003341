import React, { memo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classes from "./UserDetails.module.scss";
import { SpinnerSmall } from "components/Spinner";

/**
 * Pure component that displays the UserDetails component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const UserDetailsView = ({ bio, userAccounts, manageUser, admins }) => {
  
  const userAccountLinkList = userAccounts.map((account, idx, src) => {
    return (
      <span key={account.id}>
        { account.role === "User"
          ? <span>{account.name}{src.length === (idx + 1) ? '':', '}</span>
          : <Link to={`/AccountAdmin/${account.id}`}>{account.name}{src.length === (idx + 1) ? '':', '}</Link>
        }
      </span>
    );
  });
  
  return (
    <div className={classes.user}>
      <div className={classes["user-header"]}>
        <div className={`bar-text ${classes["user-header-name"]}`}>User</div>
        <div className={classes["user-header-manage"]}>
          { userAccounts.filter(account => account.role !== 'User').length > 0 && (
            <button
              type="button"
              className={classes["user-header-button"]}
              onClick={manageUser}
            >
              Manage
            </button>
          )}
        </div>
      </div>
      <div className={classes["user-content"]}>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="container-fluid">
                <div className="row pt-2 pb-3 ml-n4">
                  <div className="col-md-3">Name</div>
                  <div className="col-md-9">{bio.name}</div>
                </div>
                <div className="row pb-3 ml-n4">
                  <div className="col-md-3">Email</div>
                  <div className="col-md-9">{bio.email}</div>
                </div>
                <div className="row ml-n4">
                  <div className="col-md-3">Account(s)</div>
                  { userAccounts
                    ?  userAccounts.length > 0
                      ? <div className="col-md-9">{userAccountLinkList}</div>
                      : <div className="col-md-9">none</div>
                    : <SpinnerSmall/>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserDetailsView.defaultProps = {
  userAccounts: ""
};

UserDetailsView.propTypes = {
  bio: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string
  }).isRequired,
  userAccounts: PropTypes.array,
  manageUser: PropTypes.func.isRequired
};

export default memo(UserDetailsView);
