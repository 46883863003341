import { useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";

const createContract = async (accountId, history, setAddingContract) => {
  try {
    setAddingContract(true);
    const request = {
      body: {
        "dates": {
          "start": new Date().toISOString(),/* e.g. "2020-05-15T18:29:32.033Z"*/
          "end": new Date().toISOString()/* e.g. "2020-05-15T18:29:32.033Z"*/
        },
        "scopes": [
          "prn::::"
        ],
        "agreement": ""
      },
      withCredentials: true
    };
    
    const contractData = await API.post('AccountGatewayApi', `/account/${accountId}/contract`, request);
    setAddingContract(false);
    history.push(`/ContractAdmin/${contractData.id}`);
    
  } catch (err) {
    setAddingContract(false);
    console.error(`error creating contract`, err);
  }
};

const useAccountAdminContracts = accountId => {
  const history = useHistory();
  const [ addingContract, setAddingContract ] = useState(false);
  const addContract = () => {
    createContract(accountId, history, setAddingContract);
  };

  return {
    addContract, addingContract
  };
};

export default useAccountAdminContracts;
