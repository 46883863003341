import React, { memo } from "react";
import PropTypes from "prop-types";
import UserAccount from "./UserAccount";
import classes from "./UserAccounts.module.scss";
import Spinner from "components/Spinner";

/**
 * Pure component that displays the UserAccounts component.
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const UserAccountsView = ({ accounts }) => {
  const alphabetizedAccounts = [...accounts]
  alphabetizedAccounts.sort(function (a, b) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });
  const listAccounts = alphabetizedAccounts.map(account => (
    <UserAccount key={account.accountId} account={account} />
  ));

  return (
    <div>
      <div className={classes.container}>
        <div className={classes["accounts-bar"]}>
          <div className="container-fluid">
            <div className="row pt-2 pb-2">
              <div className="col-auto">
                <span className="white-text bar-text">Account</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes["column-headers-bar"]}>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <span className="white-text">Account</span>
              </div>
            </div>
          </div>
        </div>
        { accounts.length > 0
          ? <div className={classes.content}>{listAccounts}</div>
          : <div className="p-3 text-center"><Spinner/></div>
        }
      </div>
    </div>
  );
};

UserAccountsView.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      name: PropTypes.string,
      brand: PropTypes.string,
      role: PropTypes.string,
      accountProfileId: PropTypes.string
    })
  ).isRequired
};

export default memo(UserAccountsView);
