import React, { useState, useMemo } from "react";

export function usePagination() {
  const [ paginationOffset, setPaginationOffset ] = useState(0);
  const [ paginationSize, setPaginationSize ] = useState(10);
  const [ filterString, setFilterString ] = useState('');
  const [ sortColumn, setSortColumn ] = useState('');
  const [ sortKey, setSortKey ] = useState(null);
  const [ sortDirection, setSortDirection ] = useState('ascending');
  
  return {
    paginationOffset, setPaginationOffset,
    paginationSize, setPaginationSize,
    filterString, setFilterString,
    sortColumn, setSortColumn,
    sortKey, setSortKey,
    sortDirection, setSortDirection
  };
}

export function PhGridPagination({
  fullList,
  paginationOffset,
  setPaginationOffset,
  setPaginationSize,
  paginationSize,
  filterString
}) {
  
  const paginatorSlots = useMemo(() => {
    
    const filteredList = fullList.filter(row => Object.values(row).join('').includes(filterString));
    const totalPages = Math.ceil(filteredList.length / paginationSize);
    const currentPage = (paginationOffset + paginationSize) / paginationSize;
    
    return {
      filteredLength: filteredList.length,
      first: {
        handler: () => setPaginationOffset(0),
        disabled: currentPage === 1
      },
      previous: {
        handler: () => setPaginationOffset(paginationOffset - paginationSize),
        disabled: currentPage === 1
      },
      one: {
        active: currentPage === 1,
        content: currentPage === 1 ? 1 : (totalPages > 2 && currentPage === totalPages) ? currentPage -2 : currentPage -1,
        visible: true,
        handler: () => {
          if (totalPages > 2 && currentPage === totalPages) {//when decrement page by two
            setPaginationOffset(paginationOffset - (paginationSize * 2));
          } else if (currentPage !== 1 && totalPages > 1) {//when decrement page by one
            setPaginationOffset(paginationOffset - paginationSize);
          }
        }
      },
      two: {
        active: currentPage !== 1 && ((currentPage !== totalPages && totalPages > 2) || (totalPages === 2 && currentPage === 2)),
        content: currentPage === 1 ? 2 : (currentPage === totalPages && totalPages !== 2) ? totalPages - 1 : currentPage,
        visible: totalPages > 1,
        handler: () => {
          if (currentPage === 1) {//when increment page by one
            setPaginationOffset(paginationSize);
          } else if (totalPages > 2 && currentPage === totalPages) {//when decrement page by one
            setPaginationOffset(paginationOffset - paginationSize);
          }
        }
      },
      three: {
        active: currentPage === totalPages && totalPages > 2,
        content: (totalPages > 2 && currentPage === totalPages) ? currentPage : (totalPages > 2 && currentPage === 1) ? currentPage + 2 : currentPage + 1,
        visible: totalPages > 2,
        handler: () => {
          if (totalPages > 2 && currentPage === 1) {//when increment page by two
            setPaginationOffset(paginationOffset + (paginationSize * 2));
          } else if (currentPage > 1 && currentPage < totalPages) {//when increment page by one
            setPaginationOffset(paginationOffset + paginationSize);
          }
        }
      },
      next: {
        handler: () => setPaginationOffset(paginationOffset + paginationSize),
        disabled: currentPage === totalPages
      },
      last: {
        handler: () => setPaginationOffset(paginationSize * (totalPages - 1)),
        disabled: currentPage === totalPages
      }
    };
  }, [
    fullList,
    paginationOffset,
    setPaginationOffset,
    paginationSize,
    filterString
  ]);
  
  return (<>
    <div className="pt-1">
      <select className="custom-select custom-select-sm text-primary" style={{cursor:'pointer'}} value={paginationSize} onChange={(e) => {
        setPaginationOffset(0);
        setPaginationSize(parseInt(e.target.value, 10));
      }}>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
    </div>
    <div className="p-2 text-primary" >Results {paginationOffset + 1} - { (paginationOffset + paginationSize) < paginatorSlots.filteredLength ? (paginationOffset + paginationSize) : paginatorSlots.filteredLength } of {paginatorSlots.filteredLength}</div>
    <nav>
      <ul className="pagination">
        <li className={ paginatorSlots.first.disabled ? 'page-item disabled' : 'page-item' }>
          <p className="page-link" style={{cursor:'pointer'}} tabIndex={paginatorSlots.first.disabled ? -1 : null} aria-disabled={paginatorSlots.first.disabled ? 'true': null} onClick={() => {
            paginatorSlots.first.handler();
          }}>First</p>
        </li>
        <li className={ paginatorSlots.previous.disabled ? 'page-item disabled' : 'page-item' }>
          <p className="page-link" style={{cursor:'pointer'}} tabIndex={paginatorSlots.previous.disabled ? -1 : null} aria-disabled={paginatorSlots.previous.disabled ? 'true': null} onClick={() => {
            paginatorSlots.previous.handler();
          }}>Previous</p>
        </li>
        <li className={ paginatorSlots.one.active ? 'page-item active' : 'page-item'}>
          <p className="page-link" style={{cursor:'pointer'}} onClick={() => paginatorSlots.one.handler()}>{ paginatorSlots.one.content }</p>
        </li>
        { paginatorSlots.two.visible && (
          <li className={ paginatorSlots.two.active ? 'page-item active' : 'page-item'}>
            <p className="page-link" style={{cursor:'pointer'}} onClick={() => paginatorSlots.two.handler()}>{ paginatorSlots.two.content }</p>
          </li>
        )}
        { paginatorSlots.three.visible && (
          <li className={ paginatorSlots.three.active ? 'page-item active' : 'page-item'}>
            <p className="page-link" style={{cursor:'pointer'}} onClick={() => paginatorSlots.three.handler()}>{ paginatorSlots.three.content }</p>
          </li>
        )}
        <li className={ paginatorSlots.next.disabled ? 'page-item disabled' : 'page-item' }>
          <p className="page-link" style={{cursor:'pointer'}} tabIndex={paginatorSlots.next.disabled ? -1 : null} aria-disabled={paginatorSlots.next.disabled ? 'true': null} onClick={() => {
            paginatorSlots.next.handler();
          }}>Next</p>
        </li>
        <li className={ paginatorSlots.last.disabled ? 'page-item disabled' : 'page-item' }>
          <p className="page-link" style={{cursor:'pointer'}} tabIndex={paginatorSlots.last.disabled ? -1 : null} aria-disabled={paginatorSlots.last.disabled ? 'true': null} onClick={() => {
            paginatorSlots.last.handler();
          }}>Last</p>
        </li>
      </ul>
    </nav>
  </>);
}

export function PhGridFilter({
  filterString,
  setFilterString,
  setPaginationOffset
}) {
  return (
    <div className="form-group">
      <input type="text" className="form-control" placeholder="Filter..." value={filterString} onChange={(e) => {
        setPaginationOffset(0);
        setFilterString(e.target.value);
      }} />
    </div>
  );
}

export function PhGridSort({
  columnLabel,
  columnName,
  config: {sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}
}) {
  
  return (<span
    style={{whiteSpace: 'nowrap'}}
    onClick={() => {
      if (sortColumn === columnLabel) {// same column click
        setSortDirection(sortDirection === 'descending' ? 'ascending': 'descending');
      } else {// new column click
        setSortColumn(columnLabel);
        setSortDirection('descending');
        setSortKey(() => columnName);
      }
      
    }}
  >
    <span>{columnLabel}</span>
    <span className="ml-1 mr-3" style={{position: 'relative'}}>
      { (sortColumn === columnLabel && sortDirection === 'ascending')
        ? (
          <svg style={{position: 'absolute', top: '-1px'}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 14 14">
            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
          </svg>
        )
        : (
          <svg style={{position: 'absolute', top: '-1px'}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="lightgray" className="bi bi-caret-up" viewBox="0 0 14 14">
            <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"/>
          </svg>
        )}
      { (sortColumn === columnLabel && sortDirection === 'descending')
        ? (
        <svg style={{position: 'absolute', bottom: '-1px'}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 14 14">
          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
        </svg>
        )
        : (
        <svg style={{position: 'absolute', bottom: '-1px'}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="lightgray" className="bi bi-caret-down" viewBox="0 0 14 14">
          <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
        </svg>
        )}
    </span>
  </span>);
}