import PropTypes from "prop-types";

const contractPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.string).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  scopes: PropTypes.arrayOf(PropTypes.string).isRequired,
  notes: PropTypes.string
});

export default contractPropTypes;
