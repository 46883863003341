import React from "react";
import PropTypes from "prop-types";
import ContractAdminView from "./ContractAdmin-view";
import useContractAdmin from "./ContractAdmin-logic";

/**
 * Control the creation of the ContractAdmin component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
 
export const ContractAdminContext = React.createContext({});

const ContractAdminContainer = ({ match }) => {
  const { contractId } = match.params;
  const {
    contract,
    reloadContract,
    inputs,
    setInputs,
    handleSave,
    isSaving,
    scopesValidity,
    setScopesValidity
  } = useContractAdmin(contractId);

  return(
    <ContractAdminContext.Provider value={{ contract, reloadContract, inputs, setInputs, handleSave, isSaving, scopesValidity, setScopesValidity }}>
      <ContractAdminView contractId={contractId} />
    </ContractAdminContext.Provider>
  ); 
};

ContractAdminContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired
  }).isRequired
};

export default ContractAdminContainer;
