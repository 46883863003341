import AccountAdmin from "./AccountAdmin-container";

/**
 * Export the top level component AccountAdmin-container
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export default AccountAdmin;
