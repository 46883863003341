import { useContext, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { UserAdminContext } from "components/UserAdmin/UserAdmin-container";
import { useHistory } from "react-router-dom";
import useProfileId from "hooks/useProfile";
import { logError } from "util/logging";

// STEVE : jsdocs?

const useUserAdminAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const history = useHistory();
  const { userProfile } = useContext(UserAdminContext);
  const { profileId } = useProfileId();

  const addNewUser = () => {
    history.push(`/profileedit`);
  };

  const addNewAccount = () => {
    history.push(`/account`);
  };

  useEffect(() => {
    async function fetchData() {
      
      try {
        let userAccountsRequest = await API.get('AccountGatewayApi', '/accounts', { withCredentials: true });
        const accountsToSet = userAccountsRequest.map(account => {
            let modifiedProfile = {
              accountId: account.id,
              name: account.name,
              brand: '',
              role: '',
              accountProfileId: ''
            };
            
            let pageUserAccountProfile;
            if (userProfile.accounts) {
              pageUserAccountProfile = userProfile.accounts.filter(profileAccount => profileAccount.id === account.id)[0];
            }
            
            if (pageUserAccountProfile) {
              modifiedProfile.role = pageUserAccountProfile.role;
              modifiedProfile.accountProfileId = userProfile.id;
            }
            return modifiedProfile;
        });
        
        setAccounts(accountsToSet);
        
      } catch (err) {
        console.error('userAccountsRequest error', err);
      }
      
    }

    try {
      if (profileId) fetchData();
    } catch (err) {
      logError("useAccountsReducer Error", err);
    }
  }, [profileId, userProfile]);

  return {
    accounts,
    addNewUser,
    addNewAccount
  };
};

export default useUserAdminAccounts;
