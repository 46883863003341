import { useContext } from "react";
import { ContractAdminContext} from "components/ContractAdmin/ContractAdmin-container";

/**
 * Contains the logic for ContractDetails
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const useContractDetails = contractId => {
  const { contract, inputs, setInputs, handleSave, isSaving, isValidContract, scopesValidity } = useContext(ContractAdminContext);
  return { inputs, setInputs, handleSave, contract, isSaving, isValidContract, scopesValidity };
};

export default useContractDetails;
