import MsgModal from "./MsgModal-container";

/**
 * Export the top level component MsgModal-view
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export default MsgModal;
