import { useContext, useState, useEffect } from "react";
import { API } from "aws-amplify";
import { BrandAdminContext } from "components/BrandAdmin/BrandAdmin-container";
import useGenericModal from "components/GenericModal/GenericModal-logic";
import useEditAccount from "hooks/useEditAccount";

// STEVE : jsdocs?

const emptyInputs = {
  accountName: '',
  accountBrand: ''
};

const useUserAdminAccounts = () => {
  const { brandId } = useContext(BrandAdminContext);
  const [accounts, setAccounts] = useState([]);
  const { modalOpen, setModalOpen } = useGenericModal();
  const [inputs, setInputs] = useState(emptyInputs);
  const { handleSaveAccount, handleDeactivateAccount } = useEditAccount(brandId);
  const [shouldUpdateBrandAccounts, updateBrandAccounts] = useState(false);
  
  useEffect(() => {
    const fetchBrandAccounts = async () => {
        try {
            const brandData = await API.get('AccountGatewayApi', `/brand/${brandId}`, { withCredentials: true });
            setAccounts(brandData.accounts);
            updateBrandAccounts(false);
        } catch (err) {
            console.error('fetch Brand accounts error', err);
        }
    };
    fetchBrandAccounts();
  },[brandId, shouldUpdateBrandAccounts]);
  
  return {
    accounts,
    modalOpen,
    setModalOpen,
    inputs,
    setInputs,
    handleSaveAccount,
    brandId,
    updateBrandAccounts,
    handleDeactivateAccount
  };
};

export default useUserAdminAccounts;
