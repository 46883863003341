import useProfileId from "hooks/useProfile";
import useProfileAccounts from "hooks/useProfileAccounts";
import { useHistory } from "react-router-dom";

/**
 * Contains the logic for UserDetails
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const useUserDetails = () => {
  
  const history = useHistory();
  const { profileId, profileEmail, profileName } = useProfileId();
  const accounts = useProfileAccounts(profileId);
  const manageUser = () => {
    history.push(`/UserAdmin/${profileId}`);
  };

  return {
    bio: {
      id: profileId,
      email: profileEmail,
      name: profileName
    },
    userAccounts: accounts,
    manageUser
  };
};

export default useUserDetails;
