import PropTypes from "prop-types";
import userManagePropTypes from "./userManagePropTypes";

const accountManagePropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  users: PropTypes.arrayOf(userManagePropTypes)
});

export default accountManagePropTypes;
