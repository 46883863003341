import UserAdmin from "./UserAdmin-container";

/**
 * Export the top level component UserAdmin-container
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export default UserAdmin;
