import React, { useEffect, useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { ModalContext } from "contexts/Modal";
import { consoleClear } from "util/logging";
import classes from "./GenericModal.module.scss";
import { InlineSpinner } from "components/Spinner";

const GenericModal = ({
  title,
  onSave,
  children,
  onCancel,
  modalOpen,
  headerStyle,
  footerStyle,
  modalWidth,
  disabled
}) => {
  /* The react-bootstrap Modal uses some deprecated functions.
     When the Modal is rendered, it works, but throws some
     warnings in the console. 
     
     The warnings only get written to the console the
     first time the Modal is rendered.
     
     Until the Modal is fixed, the following code 
     will clear the console after only the first 
     time the Modal is rendered.
  */
  const { hasModalShown, setHasModalShown } = useContext(ModalContext);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    setInProgress(false);
    if (modalOpen && !hasModalShown) {
      consoleClear();
      setHasModalShown(true);
    }
  }, [modalOpen, hasModalShown, setHasModalShown]);
  /* End of the Modal warnings code  */

  return (
    <>
      <Modal
        show={modalOpen}
        onHide={onCancel}
        dialogClassName={`${classes[modalWidth]}`}
      >
        <Modal.Header
          closeButton
          className={`${classes["header-background"]}`}
          style={headerStyle}
        >
          <Modal.Title style={{ color: "white", marginLeft: "auto" }}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer style={footerStyle}>
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setInProgress(true);
                onSave();
              }}
              className={`${classes["modal-button"]}`}
              disabled={ disabled }
            >
              { inProgress && <InlineSpinner/>}
              Save
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                onCancel();
              }}
              className={`${classes["modal-button-cancel"]}`}
            >
              Cancel
            </Button>
          </>
        </Modal.Footer>
      </Modal>
    </>
  );
};

GenericModal.defaultProps = {
  headerStyle: {},
  footerStyle: {},
  modalWidth: ""
};

GenericModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  headerStyle: PropTypes.any,
  footerStyle: PropTypes.any,
  modalWidth: PropTypes.string
};

export default GenericModal;
