import React, { memo } from "react";
import { Link } from "react-router-dom";
import accountManagePropTypes from "constants/accountManagePropTypes";
import PropTypes from "prop-types";
import GenericModal from "components/GenericModal";
import classes from "./UserAdminAccount.module.scss";
import handleInputChange from "util/forms";

const UserAdminAccountView = ({
  account,
  history,
  userProfile,
  setModalOpen,
  modalOpen,
  handleSaveProfile,
  initInputs,
  inputs,
  updateUser,
  setInputs,
  availableRoles
}) => {
  
  return (
    <>
      <div className={`pt-2 ${classes["account-row"]}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3">
              <Link to={`/AccountAdmin/${account.accountId}`}>
                {account.name}
              </Link>
            </div>
            <div className="col-3">
              <span>{account.role}</span>
            </div>
            <div className="col" />
            <div className="col-2">
              {account.accountProfileId && (
                <button
                  type="button"
                  className={`button-primary ${classes["button-width"]}`}
                  onClick={() => setModalOpen(true)}
                >
                  Modify
                </button>
              )}
              {!account.accountProfileId && (
                <button
                  type="button"
                  className={`button-secondary ${classes["button-width"]}`}
                  onClick={() => setModalOpen(true)}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          <GenericModal
            title={account.accountProfileId ? 'Modify Role' : 'Add Role'}
            modalOpen={modalOpen}
            onSave={event => {
              handleSaveProfile(
                event,
                account.accountId,
                initInputs,
                inputs,
                setModalOpen,
                null,
                userProfile.id,
                updateUser
              );
            }}
            onCancel={() => setModalOpen(false)}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-2 pr-0">
                  <span className="float-right pt-2">Role</span>
                </div>
                <div className="col-md-10">
                  <div className="form-group">
                    <select
                      id="userRole"
                      name="userRole"
                      value={inputs ? inputs.userRole : ''}
                      onChange={e => handleInputChange(e, setInputs)}
                      className="form-control rounded w-75 pl-2"
                    >
                      {availableRoles.map(role => <option key={role} value={role}>{role}</option>)}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </GenericModal>
        </div>
      </div>
    </>
  );
};

UserAdminAccountView.propTypes = {
  account: accountManagePropTypes.isRequired,
  history: PropTypes.object.isRequired
};

export default memo(UserAdminAccountView);
