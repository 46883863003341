import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { logError } from "util/logging";

/**
 * Contains hook to get user bio info and profileId.
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const useProfileId = () => {
  const [profileId, setProfileId] = useState("");
  const [profileEmail, setProfileEmail] = useState("");
  const [profileName, setProfileName] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (!user || !user.attributes || !user.attributes.sub) {
          const errorMsg =
            "useProfileId error: Error getting currentAuthenticatedUser";
          logError(errorMsg, null);
          setError(errorMsg);
          return;
        }

        setProfileId(user.attributes.sub);
        setProfileEmail(user.attributes.email);
        setProfileName(user.attributes.name || user.attributes.email);

      } catch (err) {
        logError("useProfileId error: Unknown error", err);
        setError(err);
      }
    };

    fetchUser();
  },[]);

  return { profileId, profileEmail, profileName, error };
};

export default useProfileId;
