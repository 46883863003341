import UserAccount from "./UserAccount-container";

/**
 * Export the top level component UserAccount-container
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export default UserAccount;
