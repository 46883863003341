import React, { memo } from "react";
import PropTypes from "prop-types";
import classes from "./UserBrand.module.scss";

const UserBrandView = ({ brand, history }) => {
  return (
    <>
      <div className={`pt-2 ${classes["brand-row"]}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <span className="white-text">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => history.push(`/BrandAdmin/${brand.brandName}`)}
                >
                  {brand.brandName}
                </button>
              </span>
            </div>
            <div className="col">
              <span>{brand.accountName}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserBrandView.propTypes = {
  brand: PropTypes.shape({
    brandName: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.object.isRequired
};

export default memo(UserBrandView);
