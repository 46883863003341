import React from "react";
import PropTypes from "prop-types";
import Scope from "./Scope";
import classes from "./Scopes.module.scss";
import Spinner from "components/Spinner";

const ScopesView = ({
  scopes,
  setScopes,
  handleCreateScope,
  handleRemoveScope,
  handleScopeChange
}) => {
  const listScopes = scopes.map((scope, scopeIdx) => {
    return (
      <Scope
        key={scopeIdx}
        scope={scope}
        scopeIdx={scopeIdx}
        handleRemoveScope={handleRemoveScope}
        handleScopeChange={handleScopeChange}
      />
    );
  });

  return (
    <>
      <div className={`pt-4 classes["flex-container"]`}>
        <div className={classes["header-bar"]}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-10 pt-1">
                <span className="white-text bar-text">Scopes of Services</span>
              </div>
              
              <div className="col-2">
                <button
                  type="button"
                  className={classes["create-scope-button"]}
                  onClick={handleCreateScope}
                >
                  Create Scope
                </button>
              </div>
              <div className="col" />
            </div>
          </div>
        </div>
        <div className={classes["column-headers-bar"]}>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <span className="white-text">Product</span>
              </div>
              <div className="col">
                <span className="white-text">Allow / Deny</span>
              </div>
              <div className="col">
                <span className="white-text">Methods</span>
              </div>
              <div className="col-4">
                <span className="white-text">Path  e.g. /latest/*/(shared|market)</span>
              </div>
              
              <div className="col" />
            </div>
          </div>
        </div>
        { scopes.length > 0
          ? <div className={`pt-3 pb-3 ${classes.content}`}>{listScopes}</div>
          : <div className="p-3 text-center"><Spinner/></div>
        }
      </div>
    </>
  );
};

ScopesView.propTypes = {
  scopes: PropTypes.arrayOf(
    PropTypes.shape({
      ttm: PropTypes.string,
      sourceMix: PropTypes.string,
      teamingWindow: PropTypes.string,
      history: PropTypes.string
    })
  ).isRequired,
  ttmAvail: PropTypes.arrayOf(PropTypes.string)/*.isRequired*/,
  sourceMixAvail: PropTypes.arrayOf(PropTypes.string)/*.isRequired*/,
  teamingWindowAvail: PropTypes.arrayOf(PropTypes.string)/*.isRequired*/,
  historyAvail: PropTypes.arrayOf(PropTypes.string)/*.isRequired*/
};

export default ScopesView;
