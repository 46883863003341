import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import classes from "./UserBrands.module.scss";
import UserBrand from "./UserBrand";
import GenericModal from "components/GenericModal";
import handleInputChange from "util/forms";
import Spinner from "components/Spinner";

/**
 * Pure component that displays the UserBrands component.
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const UserBrandsView = ({ brands, modalOpen, setModalOpen, inputs, setInputs, handleSave }) => {
  const alphabetizedBrands = [...brands]
  alphabetizedBrands.sort(function (a, b) {
    return a.brandName.toLowerCase() > b.brandName.toLowerCase() ? 1 : -1;
  });
  const listBrands = alphabetizedBrands.map(brand => (
    <UserBrand key={`${brand.brandName}${brand.accountName}`} brand={brand} />
  ));
  
  const disabled = useMemo(() => {
    return !inputs.brandName.match(/^[a-zA-Z0-9_-]{1,255}$/gm);
  }, [inputs]);
  
  return (
    <div>
      <div className={classes.container}>
        <div className={classes["accounts-bar"]}>
          <div className="container-fluid">
            <div className="flex-row pt-2 pb-2">
              <div>
                <span className="white-text bar-text">My Brands</span>
              </div>
              <div className="flex-1"></div>
              <div>
                <button
                  type="button"
                  className="account-header-button"
                  onClick={() => setModalOpen(true)}
                >
                  Add Brand
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes["column-headers-bar"]}>
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <span className="white-text">Brand</span>
              </div>
              <div className="col">
                <span className="white-text">Account</span>
              </div>
            </div>
          </div>
        </div>
        { brands.length > 0
          ? <div className={classes.content}>{listBrands}</div>
          : <div className="p-3 text-center"><Spinner/></div>
        }
      </div>
      
      <GenericModal
        title="Create Brand"
        modalOpen={modalOpen}
        onSave={event => { handleSave(event) } }
        onCancel={() => setModalOpen(false)}
        disabled={ disabled }
      >
        <div className="flex-row">
          <div className="mt-1 pr-2">Name</div>
          <div className={inputs.brandName ? 'form-group was-validated flex-1' : 'form-group flex-1' }>
            <input
              type="text"
              pattern="[A-Za-z0-9_-]{1,255}"
              className="form-control border rounded w-100 pl-2"
              id="brandName"
              name="brandName"
              placeholder="Enter Brand name"
              value={inputs.brandName}
              onChange={e => handleInputChange(e, setInputs)}
            />
            {inputs.brandName && !inputs.brandName.match(/^[a-zA-Z0-9_-]{1,255}$/gm) && <div className="invalid-feedback">May only contain a-z, A-Z, 0-9, -, and _</div>}
          </div>
        </div>
      </GenericModal>
    </div>
  );
};

UserBrandsView.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      brandName: PropTypes.string,
      accountName: PropTypes.string
    })
  ).isRequired,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func.isRequired,
  inputs: PropTypes.shape({
    brandName: PropTypes.string,
  }),
  setInputs: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default memo(UserBrandsView);
