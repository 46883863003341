import React from "react";
import PropTypes from "prop-types";
import userPropType from "constants/userPropType";
import UserView from "./User-view";

/**
 * Control the creation of the User component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const UserContainer = ({ inputs, setInputs, hideOldPassword, currentUserProfile, availableRoles }) => {
  return (
    <UserView
      inputs={inputs}
      setInputs={setInputs}
      hideOldPassword={hideOldPassword}
      currentUserProfile={currentUserProfile}
      availableRoles={availableRoles}
    />
  );
};

UserContainer.defaultProps = {
  hideOldPassword: false
};

UserContainer.propTypes = {
  inputs: userPropType.isRequired,
  setInputs: PropTypes.func.isRequired,
  hideOldPassword: PropTypes.bool
};

export default UserContainer;
