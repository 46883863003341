import React from "react";
import PropTypes from "prop-types";
import userPropType from "constants/userPropType";
import AccountAdminUserView from "./AccountAdminUser-view";
import useAccountAdminUser from "./AccountAdminUser-logic";

/* eslint no-unused-vars: 0  */

/**
 * Control the creation of the AccountAdminUser component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const AccountAdminUserContainer = ({ user, setListUsers }) => {
  const {
    initInputs,
    inputs,
    setInputs,
    modalOpen,
    setModalOpen,
    handleSaveProfile,
    currentUserProfile,
    accountId,
    updateAccount,
    modalOption,
    setModalOption,
    handleDeactivateProfile,
    availableRoles
  } = useAccountAdminUser(user);

  return (
    <AccountAdminUserView
      key={user.profileId}
      user={user}
      setListUsers={setListUsers}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      initInputs={initInputs}
      inputs={inputs}
      setInputs={setInputs}
      handleSaveProfile={handleSaveProfile}
      currentUserProfile={currentUserProfile}
      accountId={accountId}
      updateAccount={updateAccount}
      modalOption={modalOption}
      setModalOption={setModalOption}
      handleDeactivateProfile={handleDeactivateProfile}
      availableRoles={availableRoles}
    />
  );
};

AccountAdminUserContainer.propTypes = {
  user: userPropType.isRequired,
  setListUsers: PropTypes.func.isRequired
};

export default AccountAdminUserContainer;
