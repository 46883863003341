import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import Modal from "react-bootstrap/Modal";
import Spinner, { InlineSpinner } from "components/Spinner";
import OpenQueryReminderModal from "components/OpenQueryReminderModal";

function OpenQueryRemindersContainer(props) {

  const [ reminders, setReminders ] = useState(null);
  const [ showReminderModal, setShowReminderModal ] = useState(false);
  const [ deleteInProgress, setDeleteInProgress ] = useState(null);
  const [ errorMsg, setErrorMsg ] = useState('');
  const [ reminderToEdit, setReminderToEdit ] = useState(null);
  
  useEffect(() => {
    async function getReminders() {
      try {
        const reminders = await API.get('OpenQueryAPI',`/${process.env.REACT_APP_TARGET}/reminders`);
        setReminders(reminders.Items);
      } catch (err) {
        console.error('error fetching reminders', err);
      }
    }
    getReminders();
  },[]);
  
  const handleReminderEdit = (item) => {
    setReminderToEdit(item);
    setShowReminderModal(true);
  };
  
  const handleCloseReminderModal = () => {
    setShowReminderModal(false);
  };
  
  const handleReminderDelete = async (id) => {
    try {
      setDeleteInProgress(id);
      await API.del('OpenQueryAPI',`/${process.env.REACT_APP_TARGET}/reminders/${id}`);
      const reminders = await API.get('OpenQueryAPI',`/${process.env.REACT_APP_TARGET}/reminders`);
      setDeleteInProgress(null);
      setReminders(reminders.Items);
    } catch (err) {
      setDeleteInProgress(null);
      setErrorMsg('error deleting reminder');
      console.error('error deleting reminder', err);
    }
  };
  
  return (<>
    <div className="card">
      <div className="card-header text-light font-weight-bold container-fluid" style={{backgroundColor: '#439945'}}>
        <div className="row">
          <div className="col-12">Reminders</div>
        </div>
      </div>
      <div clasName="card-body p-0">
        <table className="table table-striped table-responsive" style={{padding: 0}}>
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Type</th>
              <th scope="col">Value</th>
              <th scope="col">Description</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            { !reminders && <td colspan="6" className="text-center"><Spinner/></td> }
            { reminders && reminders.map(item => (
              <tr key={item.REMINDER_ID}>
                <th scope="row">{item.REMINDER_ID || ''}</th>
                <td>{item.DATE_TYPE || ''}</td>
                <td>{item.DATE_VALUE || ''}</td>
                <td>{item.REMINDER_DESCRIPTION || ''}</td>
                <td><button className="btn btn-secondary" onClick={() => handleReminderEdit(item) }>Edit</button></td>
                <td><button className="btn btn-danger" onClick={() => handleReminderDelete(item.REMINDER_ID)}>{ deleteInProgress === item.REMINDER_ID && <InlineSpinner/> }Delete</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    <Modal
      show={errorMsg}
      onHide={() => setErrorMsg('')}
    >
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{errorMsg}</p>
      </Modal.Body>
    </Modal>
    <OpenQueryReminderModal
      title="Manage Reminder"
      reminder={reminderToEdit}
      setReminders={setReminders}
      showReminderModal={showReminderModal}
      handleCloseReminderModal={handleCloseReminderModal}
    />
  </>);
}

export default OpenQueryRemindersContainer;