import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import GenericModal from "components/GenericModal";
import userPropType from "constants/userPropType";
import handleInputChange from "util/forms";

const AccountAdminUserView = ({
  user,
  setModalOpen,
  modalOpen,
  initInputs,
  inputs,
  setInputs,
  handleSaveProfile,
  setListUsers,
  currentUserProfile,
  accountId,
  updateAccount,
  modalOption,
  setModalOption,
  handleDeactivateProfile,
  availableRoles
}) => {
  
  return (
    <div className="w-100 row pt-3 pb-3">
      <div className="col-3 mr-1">
        <Link style={{overflowWrap: 'break-word'}} to={`/UserAdmin/${user.id}`}>{user.name || user.email || 'unnamed'}</Link>
      </div>
      <div className="col-auto">
        <span>{user.role}</span>
      </div>
      <div className="col" />
      <div className="col-auto pl-0 pr-2 m-0">
        <button
          type="button"
          className="button-primary float-right"
          onClick={() => { setModalOpen(true); }}
        >
          Manage
        </button>
      </div>
      <div className="col-auto p-0 m-0">
        <button type="button" className="button-red float-right" onClick={() => handleDeactivateProfile(accountId, user.id, updateAccount) }>
          Remove
        </button>
      </div>

      <GenericModal
        title="Manage User"
        modalOpen={modalOpen}
        onSave={event => {
            handleSaveProfile(
              event,
              accountId,
              initInputs,
              inputs,
              setModalOpen,
              setListUsers,
              user.id,
              updateAccount
            );
        }}
        onCancel={() => setModalOpen(false)}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-2 pr-0">
              <span className="float-right pt-2">Role</span>
            </div>
            <div className="col-md-10">
              <div className="form-group">
                <select
                  id="userRole"
                  name="userRole"
                  value={inputs.userRole}
                  onChange={e => handleInputChange(e, setInputs)}
                  className="form-control rounded w-75 pl-2"
                >
                  {availableRoles.map(role => <option key={role} value={role}>{role}</option>)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </GenericModal>
    </div>
  );
};

const emptyInputs = {
  userName: "",
  userEmail: "",
  userPhone: "",
  userOldPassword: "",
  userNewPassword: "",
  userConfirmPassword: ""
};

AccountAdminUserView.defaultProps = {
  inputs: emptyInputs,
  initInputs: emptyInputs
};

AccountAdminUserView.propTypes = {
  user: userPropType.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  initInputs: userPropType,
  inputs: userPropType,
  setInputs: PropTypes.func.isRequired,
  handleSaveProfile: PropTypes.func.isRequired,
  setListUsers: PropTypes.func.isRequired
};

export default AccountAdminUserView;
