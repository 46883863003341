import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import MsgModal from "components/MsgModal";
import UserDetails from "components/UserDetails";
import UserAdmin from "components/UserAdmin";
import UserAccounts from "components/UserAccounts";
import AccountAdmin from "components/AccountAdmin";
import ContractAdmin from "components/ContractAdmin";
import UserBrands from "components/UserBrands";
import BrandAdmin from "components/BrandAdmin";
import OpenQueryLoad from "components/OpenQuery";
import OpenQueryQueries from "components/OpenQueryQueries";
import OpenQueryReminders from "components/OpenQueryReminders";
import OpenQueryQuery from "components/OpenQueryQueries/Query";
import MyFiles from "components/MyFiles";
import MonitoringServicesPage from "components/MonitoringServices";
import QueryVizualizationReportPage from "components/QueryViz";

import { LoggedInUserContext } from "contexts/LoggedInUser"

/**
 * Pure component that displays the Dashboard component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
 
const DashboardView = () => {
  
  const { loggedInUserProfile } = useContext(LoggedInUserContext);

  return (
    <Router>
      <div className="container-fluid" style={loggedInUserProfile?.accounts?.length > 0 ? null : {display: 'none'} }>
        <div className="row">
          <div className="col-md-12 py-3">
            <Header />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <Sidebar />
                </div>
                <div className="col-md-9">
                  <Route exact path="/" component={UserDetails} />
                  <Route path="/UserAdmin/:userId" component={UserAdmin} />
                  <Route path="/UserAccounts" component={UserAccounts} />
                  <Route path="/AccountAdmin/:accountId" component={AccountAdmin} />
                  <Route path="/ContractAdmin/:contractId" component={ContractAdmin} />
                  <Route path="/UserBrands" component={UserBrands} />
                  <Route path="/BrandAdmin/:brandId" component={BrandAdmin} />
                  <Route path="/myfiles" component={MyFiles} />
                  <Route path="/aware" component={MonitoringServicesPage}/>
                  <Route path="/queryvisualization/:jobId" component={QueryVizualizationReportPage}/>
                  <Switch>
                    <Route path="/openquery/job/:jobId" component={OpenQueryQuery}/>
                    <Route path="/openquery/queries" component={OpenQueryQueries} />
                    <Route path="/openquery/reminders" component={OpenQueryReminders}/>
                    <Route path="/openquery/:payerMix" component={OpenQueryLoad} />
                    <Route path="/openquery" component={OpenQueryLoad} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MsgModal />
      </div>
      <ToastContainer />
    </Router>
  );
};

export default DashboardView;
