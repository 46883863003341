import React from "react";
import PropTypes from "prop-types";
import GenericModal from "components/GenericModal";
import User from "components/User";
import userPropType from "constants/userPropType";
import AccountAdminUser from "./AccountAdminUser";
import classes from "./AccountAdminUsers.module.scss";
import Spinner from "components/Spinner";

const AccountAdminUsersView = ({
  accountId,
  users,
  setListUsers,
  modalOpen,
  setModalOpen,
  inputs,
  setInputs,
  handleSaveProfile,
  currentUserProfile,
  updateAccount,
  availableRoles,
  accountFetched
}) => {
  const alphabetizedUsers = [...users]
  alphabetizedUsers.sort(function (a, b) {
    const nameOrEmailA = a.name ? a.name : a.email
    const nameOrEmailB = b.name ? b.name : b.email
    return nameOrEmailA.toLowerCase() > nameOrEmailB.toLowerCase() ? 1 : -1;
  });
  const listUsers = alphabetizedUsers ? alphabetizedUsers.map(user => (
    <AccountAdminUser
      key={user.id}
      user={user}
      setListUsers={setListUsers}
    />
  )) : [];

  return (
    <div className={`${classes["flex-container"]}`}>
      <div className={`w-100 row ${classes["account-users-bar"]}`}>
        <div className="col-auto pt-2">
          <span className="white-text bar-text">Account Users</span>
        </div>
        <div className="col" />
        <div className={`col-auto pr-3 ${classes["add-user-div"]}`}>
          <button
            type="button"
            className={classes["add-user-button"]}
            onClick={() => {
              setInputs({
                userName: "",
                userEmail: "",
                userRole: ""
              });
              setModalOpen(true);
            }}
          >
            Add User
          </button>
        </div>
      </div>
      <div className={`w-100 row ${classes["col-headers-bar"]}`}>
        <div className="col-3">
          <span className="white-text">User Name</span>
        </div>
        <div className="col-auto">
          <span className="white-text">User Role</span>
        </div>
      </div>

      <div className={`w-100 row ${classes.content}`}>
        { users.length > 0
          ? <div className="col pr-0">{listUsers}</div>
          : <div className="col p-3 text-center">{ accountFetched ? '' : <Spinner/> }</div>
        }
      </div>

      <GenericModal
        title="Create User to Add to Account"
        modalOpen={modalOpen}
        onSave={event =>
          handleSaveProfile(
            event,
            accountId,
            {},
            inputs,
            setModalOpen,
            setListUsers,
            "",
            updateAccount
          )
        }
        onCancel={() => setModalOpen(false)}
      >
        <div className="container-fluid">
          <User
            inputs={inputs}
            setInputs={setInputs}
            currentUserProfile={currentUserProfile}
            availableRoles={availableRoles}
            hideOldPassword 
          />
        </div>
      </GenericModal>
    </div>
  );
};

AccountAdminUsersView.defaultProps = {
  users: []
};

AccountAdminUsersView.propTypes = {
  accountId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      profileId: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      role: PropTypes.string,
      accountProfileId: PropTypes.string
    })
  ),
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  inputs: userPropType.isRequired,
  setInputs: PropTypes.func.isRequired,
  handleSaveProfile: PropTypes.func.isRequired,
  setListUsers: PropTypes.func.isRequired
};

export default AccountAdminUsersView;
