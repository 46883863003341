import GlobalErrorBoundary from "./GlobalErrorBoundary";

/**
 * Export all ErrorBoundary components
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

export default GlobalErrorBoundary;
