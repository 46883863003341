export const roles = ['GlobalAdmin','BrandAdmin','Admin','User'];

export function getAvailableRoles(myRole) {
    return roles.slice(roles.indexOf(myRole));
}

export function highestUserRole(userRoles) {
    const userRolesSet = new Set(userRoles);
    if (userRolesSet.has('GlobalAdmin')) {
        return 'GlobalAdmin';
    } else if (userRolesSet.has('BrandAdmin')) {
        return 'BrandAdmin';
    } else if (userRolesSet.has('Admin')) {
        return 'Admin';
    } else if (userRolesSet.has('User')) {
        return 'User';
    } else {
        return null;
    }
}