import React, { memo } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import handleInputChange, { handleDateChange } from "util/forms";
import classes from "./ContractDetails.module.scss";
import { InlineSpinner } from "components/Spinner";

/**
 * Pure component that displays the ContractDetails component.
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const ContractDetailsView = ({ inputs, setInputs, handleSave, contract, isSaving, isValidContract, scopesValidity }) => {
  
  const canSave = scopesValidity.reduce((acc, val) => {
    if (acc === false || val === false) {
      return false;
    } else {
      return true;
    }
  }, true);
  
  return ( inputs && (
    <div className={`${classes["flex-container"]}`}>
      <div className={`w-100 ${classes.header}`}>
        <span className="white-text bar-text">Contract</span>
      </div>
      <div className={`w-100 ${classes.content}`}>
        <div className={`w-100 pt-3 ${classes.name}`}>
          <div className="row">
            <div className="col-2 text-align-right pr-4">
              <span>Id</span>
            </div>
            <div className="col-10 p-0">
              <p>{contract.id}</p>
            </div>
          </div>
        </div>
        <div className={`w-100 pt-0 pb-3 ${classes.agreement}`}>
          <div className="row">
            <div className="col-2 text-align-right pr-4">
              <span>Agreement</span>
            </div>
            <div className="col-10 p-0">
              <input
                type="text"
                className="form-control-plaintext border rounded w-75 pl-2"
                id="agreement"
                name="agreement"
                placeholder="Salesforce Opportunity ID #"
                value={inputs.agreement}
                onChange={e => handleInputChange(e, setInputs)}
              />
            </div>
          </div>
        </div>
        <div className="`w-100 pt-0 pb-3">
          <div className="row">
            
            <div className="col-5 offset-1">
              <label>Start</label>
              <DatePicker
                name="start"
                selected={inputs.datesStart}
                onChange={date => handleDateChange(date, 'datesStart', setInputs)}
                className="form-control"
                placeholderText="Set Start Date"
              />
            </div>
            
            <div className="col-5">
              <label>End</label>
              <DatePicker
                name="end"
                selected={inputs.datesEnd}
                onChange={date => handleDateChange(date, 'datesEnd', setInputs)}
                className="form-control"
                placeholderText="Set End Date"
              />
            </div>
          </div>
        </div>
        <div className={`w-100 pt-0 pb-3 ${classes.save}`}>
          <div className="row">
            <div className="col-2 text-align-right pr-4" />
            <div className="col-8 p-0 ml-n4">
              <button
                type="button"
                disabled={!canSave}
                style={canSave ? null : {backgroundColor:'#6c757d', opacity: '.65'}}
                className="button-primary float-right"
                onClick={() => {
                  handleSave();
                }}
              >
                { isSaving && <InlineSpinner/> }
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

export default memo(ContractDetailsView);
