import React, { useContext } from "react";
import { ModalContext } from "contexts/Modal";
import MsgModalView from "./MsgModal-view";

/**
 * Control the creation of the MsgModal component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const MsgModalContainer = () => {
  const {
    modalTitle,
    modalMsg,
    isModalVisible,
    onHide,
    isConfirm,
    confirmCallback
  } = useContext(ModalContext);

  return (
    <MsgModalView
      title={modalTitle}
      msg={modalMsg}
      show={isModalVisible}
      onHide={onHide}
      isConfirm={isConfirm}
      confirmCallback={confirmCallback}
    />
  );
};

export default MsgModalContainer;
