import React, { memo } from "react";
import PropTypes from "prop-types";
import AccountAdminAccountView from "./AccountAdminAccount-view";
import useAccountAdminAccount from "./AccountAdminAccount-logic";

/* eslint no-unused-vars: 0  */

/**
 * Control the creation of the AccountAdminAccount component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const AccountAdminAccountContainer = ({ accountId, account }) => {
  const { inputs, setInputs, handleSave, availableBrands, saving } = useAccountAdminAccount(accountId, account);
  
  return (
    <AccountAdminAccountView
      inputs={inputs}
      setInputs={setInputs}
      handleSave={handleSave}
      availableBrands={availableBrands}
      saving={saving}
    />
  );
};

AccountAdminAccountContainer.propTypes = {
  accountId: PropTypes.string.isRequired
};

export default memo(AccountAdminAccountContainer);
