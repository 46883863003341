import React from "react";
import UserBrandsView from "./UserBrands-view";
import useUserBrands from "./UserBrands-logic";

/**
 * Control the creation of the UserBrands component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const UserBrandsContainer = () => {
  
  const { userBrands, modalOpen, setModalOpen, inputs, setInputs, handleSave } = useUserBrands();
  
  return (
    <UserBrandsView
      brands={userBrands}
      modalOpen={modalOpen} 
      setModalOpen={setModalOpen}
      inputs={inputs} 
      setInputs={setInputs}
      handleSave={handleSave}
    />
  );
};

export default UserBrandsContainer;
