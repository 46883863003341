import React from "react";
import PropTypes from "prop-types";
import AccountAdminUsersView from "./AccountAdminUsers-view";
import useAccountAdminUsers from "./AccountAdminUsers-logic";

/* eslint no-unused-vars: 0  */

/**
 * Control the creation of the AccountAdminUsers component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const AccountAdminUsersContainer = ({ accountId, accountProfiles }) => {
  
  const {
    listUsers,
    setListUsers,
    modalOpen,
    setModalOpen,
    inputs,
    setInputs,
    handleSaveProfile,
    modalOption,
    setModalOption,
    currentUserProfile,
    updateAccount,
    availableRoles,
    accountFetched
  } = useAccountAdminUsers(accountId, accountProfiles);
  
  return (
    <AccountAdminUsersView
      accountId={accountId}
      users={listUsers}
      setListUsers={setListUsers}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      inputs={inputs}
      setInputs={setInputs}
      handleSaveProfile={handleSaveProfile}
      currentUserProfile={currentUserProfile}
      updateAccount={updateAccount}
      availableRoles={availableRoles}
      accountFetched={accountFetched}
    />
  );
};

AccountAdminUsersContainer.propTypes = {
  accountId: PropTypes.string.isRequired
};

export default AccountAdminUsersContainer;
