import { useState, useEffect } from "react";
import { API } from "aws-amplify";

const useAccountAdmin = match => {
  const [account, setAccount] = useState({});
  const [accountFetched, setAccountFetched] = useState(false);
  const [shouldUpdateAccount, updateAccount] = useState(false);
  const [currentUserProfile, setCurrentUserProfile] = useState({});
  const { accountId } = match.params;

  useEffect(() => {
    async function getAccountDetails() {
      try {
        const accountDetails = await API.get('AccountGatewayApi', `/account/${accountId}`, { withCredentials: true });
        setAccount(accountDetails);
        setAccountFetched(true);
        updateAccount(false);
      } catch (err) {
        setAccountFetched(true);
        console.error('error fetching accountDetails', err);
      }
    }
    getAccountDetails();
  }, [accountId, shouldUpdateAccount]);

  useEffect(() => {
    async function getCurrentUserAccountProfile() {
      try {
        const profile = await API.get('AccountGatewayApi', '/profile', { withCredentials: true });
        const accountIdx = profile.accounts.findIndex(account => account.id === accountId);
        setCurrentUserProfile(profile.accounts[accountIdx]);
      } catch (err) {
        console.error('error fetching current user account profile', err);
      }
    }
    getCurrentUserAccountProfile();
  }, [accountId]);

  return {
    account, setAccount, shouldUpdateAccount, updateAccount, currentUserProfile, setCurrentUserProfile, accountFetched
  };
};

export default useAccountAdmin;
