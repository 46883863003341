import React, { memo } from "react";
import PropTypes from "prop-types";
import classes from "./UserAdminUser.module.scss";

/**
 * Pure component that displays the UserAdminUser component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const UserAdminUserView = ({
  inputs,
  setInputs,
  handleSave,
  handleDeactivate
}) => {
  return (
    <div className={classes.user}>
      <div className={classes["user-header"]}>
        <div className={`bar-text ${classes["user-header-name"]}`}>User</div>
      </div>
      <div className={classes["user-content"]}>
        <div className="container-fluid">
          <div className="row p-3">
            <div className="col-2 text-right">Name</div>
            <div className="col-10">{inputs.userName}</div>
          </div>
          <div className="row p-3">
            <div className="col-2 text-right">Email</div>
            <div className="col-10">{inputs.userEmail}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserAdminUserView.propTypes = {
  inputs: PropTypes.shape({
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userPhone: PropTypes.string
  }).isRequired,
  setInputs: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleDeactivate: PropTypes.func.isRequired
};

export default memo(UserAdminUserView);
