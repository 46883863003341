import React from "react";
import useUserDetails from "./UserDetails-logic";
import UserDetailsView from "./UserDetails-view";

/**
 * Control the creation of the UserDetails component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const UserDetailsContainer = () => {
  const { bio, userAccounts, manageUser } = useUserDetails();

  return (
    bio.name &&
    bio.id && (
      <UserDetailsView
        bio={bio}
        userAccounts={userAccounts}
        manageUser={() => manageUser(bio.id)}
      />
    )
  );
};

export default UserDetailsContainer;
