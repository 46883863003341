import React, { memo } from "react";
import PropTypes from "prop-types";
import classes from "./BrandAccount.module.scss";

const BrandAccountView = ({ account, history, handleDeactivateAccount, updateBrandAccounts, brand }) => {
  return (
    <>
      <div className={`pt-2 w-100 ${classes["account-row"]}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-9 pl-0">
              <span className="white-text">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => history.push(`/AccountAdmin/${account.id}`)}
                >
                  {account.name}
                </button>
              </span>
            </div>
            <div className="col-3 pl-0 pr-5 m-0">
              <button
                type="button"
                className="float-right button-red"
                onClick={() => handleDeactivateAccount(brand, account.id, updateBrandAccounts)}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BrandAccountView.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.object.isRequired
};

export default memo(BrandAccountView);
