import React, { memo } from "react";
import PropTypes from "prop-types";
import handleInputChange from "util/forms";
import userPropType from "constants/userPropType";

/**
 * Pure component that displays the User component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const UserView = ({ inputs, setInputs, hideOldPassword, currentUserProfile, availableRoles }) => {
  
  if (availableRoles && Array.isArray(availableRoles)) {
    availableRoles.push('');
    availableRoles.reverse();
  }
  
  return (
    <div>
      <div className="row pt-3">
        <div className="col-md-2 pr-0">
          <span className="float-right pt-2">Name</span>
        </div>
        <div className="col-md-10">
          <div className={inputs.userName ? 'form-group was-validated' : 'form-group' }>
            <input
              type="text"
              className="form-control border rounded w-75 pl-2"
              id="userName"
              name="userName"
              placeholder="Enter name"
              value={inputs.userName}
              onChange={e => handleInputChange(e, setInputs)}
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-2 pr-0">
          <span className="float-right pt-2">Email</span>
        </div>
        <div className="col-md-10">
          <div className={inputs.userEmail ? 'form-group was-validated' : 'form-group' }>
            <input
              type="email"
              className="form-control border rounded w-75 pl-2"
              id="userEmail"
              name="userEmail"
              placeholder="Enter email"
              value={inputs.userEmail}
              onChange={e => handleInputChange(e, setInputs)}
              required
            />
          </div>
        </div>
      </div>
      { availableRoles &&
        <div className="row">
          <div className="col-md-2 pr-0">
            <span className="float-right pt-2">Role</span>
          </div>
          <div className="col-md-10">
            <div className={inputs.userRole ? 'form-group was-validated' : 'form-group' }>
              <select
                id="userRole"
                name="userRole"
                value={inputs.userRole || ''}
                onChange={e => handleInputChange(e, setInputs)}
                className="form-control rounded w-75 pl-2"
                required
              >
                { availableRoles.map(role => <option key={role} value={role}>{role ? role : 'Select Role'}</option>) }
              </select>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

UserView.propTypes = {
  inputs: userPropType.isRequired,
  setInputs: PropTypes.func.isRequired,
  hideOldPassword: PropTypes.bool.isRequired
};

export default memo(UserView);
