import { useState, useContext, useEffect } from "react";
import { API } from "aws-amplify";
import useGenericModal from "components/GenericModal/GenericModal-logic";
import { ModalContext } from "contexts/Modal";
import { strNonEmpty } from "util/strings";


const emptyInputs = {
  name: "",
  content: ""
};

const validateInputs = (inputs, showModal) => {
  
  const nameNonEmpty = strNonEmpty(inputs.name);
  const contentNonEmpty = strNonEmpty(inputs.content);
  
  if (!nameNonEmpty || !contentNonEmpty) {
    showModal(
      "Save Config",
      `${nameNonEmpty ? '' : 'Must include name. '}${contentNonEmpty ? '' : 'Must include JSON body. ' }`
    );
    return false;
  } else {
    return true;
  }
};

const useAccountAdminConfigs = (accountId) => {
  const { showModal } = useContext(ModalContext);
  const { modalOpen, setModalOpen } = useGenericModal();
  const [config, setConfigs] = useState({});
  const [inputs, setInputs] = useState(emptyInputs);
  
  const getAccountConfigs = async (accountId) => {
    try {
      const fetchedConfigs = await API.get('AccountGatewayApi', `/account/${accountId}/config`);
      setConfigs(fetchedConfigs);
    } catch (err) {
      console.error('error fetching configs', err);
    }
  };
  
  const getConfigKey = async (accountId, configKey) => {
    try {
      return await API.get('AccountGatewayApi', `/account/${accountId}/config/${configKey}/`);
    } catch (err) {
      console.error('error fetching config by key', err);
    }
  };
  
  const handleConfigSave = async (event, accountId, inputs, setModalOpen) => {
    if (event) {
      event.preventDefault();
    }
    try {
      // Validate the inputs
      if (!validateInputs(inputs, showModal)) return;
      
      const parsedJson = JSON.parse(inputs.content);
      
      await API.put('AccountGatewayApi', `/account/${accountId}/config/${inputs.name}`, {
        body: parsedJson,
        withCredentials: true
      });
      setModalOpen(false);
      showModal("Save Config", "Config successfully saved.");
      getAccountConfigs(accountId);//refresh list after save
    } catch (err) {
      console.error('error saving config', err);
      setModalOpen(false);
      showModal("Save Config", "Error occurred while saving config.");
    }
  };
  
  const handleConfigDelete = async (accountId, configKey) => {
    try {
      await API.del('AccountGatewayApi', `/account/${accountId}/config/${configKey}`, {
        withCredentials: true
      });
      setModalOpen(false);
      showModal("Remove Config", "Config successfully removed.");
      getAccountConfigs(accountId);//refresh list after config set to empty object
    } catch (err) {
      console.error('error emptying config', err);
      setModalOpen(false);
      showModal("Remove Config", "Error occurred while removing config.");
    }
  };
  
  useEffect(()=>{
    getAccountConfigs(accountId);
  },[accountId]);
  
  return {
    modalOpen,
    setModalOpen,
    inputs,
    setInputs,
    config,
    setConfigs,
    getAccountConfigs,
    handleConfigSave,
    handleConfigDelete,
    getConfigKey
  };
};

export default useAccountAdminConfigs;