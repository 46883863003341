import React, { memo } from "react";
import BrandDetails from "./BrandDetails";
import BrandAccounts from "./BrandAccounts";

/**
 * Pure component that displays the BrandAdmin component.
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const BrandAdminView = () => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <BrandDetails />
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <BrandAccounts />
        </div>
      </div>
    </div>
  );
};

export default memo(BrandAdminView);
