import Account from "./Account-container";

/**
 * Export the top level component User-container
 *
 * @version 0.0.1
 * @author Josh Lloyd
 *
 */
export default Account;
