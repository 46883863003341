import React from "react";
import { withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from "aws-amplify";
import "./App.scss";
import { HistoryContextProvider } from "contexts/History";
import { LoggedInUserContextProvider } from "contexts/LoggedInUser";
import amplifyConfig from "./amplifyConfig";
import GlobalErrorBoundary from "./errorboundaries";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./components/Dashboard";
import { ModalContextProvider } from "./contexts/Modal";

Amplify.configure(amplifyConfig);

function App() {
  return (
    <GlobalErrorBoundary>
      <ModalContextProvider>
        <LoggedInUserContextProvider>
          <HistoryContextProvider>
            <Dashboard />
          </HistoryContextProvider>
        </LoggedInUserContextProvider>
      </ModalContextProvider>
    </GlobalErrorBoundary>
  );
}

export default withAuthenticator(App, {
  loginMechanisms: ["email"], hideSignUp: true, formFields: {
    signIn: {
      username: {
        labelHidden: false,
        label: "Email Address",
        isRequired: true,
        placeholder: 'Enter your email address',
        autocomplete: "off"
      },
      password: {
        labelHidden: false,
        label: "Password",
        isRequired: true,
        placeholder: 'Enter your password',
        autocomplete: "off"
      },
    }
  },
  components: {
    SignIn: {
      Header() {
        return (
          <h3 style={{ marginLeft: 1.25 + 'em', marginTop: 1.25 + 'em' }}>
            Sign in to your account
          </h3>);
      }
    }
  }
});
