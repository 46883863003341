import React from "react";
import PropTypes from "prop-types";
import classes from "./Sidebar.module.scss";
import teamlogoFull from "assets/img/team_logo.png";
import carelogoFull from "assets/img/care_logo.png";
import teamquerylogoFull from "assets/img/team-query.png";
import teamawarelogoFull from "assets/img/team-aware.png";
import { Link, Route, Switch } from "react-router-dom";

/* eslint jsx-a11y/anchor-is-valid: 0 */

/**
 * Pure component that displays the Sidebar component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const ApplicationList = () => (
  <>
    <li className={classes["sidebar-item"]}>
      <div className={classes.content}>
        <a href={`https://team.perceptionhealth.${process.env.REACT_APP_TARGET === 'prod' ? 'com' : 'dev' }/`} target="_blank" rel="noopener noreferrer">
          <img src={teamlogoFull} alt="Team Icon" />
        </a>
      </div>
    </li>
    <li className={`${classes["sidebar-item"]}`}>
      <div className={classes.content}>
        <Link to={"/openquery"}>
          <img src={teamquerylogoFull} alt="Open Query Icon" />
        </Link>
      </div>
    </li>
    <li className={`${classes["sidebar-item"]}`}>
      <div className={classes.content}>
        <Link to={"/aware"}>
          <img src={teamawarelogoFull} alt="Team Icon" />
        </Link>
      </div>
    </li>
    <li className={classes["sidebar-item"]}>
      <div className={classes.content}>
        <a href={`https://care.perceptionhealth.${process.env.REACT_APP_TARGET === 'prod' ? 'com' : 'dev' }/`} target="_blank" rel="noopener noreferrer">
          <img src={carelogoFull} alt="Care Icon" />
        </a>
      </div>
    </li>
  </>
);

const OpenQuerySidebar = () => {

  return (
    <div className={classes.sidebar}>
      <nav id="sidebar" className={classes["sidebar-nav"]}>
        <ul className="list-unstyled">
          <li className={`bar-text ${classes["open-query-sidebar-menu"]}`}>Query Menu</li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/openquery/queries"}>
              My Queries
            </Link>
          </li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/openquery/reminders"}>
              My Reminders
            </Link>
          </li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/myfiles"}>
              My Files
            </Link>
          </li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/openquery"}>
              Submit New Query
            </Link>
          </li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/"}>
              My User
            </Link>
          </li>
          <li className={`bar-text ${classes["open-query-sidebar-applications"]}`}>
            Applications
          </li>
          <ApplicationList/>
        </ul>
      </nav>
    </div>
  );
};

const MonitoringServicesSidebar = () => {

  return (
    <div className={classes.sidebar}>
      <nav id="sidebar" className={classes["sidebar-nav"]}>
        <ul className="list-unstyled">
          <li className={`bar-text ${classes["open-query-sidebar-menu"]}`}>Aware Reports Menu</li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/aware"}>
              My Aware Reports
            </Link>
          </li>
          <li className={`bar-text ${classes["open-query-sidebar-applications"]}`}>
            Applications
          </li>
          <ApplicationList/>
        </ul>
      </nav>
    </div>
  );
};

const MyFilesSidebar = () => {

  return (
    <div className={classes.sidebar}>
      <nav id="sidebar" className={classes["sidebar-nav"]}>
        <ul className="list-unstyled">
          <li className={`bar-text ${classes["my-files-sidebar-menu"]}`}>Query Menu</li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/openquery/queries"}>
              My Queries
            </Link>
          </li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/openquery/reminders"}>
              My Reminders
            </Link>
          </li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/myfiles"}>
              My Files
            </Link>
          </li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/openquery"}>
              Submit New Query
            </Link>
          </li>
          <li className={classes["sidebar-item"]}>
            <Link to={"/"}>
              My User
            </Link>
          </li>
          <li className={`bar-text ${classes["my-files-sidebar-applications"]}`}>
            Applications
          </li>
          <ApplicationList/>
        </ul>
      </nav>
    </div>
  );
};
 
const AccountSidebar = ({ counts }) => {

  return (
    <div className={classes.sidebar}>
      <nav id="sidebar" className={classes["sidebar-nav"]}>
        <ul className="list-unstyled">
          <li className={`bar-text ${classes["sidebar-menu"]}`}>Account Menu</li>
          { counts.brands > 0 && (
            <li className={classes["sidebar-item"]}>
              <Link to={"/UserBrands"}>
                { counts.brands > 1
                  ? 'Brands'
                  : 'Brand'
                }
              </Link>
            </li>
          )}
          { counts.accounts > 0 && (
            <li className={classes["sidebar-item"]}>
              <Link to={"/UserAccounts"}>
                { counts.accounts > 1
                  ? 'Accounts'
                  : 'Account'
                }
              </Link>
            </li>
          )}
          <li className={classes["sidebar-item"]}>
            <Link to={"/"}>
              My User
            </Link>
          </li>
          <li className={`bar-text ${classes["sidebar-applications"]}`}>
            Your Applications
          </li>
          <ApplicationList/>
        </ul>
      </nav>
    </div>
  );
};

const SidebarView = ({ loggedInUserProfile }) => {
  
  const counts = loggedInUserProfile.accounts.reduce((acc, account) => {
    
    if (account.role === 'GlobalAdmin' || account.role === 'BrandAdmin' || account.role === 'admin') {
      acc.accounts += 1;
    }
    if (account.role === 'GlobalAdmin' || account.role === 'BrandAdmin') {
      acc.brands += 1;
    }
    return acc;
  }, {
    brands: 0,
    accounts: 0
  });
  
  return (
    <Switch>
      <Route exact path="/">
        <AccountSidebar counts={counts}/>
      </Route>
      <Route path={[
        "/UserAdmin/:userId",
        "/UserAccounts",
        "/AccountAdmin/:accountId",
        "/ContractAdmin/:contractId",
        "/UserBrands",
        "/BrandAdmin/:brandId"
      ]}>
        <AccountSidebar counts={counts}/>
      </Route>
      <Route path="/openquery">
        <OpenQuerySidebar/>
      </Route>
      <Route path="/aware">
        <MonitoringServicesSidebar/>
      </Route>
      <Route path={[
        "/myfiles",
        "/queryvisualization/:visualizationId"
      ]}>
        <MyFilesSidebar/>
      </Route>
    </Switch>
  );
};

SidebarView.defaultProps = {
  history: null
};

SidebarView.propTypes = {
  history: PropTypes.object
};

export default SidebarView;
