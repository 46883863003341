import React, { memo } from "react";
import handleInputChange from "util/forms";

const AccountView = ({
  inputs,
  setInputs,
  currentUserProfile,
  brands
}) => {
  return (
    <>
      <div className="row pt-3">
        <div className="col-md-2 pr-0">
          <span className="float-right pt-2">Name</span>
        </div>
        <div className="col-md-10">
          <div className="form-group">
            <input
              type="text"
              className="form-control-plaintext border rounded w-75 pl-2"
              id="accountName"
              name="accountName"
              placeholder="Enter Account name"
              value={inputs.accountName}
              onChange={e => handleInputChange(e, setInputs)}
            />
          </div>
        </div>
      </div>
      {currentUserProfile &&
        <div className="row">
          <div className="col-md-2 pr-0">
            <span className="float-right pt-2">Brand</span>
          </div>
          <div className="col-md-10">
            <div className="form-group">
              <select
                id="accountBrand"
                name="accountBrand"
                value={inputs.accountBrand}
                onChange={e => handleInputChange(e, setInputs)}
                className="form-control rounded w-75 pl-2"
              >
                {brands.map(brand => <option value={brand.name}>{brand.name}</option>)}
              </select>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default memo(AccountView);