import PropTypes from "prop-types";

const profilePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cognito: PropTypes.string,
  salesforce: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string
});

export default profilePropTypes;
