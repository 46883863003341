import React, { createContext } from "react";
import PropTypes from "prop-types";
import useHistoryReducer from "reducers/History/useHistoryReducer";

//TODO: check if this context is no longer needed due to the re-implemented routing

export const Context = createContext({});

export const Provider = ({ children }) => {
  const { state, dispatch } = useHistoryReducer();

  // pass the value in provider and return
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

Provider.defaultProps = {
  children: []
};

Provider.propTypes = {
  children: PropTypes.object
};

export const { Consumer } = Context;
