import { useState, useEffect, useContext } from "react";
import useGenericModal from "components/GenericModal/GenericModal-logic";
import useEditProfile from "hooks/useEditProfile";
import { AccountAdminContext } from "components/AccountAdmin/AccountAdmin-container";
import { LoggedInUserContext } from "contexts/LoggedInUser";
import { getAvailableRoles, highestUserRole/*, roles*/ } from "util/role";

const useAccountAdminUser = user => {
  const { modalOpen, setModalOpen } = useGenericModal();
  const [modalOption, setModalOption] = useState('');
  const { currentUserProfile, accountId, updateAccount } = useContext(AccountAdminContext);
  const { loggedInUserProfile } = useContext(LoggedInUserContext);
  const [inputs, setInputs] = useState();
  const [initInputs, setInitInputs] = useState();
  const { handleSaveProfile, handleDeactivateProfile } = useEditProfile(accountId);
  const availableRoles = getAvailableRoles(highestUserRole(loggedInUserProfile.accounts.map(account => account.role)));

  // Whenever the user updates, update the initInputs
  useEffect(() => {
    const newInputs = {
      userName: user.name,
      userEmail: user.email,
      userPhone: user.phone,
      userRole: user.role,
      userOldPassword: "",
      userNewPassword: "",
      userConfirmPassword: ""
    };
    setInitInputs(newInputs);
  }, [user]);

  // Whenever the modal opens, update
  // inputs with the current initInputs
  useEffect(() => {
    if (modalOpen) {
      setInputs(initInputs);
    }
  }, [modalOpen, initInputs]);

  return {
    initInputs,
    inputs,
    setInputs,
    modalOpen,
    setModalOpen,
    handleSaveProfile,
    currentUserProfile,
    accountId,
    updateAccount,
    modalOption,
    setModalOption,
    handleDeactivateProfile,
    availableRoles
  };
};

export default useAccountAdminUser;
