import { useState, useEffect, useContext } from "react";
import useGenericModal from "components/GenericModal/GenericModal-logic";
import useEditProfile from "hooks/useEditProfile";
import { AccountAdminContext } from "components/AccountAdmin/AccountAdmin-container";
import { LoggedInUserContext } from "contexts/LoggedInUser";
import { getAvailableRoles, highestUserRole/*, roles*/ } from "util/role";

const emptyInputs = {
  userName: "",
  userEmail: "",
  userRole: ""
};

const useAccountAdminUsers = (accountId, accountProfiles) => {
  
  const { loggedInUserProfile } = useContext(LoggedInUserContext);
  const [listUsers, setListUsers] = useState([]);
  const { modalOpen, setModalOpen } = useGenericModal();
  const [inputs, setInputs] = useState(emptyInputs);
  const { handleSaveProfile } = useEditProfile(accountId);
  const { currentUserProfile, updateAccount, accountFetched } = useContext(AccountAdminContext);
  const availableRoles = getAvailableRoles(highestUserRole(loggedInUserProfile.accounts.map(account => account.role)));

  useEffect(() => {
    setListUsers(accountProfiles);
  },[accountProfiles]);

  return {
    listUsers,
    setListUsers,
    modalOpen,
    setModalOpen,
    inputs,
    setInputs,
    handleSaveProfile,
    currentUserProfile,
    updateAccount,
    availableRoles,
    accountFetched
  };
};

export default useAccountAdminUsers;
