import { useState, useContext, useEffect } from "react";
import { BrandAdminContext } from "components/BrandAdmin/BrandAdmin-container";

/**
 * Contains the logic for BrandDetails
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const emptyInputs = {
  name: "Acme Brand",
  logo: "logo.png"
};

const useBrandDetails = () => {
  const { brandId } = useContext(BrandAdminContext);
  const [inputs, setInputs] = useState(emptyInputs);

  const handleSave = async event => {
    if (event) {
      event.preventDefault();
    }
  };

  const handleDeactivate = async event => {
    if (event) {
      event.preventDefault();
    }
  };
  
  useEffect(() => {
    setInputs({
      name: brandId,
      logo: "logo.png"
    });
  },[brandId]);

  return { inputs, setInputs, handleSave, handleDeactivate };
};

export default useBrandDetails;
