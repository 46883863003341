import { useContext } from "react";
import { ModalContext } from "contexts/Modal";
import { logError, logInfo } from "util/logging";
import { strNonEmpty } from "util/strings";
import { API } from "aws-amplify";

const useEditAccount = brandId => {
  const { showModal, showConfirm } = useContext(ModalContext);
  
  const handleSaveAccount = async (
    event,
    brandId,
    initInputs,
    inputs,
    setModalOpen,
    setListAccounts,
    accounts,
    accountId = "",
    updateBrandAccounts
  ) => {
    if (event) {
      event.preventDefault();
    }

    try {
      //Validate inputs
      if (!validateInputs(accountId, inputs, showModal)) return;
      
      if (!strNonEmpty(accountId)) {
        createAccount(
          brandId,
          inputs,
          showModal,
          setListAccounts,
          updateBrandAccounts
        );
      } else {
        updateAccount(
          accountId,
          initInputs,
          inputs,
          showModal,
          setListAccounts,
          accounts,
          brandId,
          updateBrandAccounts
        );
      }
      setModalOpen(false);
    } catch (err) {
      logError("useEditAccount.js: Error occurred while updating account (2)", err);
      showModal("Save Account", "Error occurred while saving account. (7)");
    }
    
  };
  
  const handleDeactivateAccount = async (brandId, accountId, updateBrandAccounts) => {

    try {
      showConfirm(
        "Deactivate Account",
        "Do you really want to deactivate the account?",
        () => {
          deactivateAccount(brandId, accountId, showModal, updateBrandAccounts);
        }
      );
    } catch (err) {
      logError(
        "useEditAccount.js handleDeactivateAccount error: Unknown error",
        err
      );
    }
  };
  
  return {
    handleSaveAccount,
    handleDeactivateAccount
  };
};

const validateInputs = (accountId, inputs, showModal) => {
  
  // If account name is empty form is invalid
  const accountNameNonEmpty = strNonEmpty(inputs.accountName);
  
  // If accountId is non-empty, then an existing account is being
  // updated and need to validate oldPassword.
  //
  // If accountId is empty, then a new user is being created
  // and oldPassword does not need to be validated.
  if (strNonEmpty(accountId)) {
    if (accountNameNonEmpty) {
      return true;
    } else {
      showModal('Edit Account Name', "Account name can't be empty");
      return false;
    }
  } else {
     if (accountNameNonEmpty) {
       return true;
     } else {
       showModal('Create Account', "Account name can't be empty");
     }
  }
  
};

const createAccount = async (
  brandId,
  inputs,
  showModal,
  setListAccounts,
  updateBrandAccounts
) => {
  try {
    
    const accountToCreate = {
      name: inputs.accountName,
      brand: brandId
    };

    await API.post('AccountGatewayApi', `/account`, {
      body: accountToCreate,
      withCredentials: true
    });
    
    updateBrandAccounts(true);
    
  } catch (err) {
    logError("createAccount error", err);
    showModal("Add Account", "An unknown error occurred. (3)");
  }
};

const updateAccount = async (
  accountId,
  initInputs,
  inputs,
  showModal,
  setListAccounts,
  accounts,
  brandId,
  updateBrandAccounts
) => {
  try {
    
    if (
      initInputs.accountName === inputs.accountName
    ) {
      return true;
    }
    
    Object.keys(inputs).forEach(key => {
      if (!inputs[key]) {
        delete inputs[key];
      }
    });
    await API.patch('AccountGatewayApi', `/account/${accountId}`, {
      body: { name: inputs.accountName },
      withCredentials: true
    });
    updateBrandAccounts(true);
    
  } catch (err) {
    logError("useEditAccount.js: Error occurred while updating account (3)", err);
    showModal("Save Account", "Error occurred while saving account. (6)");
    return false;
  }
};

const deactivateAccount = async (brandId, accountId, showModal, updateBrandAccounts) => {
  try {
    
    const removedAccount = await API.patch('AccountGatewayApi', `/account/${accountId}`, {
      body: {brand: "default"},//TODO: need a defined way to delete accounts and/or remove from brand other than reassignment
      withCredentials: true
    });
    logInfo("useProfile adminDisableAccount successful", removedAccount); // successful response
    updateBrandAccounts(true);
    
  } catch (err) {
    logError("useEditAccount.js deactivateAccount Error", err);
    showModal(
      "Deactivate Account",
      "An error occurred while deactivating account."
    );
  }
};

export default useEditAccount;