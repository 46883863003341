import React from "react";
import PropTypes from "prop-types";
import BrandAccount from "./BrandAccount";
import classes from "./BrandAccounts.module.scss";
import GenericModal from "components/GenericModal";
import Account from "components/Account";

const BrandAccountsView = ({
  accounts,
  modalOpen,
  setModalOpen,
  handleSaveAccount,
  brandId,
  inputs,
  setInputs,
  setListAccounts,
  updateBrandAccounts,
  currentUserProfile,
  handleDeactivateAccount
}) => {
  const alphabetizedAccounts = [...accounts]
  alphabetizedAccounts.sort(function (a, b) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });
  const listAccounts = alphabetizedAccounts.map(account => (
    <BrandAccount
      key={`${account.brand}${account.name}`}
      account={account}
      brand={brandId}
      handleDeactivateAccount={handleDeactivateAccount}
      updateBrandAccounts={updateBrandAccounts}
    />
  ));
  
  return (
    <>
      <div className={classes.container}>
        <div className={classes["accounts-bar"]}>
          <div className="container-fluid">
            <div className="row pt-2 pb-2">
              <div className="col-auto">
                <span className="white-text bar-text">Accounts</span>
              </div>
              <div className="col" />
              <div className="col-auto inline-block">
                <button
                  type="button"
                  className="account-header-button"
                  onClick={() => setModalOpen(true)}
                >
                  Add Account
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.content}>{listAccounts}</div>
        <GenericModal
          title="Create Account to Add to Brand"
          modalOpen={modalOpen}
          onSave={event =>
            handleSaveAccount(
              event,
              brandId,
              {},
              inputs,
              setModalOpen,
              setListAccounts,
              [],
              "",
              updateBrandAccounts
            )
          }
          onCancel={() => setModalOpen(false)}
        >
          <div className="container-fluid">
            <Account inputs={inputs} setInputs={setInputs} currentUserProfile={currentUserProfile} />
          </div>
        </GenericModal>
      </div>
    </>
  );
};

BrandAccountsView.defaultProps = {
  accounts: []
};

BrandAccountsView.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

export default BrandAccountsView;
