import React, { memo } from "react";
import PropTypes from "prop-types";
import ContractAccount from "./ContractAccount";
import classes from "./ContractAccounts.module.scss";

/**
 * Pure component that displays the ContractAccounts component.
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const ContractAccountsView = ({ accounts }) => {
  const listAccounts = accounts.map(account => (
    <ContractAccount key={account.id} account={account} />
  ));

  return (
    <div className={classes.container}>
      <div className={classes["accounts-bar"]}>
        <div className="container-fluid">
          <div className="row pt-2 pb-2">
            <div className="col-auto">
              <span className="white-text bar-text">Contract Accounts</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.content}>{listAccounts}</div>
    </div>
  );
};

ContractAccountsView.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      accountContractId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired
};

export default memo(ContractAccountsView);
