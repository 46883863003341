import React from "react";
import PropTypes from "prop-types";
import ScopeView from "./Scope-view";

const ScopeContainer = ({
  scope,
  scopeIdx,
  handleRemoveScope,
  handleScopeChange
}) => {
  
  return (
    <ScopeView
      scope={scope}
      scopeIdx={scopeIdx}
      handleRemoveScope={handleRemoveScope}
      handleScopeChange={handleScopeChange}
    />
  );
};

ScopeContainer.propTypes = {
  scope: PropTypes.shape({
    ttm: PropTypes.string,
    sourceMix: PropTypes.string,
    teamingWindow: PropTypes.string,
    history: PropTypes.string
  }).isRequired,
  ttmAvail: PropTypes.arrayOf(PropTypes.string),
  sourceMixAvail: PropTypes.arrayOf(PropTypes.string),
  teamingWindowAvail: PropTypes.arrayOf(PropTypes.string),
  historyAvail: PropTypes.arrayOf(PropTypes.string)
};

export default ScopeContainer;
