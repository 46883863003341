import React, { useState, useEffect, useMemo } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import Spinner from "components/Spinner";
import { PhGridPagination, PhGridFilter, usePagination, PhGridSort } from 'components/PhGrid';

function calcDaysTilPurge(createdDate) {
  const purgeTime = createdDate.getTime() + (90 * 24 * 3600 * 1000);// 90 days from created date
  const rightNowDate = new Date();
  const timeTillPurge = (purgeTime - rightNowDate.getTime());
  const daysTillPurge = timeTillPurge / (1000 * 3600 * 24);
  return daysTillPurge < 0 ? 'expired' : daysTillPurge < 1 ? '< 1 day' : `${Math.floor(daysTillPurge)} days`;
}

//https://ourcodeworld.com/articles/read/713/converting-bytes-to-human-readable-values-kb-mb-gb-tb-pb-eb-zb-yb-with-javascript
function readableBytes(bytes) {
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
}

function MyFilesContainer(props) {
  
  const {
    paginationOffset, setPaginationOffset,
    paginationSize, setPaginationSize,
    filterString, setFilterString,
    sortColumn, setSortColumn,
    sortKey, setSortKey,
    sortDirection, setSortDirection
  } = usePagination();
  
  const [ files, setFiles ] = useState(null);
  
  useEffect(() => {
    async function getFilesList() {
      const filesResponse = await API.get('FileApi',`/${process.env.REACT_APP_TARGET}/filemanagement/files`);
      setFiles(filesResponse.map((file, fileIdx) => {
        const fileCopy = {...file, 'created_date': new Date(file.created_date)};
        // fileCopy.canBeVisualized = true;//TODO: remove when ready
        return fileCopy;
      }));
    }
    getFilesList();
  },[]);
  
  const displayList = useMemo(() => {
    if (!files) return [];
    return files
      .filter(file => [
        file.description,
        file.id,
        file.file_size ? readableBytes(file.file_size) : 'unavailable',
        file.created_date.toLocaleDateString(),
        calcDaysTilPurge(file.created_date)
      ].join('').toLowerCase().includes(filterString.toLowerCase()))
      .sort((a,b) => {
        if (sortColumn) {
          if (sortDirection === 'ascending') {
            return sortKey(a) > sortKey(b) ? 1 : sortKey(a) < sortKey(b) ? -1 : 0 ;
          } else {
            return sortKey(a) > sortKey(b) ? -1 : sortKey(a) < sortKey(b) ? 1 : 0 ;
          }
        } else {
          return;
        }
      })
      .slice(paginationOffset, (paginationOffset + paginationSize));
  },[
    files,
    paginationOffset,
    paginationSize,
    filterString,
    sortColumn,
    sortKey,
    sortDirection
  ]);
    
  return (
    <div className="card">
      <div className="card-header text-light font-weight-bold container-fluid" style={{backgroundColor: '#828282'}}>
        <div className="row">
          <div className="col-12">My Files</div>
        </div>
      </div>
      <div>
        <div className="row m-0">
          <div className="col"></div>
          <div className="col-auto mt-3">
            { files && (
              <PhGridFilter
                filterString={filterString}
                setFilterString={setFilterString}
                setPaginationOffset={setPaginationOffset}
              />
            )}
          </div>
        </div>
        <table className="table table-striped container-fluid" style={{padding: 0, margin: 0}}>
          <thead>
            <tr>
              <th scope="col">
                <PhGridSort
                  columnLabel='File Name'
                  columnName={d => d?.description}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col">
                <PhGridSort
                  columnLabel='Job Id'
                  columnName={d => d?.id}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col">
                <PhGridSort
                  columnLabel='Size'
                  columnName={d => d.file_size ? parseInt(d.file_size, 10) : 0}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col">
                <PhGridSort
                  columnLabel='Created'
                  columnName={d => d?.created_date}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col">
                <PhGridSort
                  columnLabel='Expires'
                  columnName={d => d?.created_date}
                  config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                />
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            { !files && <tr><td colSpan="6" className="text-center"><Spinner/></td></tr> }
            {displayList && displayList.map(item => (
            <tr key={item.id}>
              <th scope="row">{item.description}</th>
              <td>{item.id}</td>
              <td>{item.file_size ? readableBytes(item.file_size) : 'unavailable'}</td>
              <td>{item.created_date.toLocaleDateString()}</td>
              <td>{calcDaysTilPurge(item.created_date)}</td>
              <td>
                <a
                  href={item.signed_url}
                  className={calcDaysTilPurge(item.created_date) === 'expired' ? 'btn btn-secondary disabled' : 'btn btn-primary'}
                  tabIndex={calcDaysTilPurge(item.created_date) === 'expired' ? -1 : ''}
                  aria-disabled={calcDaysTilPurge(item.created_date) === 'expired' ? 'true' : ''}
                  download
                >Download</a>
                {item.canBeVisualized && <Link
                  to={`/queryvisualization/${item.id}`}
                  className={'btn btn-success m-2'}
                >Visualize</Link>}
              </td>
            </tr>
            ))}
          </tbody>
        </table>
        { files && (
        <div className="row m-0 justify-content-end" style={{paddingRight: '.75rem'}}>
          <PhGridPagination
            fullList={files}
            paginationOffset={paginationOffset}
            setPaginationOffset={setPaginationOffset}
            setPaginationSize={setPaginationSize}
            paginationSize={paginationSize}
            filterString={filterString}
          />
        </div>
        )}
      </div>
    </div>
    );
}

export default MyFilesContainer;