import React from "react";
import PropTypes from "prop-types";
import classes from "./Header.module.scss";
import phLogo from "assets/img/PH_Logo.png";
import { Switch, Route } from "react-router-dom";

/**
 * Pure component that displays the Header component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const HeaderView = ({
  userName,
  signOut
}) => {

  return (
    <div className="header">
      <div>
        <a href="/">
          <img
            src={phLogo}
            alt="Perception Health Logo"
            className="float-left"
          />
        </a>
      </div>
      <div className="float-right">
        <span className={`pr-4 ${classes["user-name"]}`}>
          <strong>Hello {userName || 'unnamed'}</strong>
        </span>
         <Switch>
          <Route exact path="/">
            <button type="button" onClick={signOut} className="button-primary">
              Sign Out
            </button>
          </Route>
          <Route path={[
            "/UserAdmin/:userId",
            "/UserAccounts",
            "/AccountAdmin/:accountId",
            "/ContractAdmin/:contractId",
            "/UserBrands",
            "/BrandAdmin/:brandId"
          ]}>
            <button type="button" onClick={signOut} className="button-primary">
              Sign Out
            </button>
          </Route>
          <Route path="/openquery">
            <button type="button" onClick={signOut} className="button-open-query">
              Sign Out
            </button>
          </Route>
          <Route path="/myfiles">
            <button type="button" onClick={signOut} className="button-my-files">
              Sign Out
            </button>
          </Route>
        </Switch>
      </div>
    </div>
  );
};

HeaderView.propTypes = {
  userName: PropTypes.string.isRequired,
  signOut: PropTypes.func.isRequired
};

export default HeaderView;
