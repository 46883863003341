import React, { memo, useContext } from "react";
import PropTypes from "prop-types";
import AccountAdminContract from "./AccountAdminContract";
import classes from "./AccountAdminContracts.module.scss";
import { scopeParseObj } from "util/scope";
import Spinner, { InlineSpinner } from "components/Spinner";
import { AccountAdminContext } from "components/AccountAdmin/AccountAdmin-container";

const AccountAdminContractsView = ({ contracts, accountId, accountName, add, addingContract }) => {
  
  const { accountFetched } = useContext(AccountAdminContext);
  
  const listContracts = contracts ? contracts.map(contract => {
    
    const dates = [
      [contract.dates.start, 'no start date'],
      [contract.dates.end, 'no end date']
    ].map(arr => {
      if (arr[0]) {
        let newDate = new Date(arr[0]);
        return `${newDate.getMonth()+1}/${newDate.getDate()}/${newDate.getFullYear()}`;
      } else {
        return arr[1];
      }
    }).join(' - ');
    
    const products = contract.scopes.reduce((acc, scope) => {
      const parsedScope = scopeParseObj(scope);
      if (parsedScope.product && parsedScope.permit !== 'deny') {
        acc.push(parsedScope.product);
      }
      return acc;
    }, []).join(', ');
    
    return (
      <AccountAdminContract
        key={contract.id}
        name={contract.id}
        dates={dates}
        products={products}
        role={contract.role}
        contractId={contract.id}
        accountContractId={contract.accountContractId}
        accountId={accountId}
      />
    );
    
  }) : [];

  return (
    <div className={`${classes["flex-container"]}`}>
      <div className={`w-100 row ${classes["account-contracts-bar"]}`}>
        <div className="col-auto pt-2">
          <span className="white-text bar-text">Account Contracts</span>
        </div>
        <div className="col" />
        <div className={`col-auto pr-3 ${classes["add-contract-div"]}`}>
          { addingContract && <InlineSpinner/> }
          <button type="button" className={classes["add-contract-button"]} onClick={add}>
            Add Contract 
          </button>
        </div>
      </div>
      <div className={`w-100 row ${classes["col-headers-bar"]}`}>
        <div className="col-3">
          <span className="white-text">Dates</span>
        </div>
        <div className="col-3">
          <span className="white-text">Products</span>
        </div>
        <div className="col-6">
          
        </div>
      </div>
      <div className={`w-100 row ${classes.content}`}>
        { contracts.length > 0
          ? <div className="col pr-0">{listContracts}</div>
          : <div className="col p-3 text-center">{ accountFetched ? '' : <Spinner/> }</div>
        }
      </div>
    </div>
  );
};

AccountAdminContractsView.defaultProps = {
  contracts: []
};

AccountAdminContractsView.propTypes = {
  contracts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      accountContractId: PropTypes.string
    })
  ),
  add: PropTypes.func.isRequired
};

export default memo(AccountAdminContractsView);
