import { useContext, useState, useEffect } from "react";
import { ContractAdminContext} from "components/ContractAdmin/ContractAdmin-container";
import { scopeParseObj, scopeStringifyObj } from "util/scope";

// STEVE : jsdocs?

const useScopes = () => {
  const [ scopes, setScopes ] = useState([]);
  const { inputs, setInputs } = useContext(ContractAdminContext);
  useEffect(() => {
    if (inputs && inputs.scopes) {
      const thescopes = inputs.scopes.reduce((acc, scope) => {
          const parsedScope = scopeParseObj(scope);
          acc.push(parsedScope);
          return acc;
        }, []);
      setScopes(thescopes);
    }
  },[ inputs]);
  
  const handleCreateScope = () => {
    let newInputs = {...inputs};
    newInputs.scopes.push('prn:::*:*');
    setInputs(newInputs);
  };
  
  const handleRemoveScope = (scopeIdx) => {
    let newInputs = {...inputs};
    newInputs.scopes.splice(scopeIdx, 1);
    setInputs(newInputs);
  };
  
  const handleScopeChange = (event, scopeIdx, field) => {
    event.persist();
    let newInputs = {...inputs};
    let parsedScope = scopeParseObj(newInputs.scopes[scopeIdx]);
    if (field === 'product') {
      if (
        (['openquery','vocal','path','predict','npi'].indexOf(event.target.value) > -1) &&
        (['team','code'].indexOf(parsedScope.product) > -1)
      ) {// clear methods and path if prior product selection allowed it and new product selection does not
        parsedScope.methods = ['*'];
        parsedScope.path = '*';
      }
      parsedScope.product = event.target.value;
    } else if (field === 'methods') {
      let selectedMethods = Array.from(event.target, option => {
        if (option.selected) {
          return option.value;
        }
      }).filter(val => val);
      if (selectedMethods.indexOf('*') > -1) {//if ALL is one of the active remove all redundant other methods
        selectedMethods = selectedMethods.filter(method => method === '*');
      }
      parsedScope[field] = selectedMethods;
    } else {
      parsedScope[field] = event.target.value;
    }
    newInputs.scopes[scopeIdx] = scopeStringifyObj(parsedScope);
    setInputs(newInputs);
  }; 
  
  return {
    scopes,
    setScopes,
    handleCreateScope,
    handleRemoveScope,
    handleScopeChange
  };
};

export default useScopes;
