import React, { memo } from "react";
import PropTypes from "prop-types";
import AccountAdminContractsView from "./AccountAdminContracts-view";
import useAccountAdminContracts from "./AccountAdminContracts-logic";

/**
 * Control the creation of the AccountAdminContracts component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

const AccountAdminContractsContainer = ({ accountId, accountName, accountContracts }) => {
  const { addContract, addingContract } = useAccountAdminContracts(accountId);

  return <AccountAdminContractsView accountId={accountId} accountName={accountName} contracts={accountContracts} add={addContract} addingContract={addingContract} />;
};
AccountAdminContractsContainer.defaultProps = {
  accountId: ""
};

AccountAdminContractsContainer.propTypes = {
  accountId: PropTypes.string
};

export default memo(AccountAdminContractsContainer);
