import React, { useContext } from "react";
import SidebarView from "./Sidebar-view";
import { LoggedInUserContext } from "contexts/LoggedInUser";

/**
 * Control the creation of the Sidebar component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const SidebarContainer = () => {
  const { loggedInUserProfile } = useContext(LoggedInUserContext);
  return (
    <SidebarView
      loggedInUserProfile={loggedInUserProfile}
    />);
};

export default SidebarContainer;
