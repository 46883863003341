import React, { createContext, useState, useEffect, useContext } from "react";
import { ModalContext } from "contexts/Modal";
import { API, Auth } from "aws-amplify";

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [loggedInUserProfile, setLoggedInUserProfile] = useState({});
  const [shouldReloadLoggedInUserProfile, reloadLoggedInUserProfile] = useState(false);
  const { showModal, showConfirm } = useContext(ModalContext);
  
  useEffect(() => {
    async function fetchLoggedInUserProfile() {
      try {
        const accountData = await API.get('AccountGatewayApi', '/profile', { withCredentials: true });
        if (accountData?.accounts?.length === 0) {
          showModal('Account Error','Oops!, it looks like your user currently is not assigned to an active account.');
          setTimeout(()=>{
            Auth.signOut().then(() => {
              window.location.href = "/";
            });
          }, 10000);
        }
        setLoggedInUserProfile(accountData);
        reloadLoggedInUserProfile(false);
      } catch (err) {
        console.error("error fetching loggedInUserProfile", err);
        await Auth.signOut();
        window.location.href = "/";
      }
    }
    fetchLoggedInUserProfile();
  }, [shouldReloadLoggedInUserProfile, showModal]);
  
  const profileContext = {
    loggedInUserProfile,
    reloadLoggedInUserProfile
  };
   
  // pass the value in provider and return
  // conditionally render to deal with async nature of setting logged in user profile
  return <>
    { loggedInUserProfile && loggedInUserProfile.id && (
      <Context.Provider value={profileContext}>{children}</Context.Provider>
    )}
  </>;
    
};

export const { Consumer } = Context;