import OpenQuery from "./OpenQuery-container";
// import React, { Suspense } from "react";
// import Spinner from "components/Spinner";

// const OpenQueryContainer = React.lazy(() => import('./OpenQuery-container'));

// function OpenQueryLoad(props) {
//     return (
//         <Suspense fallback={<Spinner/>}>
//             <OpenQueryContainer />
//         </Suspense>
//     );
// }

export default OpenQuery;
