import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import ContractAccountView from "./ContractAccount-view";

const ContractAccountContainer = ({ account }) => {
  const history = useHistory();
  return <ContractAccountView account={account} history={history} />;
};

ContractAccountContainer.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string,
    accountContractId: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default ContractAccountContainer;
