export function scopeParseArr(scopeString) {
    const parsedScope = scopeString
        .split(':')
        .map((sub, idx) => {
            if (idx === 3) {// method
                if (sub[0] === '(') {// method contains grouping
                    return sub.slice(1, (sub.length -1) ).split('|');
                } else {// method is singular or wildcard
                    return sub;
                }
            } else if (idx === 4) {// path TODO: need path spec for products
                return sub;
            } else {
                return sub;
            }
        });
    return parsedScope;
}

export function scopeParseObj(scopeString) {
    const parsedScope = scopeString
        .split(':')
        .map((sub, idx) => {
            if (idx === 3) {// method
                if (sub[0] === '(') {// method contains grouping
                    return sub.slice(1, (sub.length -1) ).split('|');
                } else {// method is singular or wildcard
                    return [sub];
                }
            } else if (idx === 4) {// path TODO: need path spec for products
                return sub;
            } else {
                return sub;
            }
        });
    return {
        product: parsedScope[1],
        permit: parsedScope[2],
        methods: parsedScope[3],
        path: parsedScope[4]
    };
}

export function scopeStringify(scopeArray) {
    scopeArray.forEach((item, idx) => {
        if (idx === 3) {// method
            if (Array.isArray(item)) {
                let retString = '(';
                item.forEach((method, methodIdx, src) => {
                    if ( methodIdx === (src.length - 1) ) {// last method in array
                        retString = retString + method;
                    } else {
                        retString = retString + method + '|';
                    } 
                });
                retString = retString + ')';
                return retString;
            } else {
                return item;
            }
        } else if (idx === 4) {// path
            return item;//TODO: need path spec for products
        } else {
            return item;
        }
    });
    const stringifiedScope = ''.concat(...scopeArray);
    return stringifiedScope;
}

export function scopeStringifyObj(scopeObj) {
    let methodsStr = '';
    if (Array.isArray(scopeObj.methods) && scopeObj.methods.length > 1) {
        methodsStr = '(';
        scopeObj.methods.forEach((method, methodIdx, src) => {
            if ( methodIdx === (src.length - 1) ) {// last method in array
                methodsStr = methodsStr + method;
            } else {
                methodsStr = methodsStr + method + '|';
            }
        });
        methodsStr = methodsStr + ')';
    } else {
        methodsStr = scopeObj.methods[0];
    }
    //TODO: need path spec for products
    return `prn:${scopeObj.product}:${scopeObj.permit}:${methodsStr}:${scopeObj.path}`;
}