import React from "react";
import AccountView from "./Account-view";

/**
 * Control the creation of the User component
 *
 * @version 0.0.1
 * @author Josh Lloyd
 *
 */
const AccountContainer = ({
  inputs,
  setInputs,
  currentUserProfile,
  brands
}) => {
  return (
    <AccountView
      inputs={inputs}
      setInputs={setInputs}
      currentUserProfile={currentUserProfile}
      brands={brands}
    />
  );
};

export default AccountContainer;