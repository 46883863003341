import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import UserBrandView from "./UserBrand-view";

const UserBrandContainer = ({ brand }) => {
  const history = useHistory();
  return <UserBrandView brand={brand} history={history} />;
};

UserBrandContainer.propTypes = {
  brand: PropTypes.shape({
    brandName: PropTypes.string.isRequired,
    accountName: PropTypes.string.isRequired
  }).isRequired
};

export default UserBrandContainer;
