import PropTypes from "prop-types";
import contractPropTypes from "./contractPropTypes";
import profilePropTypes from "./profilePropTypes";

const accountPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.string),
  contracts: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        contract: contractPropTypes
      })
    )
  }),
  profiles: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        profile: profilePropTypes
      })
    )
  })
});

export default accountPropTypes;
