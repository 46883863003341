import React, { memo } from "react";
import UserAdminUser from "./UserAdminUser";
import UserAdminAccounts from "./UserAdminAccounts";

/**
 * Pure component that displays the UserAdmin component.
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const UserAdminView = () => {
  return (
    <div>
      <UserAdminUser/>
      <UserAdminAccounts/>
    </div>
  );
};

export default memo(UserAdminView);
