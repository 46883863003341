import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import useProfileId from "hooks/useProfile";
import { logError } from "util/logging";

/**
 * Contains the logic for UserAccounts
 *
 * @version 0.0.1
 * @author Josh Lloyd
 *
 */

const useUserAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const { profileId } = useProfileId();

  useEffect(() => {
    async function fetchData() {
      
      try {
        let userAccountsRequest = await API.get('AccountGatewayApi', '/accounts', { withCredentials: true });

        const accountsToSet = userAccountsRequest.reduce((acc, account) => {
        
          let modifiedProfile = {
            accountId: account.id,
            name: account.name,
            brand: '',
            role: '',
            accountProfileId: ''
          };
          
          acc.push(modifiedProfile);
          
          return acc;
          
        },[]);
        
        setAccounts(accountsToSet);
        
      } catch (err) {
        console.error('userAccountsRequest error', err);
      }
      
    }

    try {
      if (profileId) fetchData();
    } catch (err) {
      logError("useAccountsReducer Error", err);
    }
  }, [profileId]);

  return {
    accounts
  };
};

export default useUserAccounts;
