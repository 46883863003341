import User from "./User-container";

/**
 * Export the top level component User-container
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export default User;
