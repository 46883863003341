import React, { useState, useEffect, useMemo } from "react";
import { API } from "aws-amplify";
import { toast } from 'react-toastify';

import { SpinnerSmall } from "components/Spinner";
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import sortBy from 'lodash/sortBy';

import { PhGridPagination, PhGridFilter, usePagination, PhGridSort } from 'components/PhGrid';

import dompurify from 'dompurify';

import 'react-toastify/dist/ReactToastify.css';

function validateName(str) {
  const rg1=/^[0-9A-Za-z_.-\s]+$/; // alphanumeric, hyphens, underscores, periods and spaces
  return  rg1.test(str) && str.length < 50;
}

function toMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', {
    month: 'long',
  });
}

//converts YYYY-MM-DD to Month Day, YYYY
function translateDateFormat(dateStr) {// converts

  const monthDate = new Date();
  monthDate.setMonth(Number(dateStr.slice(5,7)) - 1);
  
  const monthStr = monthDate.toLocaleString('en-US', {
    month: 'long',
  });

  return `${monthStr} ${Number(dateStr.slice(8,10))}, ${dateStr.slice(0,4)}`;
  
}

function MonitoringServicePage(props) {

    const {
      paginationOffset, setPaginationOffset,
      paginationSize, setPaginationSize,
      filterString, setFilterString,
      sortColumn, setSortColumn,
      sortKey, setSortKey,
      sortDirection, setSortDirection
    } = usePagination();
    
    const [ profile, setProfile ] = useState(null);

    const [ monitors, setMonitors ] = useState(null);
    const [ availableCommunities, setAvailableCommunities ] = useState(null);
    const [ availableWatchers, setAvailableWatchers ] = useState(null);
    
    const [ modalActive, setModalActive ] = useState(false);
    const [ previewModalActive, setPreviewModalActive ] = useState(false);
    
    const [ modalMonitor, setModalMonitor ] = useState(null);
    const [ modalTarget, setModalTarget ] = useState(null);
    const [ modalWatchers, setModalWatchers ] = useState(null);
    const [ modalFrequency, setModalFrequency ] = useState(null);
    const [ modalMonitoringServiceName, setModalMonitoringServiceName ] = useState(null);
    const [ pageState, setPageState ] = useState('Loading');

    const [ previewModalReport, setPreviewModalReport ] = useState(null);
    
    async function init() {
      try {
      
        const initialRequests = await Promise.allSettled([
          API.get('AccountGatewayApi', '/profile', { withCredentials: false }),
          API.get('CommunityApi',`/communities`, { withCredentials: false }),
          API.get('MonitoringServiceApi',`/monitors`, { withCredentials: false })
        ]);
        
        const accountsDetails = await Promise.allSettled(initialRequests[0].value.accounts.map(account => {
          return API.get('AccountGatewayApi', `/account/${account.id}`, { withCredentials: false });
        }));
        
        const accountsUsers = sortBy(accountsDetails.map(account => {
          return account.value.profiles;
        }).flat(), [obj => (obj?.name?.toUpperCase() || obj?.email?.toUpperCase())]);
        
        const communitiesResponse = sortBy(initialRequests[1].value, [obj => obj.name.toUpperCase()]);//sort ascending by name
        
        const monitorApiResponse = sortBy(initialRequests[2].value.data.reduce((acc, monitor) => {
          monitor.watchers = monitor.subscribers;
          acc.push(monitor);
          return acc;
        }, []), [obj => obj.name.toLocaleUpperCase()]);
        
        const communitiesLookup = communitiesResponse.reduce((acc, val) => {
          acc[val.id] = val;
          return acc;
        }, {});
        
        const monitorList = monitorApiResponse.map(monitor => {
          monitor.monitoringcommunity = communitiesLookup[monitor.monitoringcommunity];
          monitor.targetcommunity = communitiesLookup[monitor.targetcommunity];
          return monitor;
        });
        
        setProfile(initialRequests[0].value);
        
        setAvailableCommunities(communitiesResponse);
        setAvailableWatchers(accountsUsers);
        
        setMonitors(monitorList);
        setPageState(null);

      } catch (err) {
        setPageState(null);
        toast(`There was a problem loading your aware reports`, { type: toast.TYPE.ERROR, autoClose: 3000 });
      }
    }
    
    useEffect(() => {
      init();
    }, []);
    
    const displayList = useMemo(() => {
      if (!monitors) return [];
      
      return monitors
        .filter(monitor => [
          monitor.name || '',
          monitor.id,
          monitor.monitoringcommunity?.name || '',
          monitor.targetcommunity?.name || '',
          monitor.watchers.map(id => {
            const matchedwatcher = availableWatchers.filter(availableWatcher => {
              return availableWatcher.id === id;
            })[0];
            return matchedwatcher ? `${matchedwatcher.name} (${matchedwatcher.email})` : '';
          }).join(', '),
          monitor.frequency,
          monitor.isactive ? 'Active' : 'Inactive',
          translateDateFormat(monitor.modified),
        ].join('').toLowerCase().includes(filterString.toLowerCase()))
        .sort((a,b) => {
          if (sortColumn) {
            if (sortDirection === 'ascending') {
              return sortKey(a) > sortKey(b) ? 1 : sortKey(a) < sortKey(b) ? -1 : 0 ;
            } else {
              return sortKey(a) > sortKey(b) ? -1 : sortKey(a) < sortKey(b) ? 1 : 0 ;
            }
          } else {
            return;
          }
        })
        .slice(paginationOffset, (paginationOffset + paginationSize));
    },[
      monitors,
      paginationOffset,
      paginationSize,
      filterString,
      sortColumn,
      sortKey,
      sortDirection,
      availableWatchers
    ]);

    return(<>
      <div className="card">
        <div className="card-header text-light font-weight-bold container-fluid" style={{backgroundColor: '#439945'}}>
          <div className="row">
            <div className="col-12">My Aware Reports</div>
          </div>
        </div>
        <div className="card-body table-responsive">
          <div className="row">
            { monitors && (
              <div className="col-6 pt-2 pl-4">
                <PhGridFilter
                  filterString={filterString}
                  setFilterString={setFilterString}
                  setPaginationOffset={setPaginationOffset}
                />
              </div>
            )}
            <div className="col-6">
              {pageState && <div className="d-inline-block m-3"><b>{ pageState } </b><SpinnerSmall/></div>}
              <button className="btn btn-primary m-2 float-right" onClick={ () => setModalActive(true) }>New Aware Report</button>
            </div>
          </div>
          <table className="table table-striped table-sm" style={{padding: 0}}>
            <thead>
              <tr>
                <th scope="col">
                  <PhGridSort
                    columnLabel='Name'
                    columnName={d => d?.name}
                    config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                  />
                </th>
                <th scope="col">
                  <PhGridSort
                    columnLabel='Physician Community'
                    columnName={d => d?.monitoringcommunity?.name}
                    config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                  />
                </th>
                <th scope="col">
                  <PhGridSort
                    columnLabel='Facility Community'
                    columnName={d => d?.targetcommunity?.name}
                    config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                  />
                </th>
                <th scope="col">
                  <PhGridSort
                    columnLabel='Watchers'
                    columnName={d => d?.dateCreated}
                    config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                  />
                </th>
                <th scope="col">
                  <PhGridSort
                    columnLabel='Frequency'
                    columnName={d => d?.frequency}
                    config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                  />
                </th>
                <th scope="col">
                  <PhGridSort
                    columnLabel='Status'
                    columnName={d => d?.isactive}
                    config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                  />
                </th>
                <th scope="col">
                  <PhGridSort
                    columnLabel='Last Modified'
                    columnName={d => d?.modified}
                    config={{sortKey, setSortKey, sortDirection, setSortDirection, sortColumn, setSortColumn}}
                  />
                </th>
                <th scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              { !monitors && <tr><td colSpan="7" className="text-center"><SpinnerSmall/></td></tr> }
              { displayList && displayList.map(item => (
                <MonitorRow
                  {...item}
                  key={item.id}
                  monitor={item}
                  availableCommunities={availableCommunities}
                  availableWatchers={availableWatchers}
                  init={init}
                  setPageState={setPageState}
                  setPreviewModalActive={setPreviewModalActive}
                  setPreviewModalReport={setPreviewModalReport}
                />
              ))}
            </tbody>
          </table>
          { monitors && (
          <div className="row m-0 justify-content-end" style={{paddingRight: '.75rem'}}>
            <PhGridPagination
              fullList={monitors}
              paginationOffset={paginationOffset}
              setPaginationOffset={setPaginationOffset}
              setPaginationSize={setPaginationSize}
              paginationSize={paginationSize}
              filterString={filterString}
            />
          </div>
          )}
        </div>
      </div>
      <Modal
        size="lg"
        show={modalActive}
        onHide={()=>{
          setModalActive(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Aware Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MonitorForm
            availableCommunities={availableCommunities}
            availableWatchers={availableWatchers}
            modalMonitor={modalMonitor}
            setModalMonitor={setModalMonitor}
            modalTarget={modalTarget}
            setModalTarget={setModalTarget}
            modalWatchers={modalWatchers}
            setModalWatchers={setModalWatchers}
            modalFrequency={modalFrequency}
            setModalFrequency={setModalFrequency}
            modalMonitoringServiceName={modalMonitoringServiceName}
            setModalMonitoringServiceName={setModalMonitoringServiceName}
            setFormContainerOpen={setModalActive}
            init={init}
            setPageState={setPageState}
          />
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={previewModalActive}
        onHide={()=>{
          setPreviewModalActive(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Clicking the preview button will send <strong>{previewModalReport?.name && previewModalReport.name}</strong> to the email tied to your account <strong>{profile && profile.email}</strong>.</p>
        </Modal.Body>
        <Modal.Footer>
          { previewModalActive === 'rejected' && <p className="text-danger">There was an error requesting the preview</p>}
          { previewModalActive !== 'rejected' && <button
            type="button"
            className={`btn btn-primary font-weight-bold`}
            onClick={() => {
              setPreviewModalActive('requesting');
              API.post('MonitoringServiceApi',`/ondemand`, {
                withCredentials: false,
                body: {
                  ids: [previewModalReport.id]
                }
              })
              .then(success => {
                setPreviewModalActive(false);
              }, failure => {
                console.log('failure', failure);
                setPreviewModalActive('rejected');
              });
            }}
          >
            {`Send me a preview`}{previewModalActive === 'requesting' ? <SpinnerSmall color="light"/> : ''}
          </button>}
          <button type="button" className={`btn btn-secondary font-weight-bold`} onClick={() => {
            setPreviewModalActive(false);
          }}>
            {previewModalActive === 'rejected' ? 'Close': 'Cancel'}
          </button>
        </Modal.Footer>
      </Modal>
    </>);
        
}

function MonitorRow(props) {
  
  const [expanded, setExpanded ] = useState(false);
  return (<React.Fragment key={props.id}>
    <tr key={props.id}>
      <th scope="row">{props?.name || <span className="text-danger">Name not found</span>}</th>
      <td>{props.monitoringcommunity?.name || <span className="text-danger">Community not found</span>}</td>
      <td>{props.targetcommunity?.name || <span className="text-danger">Community not found</span>}</td>
      <td>
        {props.watchers
        .map(watcher => {
          const ret = props.availableWatchers.filter(availableWatcher => {
            return availableWatcher.id === watcher;
          })[0];
          if (ret) {
            return ret;
          } else {
            return {altId: watcher};
          }
        })
        .map((subscriber, idx, arr) => {
          if (subscriber?.id) {
            return <React.Fragment key={subscriber.id}>
              <span>{subscriber.name || ''}{' '}({subscriber.email})</span>
              {(arr.length > 1 && idx !== (arr.length - 1)) ? <span>, </span>: null}
            </React.Fragment>
          } else {
            return <React.Fragment key={subscriber.altId}>
              <span className="text-danger">Subscriber not found</span>
              {(arr.length > 1 && idx !== (arr.length - 1)) ? <span>, </span>: null}
            </React.Fragment>;
          }
        })
        }
      </td>
      <td>{props.frequency || <span className="text-danger">Frequency not found</span>}</td>
      <td>{props.isactive
        ? <span className="badge badge-pill badge-success">Active</span>
        : <span className="badge badge-pill badge-warning">Inactive</span>
      }</td>
      <td>{(props.modified && translateDateFormat(props.modified)) ? translateDateFormat(props.modified) : <span className="text-danger">Date not found</span> }
      </td>
      <td>
        <div style={{whiteSpace: 'nowrap'}}>
          <button type="button" title="Edit" className={`btn btn-${expanded ? 'secondary' : 'primary'} font-weight-bold m-1 px-2 py-1`} onClick={() => setExpanded(!expanded)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 117.74 122.88" width="15" height="15" fill="#FFF">
              <path 
                d="M94.62,2c-1.46-1.36-3.14-2.09-5.02-1.99c-1.88,0-3.56,0.73-4.92,2.2L73.59,13.72l31.07,30.03l11.19-11.72 c1.36-1.36,1.88-3.14,1.88-5.02s-0.73-3.66-2.09-4.92L94.62,2L94.62,2L94.62,2z M41.44,109.58c-4.08,1.36-8.26,2.62-12.35,3.98 c-4.08,1.36-8.16,2.72-12.35,4.08c-9.73,3.14-15.07,4.92-16.22,5.23c-1.15,0.31-0.42-4.18,1.99-13.6l7.74-29.61l0.64-0.66 l30.56,30.56L41.44,109.58L41.44,109.58L41.44,109.58z M22.2,67.25l42.99-44.82l31.07,29.92L52.75,97.8L22.2,67.25L22.2,67.25z"
              />
            </svg>
          </button>
          <button type="button" title="Preview" className={`btn btn-success font-weight-bold m-1 px-2 py-1`}
            disabled={(!props.monitoringcommunity?.name || !props.targetcommunity?.name)}
            onClick={() => {
              props.setPreviewModalReport(props.monitor);
              props.setPreviewModalActive(true);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
              <path
                d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"
              />
            </svg>
          </button>
          </div>
      </td>
    </tr>
    { expanded && <tr><td colSpan="8"><div className="card container">
      <MonitorForm
        monitor={props.monitor}
        availableCommunities={props.availableCommunities}
        availableWatchers={props.availableWatchers}
        setModalMonitor={props.setModalMonitor}
        setModalTarget={props.setModalTarget}
        setModalWatchers={props.setModalWatchers}
        setModalMonitoringServiceName={props.setModalMonitoringServiceName}
        setFormContainerOpen={setExpanded}
        init={props.init}
        setPageState={props.setPageState}
      />
    </div></td></tr>}

  </React.Fragment>);
}

function MonitorForm({
  monitor,
  availableCommunities,
  availableWatchers,
  modalMonitor,
  setModalMonitor,
  modalTarget,
  setModalTarget,
  modalWatchers,
  setModalWatchers,
  modalFrequency,
  setModalFrequency,
  modalMonitoringServiceName,
  setModalMonitoringServiceName,
  setFormContainerOpen,
  init,
  setPageState
}) {
  
  const [ showInfo, setShowInfo ] = useState(false);
  const [ selectedMonitoringCommunity, setSelectedMonitoringCommunity ] = useState(null);
  const [ selectedTargetCommunity, setSelectedTargetCommunity ] = useState(null);
  const [ selectedWatchers, setSelectedWatchers ] = useState([]);
  const [ selectedFrequency, setSelectedFrequency ] = useState(null);
  const [ selectedStatus, setSelectedStatus ] = useState(null);
  const [ monitorName, setMonitorName ] = useState('');
  
  const payloadObj = useMemo(() => {
    const bodyObj = {};
    
    if (monitor) {//editing
      if (monitor.monitoringcommunity !== selectedMonitoringCommunity) {
        bodyObj.monitoringcommunity = selectedMonitoringCommunity?.id;
      }
      
      if (monitor.targetcommunity !== selectedTargetCommunity) {
        bodyObj.targetcommunity = selectedTargetCommunity?.id;
      }
      
      const monitorSubscribersSet = new Set(monitor.subscribers);
      const selectedSubscribersSet = new Set(selectedWatchers);
      
      if (monitorSubscribersSet.size !== selectedSubscribersSet.size || ![...monitorSubscribersSet].every((x) => selectedSubscribersSet.has(x))) {// has watchers changes
        if (selectedWatchers && selectedWatchers.filter(watcher => watcher).length > 0) {
          bodyObj.subscribers = selectedWatchers;
        }
      }
      
      if (monitor.frequency !== selectedFrequency) {
        bodyObj.frequency = selectedFrequency;
      }
      
      if (monitor.isactive !== selectedStatus) {
        bodyObj.isactive = selectedStatus;
      }
      
      if (monitor.name !== monitorName) {
        bodyObj.name = monitorName;
      }
      
      if (
        Object.keys(bodyObj).length > 0 &&
        Object.values(bodyObj).filter(val => val === '').length === 0
      ) {
        return bodyObj;
      } else {
        return null;
      }
      
    } else {//new
      bodyObj.monitoringcommunity = selectedMonitoringCommunity?.id;
      bodyObj.targetcommunity = selectedTargetCommunity?.id;
      bodyObj.subscribers = selectedWatchers;
      bodyObj.frequency = selectedFrequency;
      bodyObj.name = monitorName;

      if (
        Object.keys(bodyObj).length === 5 &&
        bodyObj.monitoringcommunity &&
        bodyObj.targetcommunity &&
        (Array.isArray(bodyObj.subscribers) && bodyObj.subscribers.filter(watcher => watcher).length > 0) &&
        (bodyObj.frequency === 'Quarterly' || bodyObj.frequency === 'Monthly') &&
        (bodyObj.name && validateName(bodyObj.name))
      ) {
        return bodyObj;
      } else {
        return null;
      }
    }
    
  }, [
    monitor,
    selectedMonitoringCommunity,
    selectedTargetCommunity,
    selectedWatchers,
    selectedFrequency,
    selectedStatus,
    monitorName,
  ]);
  
  /**
   * 
   * @param {*} communities 
   * @param {number} entityType 
   * @returns 
   */
  function filterCommunitiesByEntityType(communities, entityType) {
    // If looking for Hybrid communities, just return all communities.
    if (entityType == '3') {
      return communities;
    }

    return communities.filter(comm => {
      return comm.entitytype == entityType;
    });
  }
  
  useEffect(() => {
    if (monitor) {
      setSelectedMonitoringCommunity(monitor.monitoringcommunity);
      setSelectedTargetCommunity(monitor.targetcommunity);
      setSelectedWatchers(monitor.subscribers);
      setSelectedFrequency(monitor.frequency);
      setSelectedStatus(monitor.isactive);
      setMonitorName(monitor.name);
    }
  }, [monitor]);
  
  return(<>
  <div className="row">
    <div className="col-3">
      <button className="btn btn-outline-success btn-sm font-weight-bold mr-1 mt-2" onClick={e => setShowInfo(!showInfo)}>{showInfo ? 'Hide ' : 'Show '} Additional Info</button>
    </div>
    <div className="col-9">
      { showInfo ? <p className="mt-1"> An Aware Report allows a user to identify a community of physicians from the TEAM platform and a community of facilities to measure their market share and volume to month over month. Once set - the user can identify watchers from their account and a monthly or quarterly cadence for the watchers to receive an interactive dashboard in their email with the freshest data.</p> : null }
    </div>
  </div>
  <div className="row">
    <div className="col-12">
      <div className="form-group row p-3 mb-0">
        <OverlayTrigger
          key="modalmonitoringcommunity"
          placement="left"
          overlay={<Tooltip>Select Community of Physicians to monitor</Tooltip>}
        >
          <label htmlFor="" className="col-3 col-form-label p-0">Physician Community:</label>
        </OverlayTrigger>
        <select className="form-control col-9"
          disabled={monitor}
          value={selectedMonitoringCommunity?.id || ''}
          onChange={e => {
            const newMonitoringCommunity = availableCommunities.find(com => com.id === e.target.value);
            setSelectedMonitoringCommunity(newMonitoringCommunity);
          }}
        >
          <option value="">-- Select Physician Community --</option>
          { availableCommunities && filterCommunitiesByEntityType(availableCommunities, '1').map(community => {
            return <option key={community.id} value={community.id}>{community.name}</option>;
          })}
        </select>
      </div>
      {selectedMonitoringCommunity && (<div className="row ml-0">
        <p className="offset-3"><a className="btn btn-sm btn-outline-success" href={`https://team.perceptionhealth.${process.env.REACT_APP_TARGET === 'prod' ? 'com' : 'dev'}/community/${dompurify.sanitize(selectedMonitoringCommunity.id)}/home`} target="_blank" rel="noopener noreferrer">View/Edit {selectedMonitoringCommunity.name} in TEAM</a> ({selectedMonitoringCommunity.members.length} members)</p>
      </div>)}
      
      <div className="form-group row p-3 mb-0">
        <OverlayTrigger
          key="modaltargetcommunity"
          placement="left"
          overlay={<Tooltip>Select target community of Organizations to monitor the physician's metrics</Tooltip>}
        >
          <label htmlFor="" className="col-3 col-form-label p-0">Facility Community:</label>
        </OverlayTrigger>
        <select className="form-control col-9"
          disabled={monitor}
          value={selectedTargetCommunity?.id || ''}
          onChange={e => {
            const newTargetCommunity = availableCommunities.find(com => com.id === e.target.value);
            setSelectedTargetCommunity(newTargetCommunity);
          }}
        >
          <option value="">-- Select Facility Community --</option>
          { availableCommunities && filterCommunitiesByEntityType(availableCommunities, '2').map(community => {
            return <option key={community.id} value={community.id}>{community.name}</option>;
          })}
        </select>
      </div>
      {selectedTargetCommunity && (<div className="row ml-0">
        <p className="offset-3"><a className="btn btn-sm btn-outline-success" href={`https://team.perceptionhealth.${process.env.REACT_APP_TARGET === 'prod' ? 'com' : 'dev'}/community/${dompurify.sanitize(selectedTargetCommunity.id)}/home`} target="_blank" rel="noopener noreferrer">View/Edit {selectedTargetCommunity.name} in TEAM</a> ({selectedTargetCommunity.members.length} members)</p>
      </div>)}
      
      <div className="form-group row p-3 mb-0">
        <OverlayTrigger
          key="modaltargetcommunity"
          placement="left"
          overlay={<Tooltip>Select watchers within your accounts's users to send the monitoring services reports via email</Tooltip>}
        >
          <label htmlFor="" className="col-3 col-form-label p-0">Watchers:<br />ctrl + click to select multiple watchers</label>
        </OverlayTrigger>
        <select className="form-control col-9"
          value={selectedWatchers || ''}
          onChange={e => {
            const options = e.target.options;
            const values = [];
            for (var i = 0, l = options.length; i < l; i++) {
              if (options[i].selected) {
                values.push(options[i].value);
              }
            }

            setSelectedWatchers(values);
          }}
          multiple={true}
        >
          <option value="">-- Select Watchers --</option>
          { availableWatchers && availableWatchers.map(watcher => {
            return <option key={watcher.id} value={watcher.id}>{watcher.name} ({watcher.email})</option>;
          })}
        </select>
      </div>
      {monitor && (<div className="row ml-0">
        <p className="offset-4">Subscribers: {monitor.watchers.length}</p>
      </div>)}
      
      <div className="form-group row p-3 mb-0">
        <OverlayTrigger
          key="modaltargetcommunity"
          placement="left"
          overlay={<Tooltip>Select frequency to recieve notification</Tooltip>}
        >
          <label htmlFor="" className="col-3 col-form-label p-0">Frequency:</label>
        </OverlayTrigger>
        <select className="form-control col-9"
          value={selectedFrequency || ''}
          onChange={e => {
            setSelectedFrequency(e.target.value);
          }}
        >
          <option value="">-- Select Frequency --</option>
          <option value="Quarterly">Quarterly</option>
          <option value="Monthly">Monthly</option>
        </select>
      </div>
      
      { monitor && <div className="form-group row p-3 mb-0">
        <OverlayTrigger
          key="modaltargetcommunity"
          placement="left"
          overlay={<Tooltip>Set Monitor status as active or inactive</Tooltip>}
        >
          <label htmlFor="" className="col-3 col-form-label p-0">Status:</label>
        </OverlayTrigger>
        <select className="form-control col-9"
          value={selectedStatus ? 'true' : 'false'}
          onChange={e => {
            if (e.target.value === 'true') {
              setSelectedStatus(true);
            } else if (e.target.value === 'false') {
              setSelectedStatus(false);
            }
          }}
        >
          <option value="">-- Select Status --</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      </div>}
      
      <div className="form-group row p-3 mb-0">
        <OverlayTrigger
          key="modaltargetcommunity"
          placement="left"
          overlay={<Tooltip>Name the Aware report</Tooltip>}
        >
          <label htmlFor="" className="col-3 col-form-label p-0">Name:</label>
        </OverlayTrigger>
        <input
          type="text"
          className={`form-control col-9 ${monitorName && !validateName(monitorName) ? 'is-invalid' : ''}`}
          value={monitorName}
          onChange={e => {
            setMonitorName(e.target.value);
          }}
          placeholder="name the Aware report"
        />
        { (monitorName && !validateName(monitorName)) && <div className="offset-3 invalid-feedback">
          Must be under 50 characters long and may only include alphanumeric characters, hyphens, underscores, periods and spaces.
        </div>}
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col text-right">
      <button className="btn btn-primary m-1" disabled={!payloadObj} onClick={()=>{
        setPageState('Saving...');
        if (monitor) {// checking if editing a monitor or creating a new one
          API.patch('MonitoringServiceApi', `/monitor/${monitor.id}`, {
            withCredentials: false,
            body: payloadObj
          })
          .then(success => {
            if (setFormContainerOpen) {
              init();
              setFormContainerOpen(false);
            }
          }, failure => {
            console.log('failure', failure);
          });
        } else {
          API.post('MonitoringServiceApi',`/monitor`, {
            withCredentials: false,
            body: payloadObj
          })
          .then(success => {
            if (setFormContainerOpen) {
              init();
              setFormContainerOpen(false);
            }
          }, failure => {
            console.log('failure', failure);
          });
        }
        
        
      }}>
        {monitor ? 'Save Edits' : 'Save New'}
      </button>
      { monitor && <button className="btn btn-danger m-1" onClick={()=>{
        setPageState('Deleting...');
        API.del('MonitoringServiceApi',`/monitor/${monitor.id}`, {
          withCredentials: false
        })
        .then(success => {
          init();
          setFormContainerOpen(false);
        }, failure => {
          console.log('failure', failure);
        });
      }}>
        Delete
      </button>}
      <button className="btn btn-secondary m-1" onClick={()=>{
        if (setFormContainerOpen) {
          setFormContainerOpen(false);
        }
        
      }}>
        Cancel
      </button>
    </div>
  </div>
  </>);
}

// function SendPreviewModal(props) {

// }

export default MonitoringServicePage;