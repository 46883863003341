import { useReducer } from "react";
import { logError } from "util/logging";

//TODO: check if this reducer hook is no longer needed due to the re-implemented routing

const reducer = (state, action) => {
  try {
    switch (action.type) {
    case "SET_HISTORY":
      return action.history;
    default:
      return state;
    }
  } catch (err) {
    logError(`useHistoryReducer action.type = ${action.type}`, err);
  }

  return state;
};

const useAccountsReducer = () => {
  const [state, dispatch] = useReducer(reducer, null);

  return {
    state,
    dispatch
  };
};

export default useAccountsReducer;
