import React from "react";
import UserAccountsView from "./UserAccounts-view";
import useUserAccounts from "./UserAccounts-logic";

/**
 * Control the creation of the UserAccounts component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
 
export const UserAccountsContext = React.createContext({});

const UserAccountsContainer = () => {
  const { accounts } = useUserAccounts();

  return <UserAccountsView accounts={accounts} />;
};

export default UserAccountsContainer;
