import React from "react";
import UserAdminUserView from "./UserAdminUser-view";
import useUserAdminUser from "./UserAdminUser-logic";

/**
 * Control the creation of the UserAdminUser component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const UserAdminUserContainer = () => {
  const { inputs, setInputs, handleSave, handleDeactivate } = useUserAdminUser();
  
  return (
    <UserAdminUserView
      inputs={inputs}
      setInputs={setInputs}
      handleSave={handleSave}
      handleDeactivate={handleDeactivate}
    />
  );
};

export default UserAdminUserContainer;
