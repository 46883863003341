import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import UserAccountView from "./UserAccount-view";

const UserAccountContainer = ({ account }) => {
  const history = useHistory();
  return <UserAccountView account={account} history={history} />;
};

UserAccountContainer.propTypes = {
  account: PropTypes.shape({
    name: PropTypes.string.isRequired,
    brand: PropTypes.string,
    role: PropTypes.string
  }).isRequired
};

export default UserAccountContainer;
