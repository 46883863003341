import React, { useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { consoleClear } from "util/logging";
import { ModalContext } from "contexts/Modal";
import classes from "./MsgModal.module.scss";

const MsgModal = ({ title, msg, show, onHide, isConfirm, confirmCallback }) => {
  /* The react-bootstrap Modal uses some deprecated functions.
     When the Modal is rendered, it works, but throws some
     warnings in the console. 
     
     The warnings only get written to the console the
     first time the Modal is rendered.
     
     Until the Modal is fixed, the following code 
     will clear the console after only the first 
     time the Modal is rendered.
  */
  const { hasModalShown, setHasModalShown } = useContext(ModalContext);

  useEffect(() => {
    if (show && !hasModalShown) {
      consoleClear();
      setHasModalShown(true);
    }
  }, [show, hasModalShown, setHasModalShown]);
  /* End of the Modal warnings code  */

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header className={`${classes["header-background"]}`} closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
        <Modal.Footer>
          {!isConfirm && (
            <Button
              className={`${classes["modal-button"]}`}
              variant="secondary"
              onClick={onHide}
            >
              Close
            </Button>
          )}
          {isConfirm && (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  onHide();
                  confirmCallback();
                }}
                className={`${classes["modal-button"]}`}
              >
                Yes
              </Button>
              <Button
                variant="secondary"
                onClick={onHide}
                className={`${classes["modal-button-no"]}`}
              >
                No
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

MsgModal.defaultProps = {
  confirmCallback: () => {}
};

MsgModal.propTypes = {
  title: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  isConfirm: PropTypes.bool.isRequired,
  confirmCallback: PropTypes.func
};

export default MsgModal;
