import { useContext } from "react";
import { API } from "aws-amplify";
import { logError, logInfo } from "util/logging";
import { ModalContext } from "contexts/Modal";
import { strNonEmpty } from "util/strings";

/* eslint-disable no-use-before-define */

const useEditProfile = accountId => {
  const { showModal, showConfirm } = useContext(ModalContext);

  const handleSaveProfile = async (
    event,
    accountId,
    initInputs,
    inputs,
    setModalOpen,
    setListUsers,
    profileId = "",
    updateAccount
  ) => {
    if (event) {
      event.preventDefault();
    }
    
    try {
      // Validate the inputs
      if (!validateInputs(profileId, inputs, showModal)) return;

      if (!strNonEmpty(profileId)) {
        createUser(
          accountId,
          inputs,
          showModal,
          setListUsers,
          updateAccount,
          setModalOpen
        );
      } else {
        updateUser(
          profileId,
          initInputs,
          inputs,
          showModal,
          setListUsers,
          accountId,
          updateAccount,
          setModalOpen
        );
      }
      // setModalOpen(false);
    } catch (err) {
      logError("useProfile.js: Error occurred while updating profile (2)", err);
      showModal("Save User", "Error occurred while saving user. (7)");
    }
  };

  const handleDeactivateProfile = async (accountId, profileId, updateAccount) => {

    try {
      showConfirm(
        "Deactivate User",
        "Do you really want to deactivate the user?",
        () => {
          deactivateUser(accountId, profileId, showModal, updateAccount);
        }
      );
    } catch (err) {
      logError(
        "useProfile.js handleDeactivateProfile error: Unknown error",
        err
      );
    }
  };

  return {
    handleSaveProfile,
    handleDeactivateProfile
  };
};

// Validate that the inputs on the form are valid.
const validateInputs = (profileId, inputs, showModal) => {
  
  const nameNonEmpty = strNonEmpty(inputs.userName);
  const emailNonEmpty = strNonEmpty(inputs.userEmail);
  const roleNonEmpty = strNonEmpty(inputs.userRole);
  if (profileId ) {// update user role
    if (!roleNonEmpty) {
      showModal(
        "Save User",
        'Must select role.'
      );
      return false;
    } else {
      return true;
    }
  } else {// new user
    if (!nameNonEmpty || !emailNonEmpty || !roleNonEmpty) {
      showModal(
        "Save User",
        `${nameNonEmpty ? '' : 'Must include name. '}${emailNonEmpty ? '' : 'Must include email address. ' }${roleNonEmpty ? '' : 'Must select role. '}`
      );
      return false;
    } else {
      return true;
    }
  }
};

// Create a user.  Users must be added to an account at creation
// eslint-disable-next-line no-unused-vars
const createUser = async (accountId, inputs, showModal, setListUsers, updateAccount, setModalOpen) => {
  try {

    const userToCreate = {
      name: inputs.userName,
      email: inputs.userEmail,
      role: inputs.userRole
    };

    await API.post('AccountGatewayApi', `/account/${accountId}/profile`, {
      body: userToCreate,
      withCredentials: true
    });
    setModalOpen(false);
    showModal(
      "Create User",
      "User successfully created"
    );
    updateAccount(true);
    
  } catch (err) {
    logError("createUser error", err);
    showModal("Add User", "An unknown error occurred. (3)");
  }
};

// Update the profile record with the inputs from the form.
const updateProfile = async (profileId, initInputs, inputs, showModal, accountId) => {
  try {
    // Compare the initInputs to the currInputs(inputs).
    // If there are no changes, then return.
    if (
      initInputs.userName === inputs.userName &&
      initInputs.userEmail === inputs.userEmail &&
      initInputs.userPhone === inputs.userPhone &&
      initInputs.userRole === inputs.userRole //added role
    ) {
      return true;
    }

    Object.keys(inputs).forEach(key => {
      if (!inputs[key]) {
        delete inputs[key];
      }
    });
    
    await API.patch('AccountGatewayApi', `/account/${accountId}/profile/${profileId}`, {
      body: {role: inputs.userRole},
      withCredentials: true
    });
    
  } catch (err) {
    logError("useProfile.js: Error occurred while updating profile (3)", err);
    showModal("Save User", "Error occurred while saving user. (6)");
    return false;
  }

  return true;
};

// Update a Profile record.
const updateUser = async (
  profileId,
  initInputs,
  inputs,
  showModal,
  setListUsers,
  accountId,
  updateAccount,
  setModalOpen
) => {

  // Update the profile
  if (!(await updateProfile(profileId, initInputs, inputs, showModal, accountId))) return;

  // If everything updated successfully, then go back to the dashboard.
  setModalOpen(false);
  showModal("Save User", "Save Successful");
  updateAccount(true);
};

const deactivateUser = async (accountId, profileId, showModal, updateAccount) => {
  try {
    
    const removedProvile = await API.del('AccountGatewayApi', `/account/${accountId}/profile/${profileId}`, { withCredentials: true });
    logInfo("useProfile adminDisableUser successful", removedProvile); // successful response
    updateAccount(true);
    
  } catch (err) {
    logError("useProfile.js deactivateUser Error", err);
    showModal(
      "Deactivate User",
      "An error occurred while deactivating user."
    );
  }
};

export default useEditProfile;
