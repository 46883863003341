import { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";
import { logError } from "util/logging";
import { strNonEmpty } from "util/strings";
import { ModalContext } from "contexts/Modal";

const emptyInputs = {
  name: "",
  brand: ""
};

const validateInputs = (inputs, showModal) => {
  if (!strNonEmpty(inputs.name)) {
    showModal("Save Account", "Name cannot be empty.");
    return false;
  }

  return true;
};

const saveAccount = async (accountId, inputs, showModal, setSaving) => {
  try {
    await API.patch('AccountGatewayApi', `/account/${accountId}`, {
      body: {
        name: inputs.name,
        brand: inputs.brand
      },
      withCredentials: true
    });
    setSaving(false);
    showModal('Account Save', 'Account successfully updated.');
  } catch (err) {
    console.error('error updating account', err);
    setSaving(false);
    showModal('Account save unsuccessful', 'Error occurred while saving account.');
  }
};

const useAccountAdminAccount = (accountId, account) => {
  const [inputs, setInputs] = useState(emptyInputs);
  const [availableBrands, setBrands] = useState([]);
  const [saving, setSaving] = useState(false);
  const { showModal } = useContext(ModalContext);

  useEffect(() => {
    if (account.id) {
      setInputs({
        ...account,
        brand: account.brand.name
      });
    }
  }, [accountId, account]);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const brands = await API.get('AccountGatewayApi', '/brands', { withCredentials: true });
        setBrands(brands.map(brand => brand.name));
      } catch (err) {
        console.error('error getting brands', err);
      }
    };
    getBrands();
  }, []);

  const handleSave = event => {
    if (event) {
      event.preventDefault();
    }
    setSaving(true);
    try {
      // Validate the inputs
      if (!validateInputs(inputs, showModal)) return;

      saveAccount(accountId, inputs, showModal, setSaving);
    } catch (err) {
      logError(
        "AccountAdminAccount-logic.js: Error occurred while saving account.",
        err
      );
      showModal("Save Account", "Error occurred while saving account.");
    }
  };

  return {
    inputs,
    setInputs,
    handleSave,
    availableBrands,
    saving
  };
};

export default useAccountAdminAccount;
