import React, { memo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classes from "./UserAccount.module.scss";

const UserAccountView = ({ account, history }) => {
  return (
      <div className={`pt-2 ${classes["account-row"]}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <span className="white-text">
                <Link to={`/AccountAdmin/${account.accountId}`}>{account.name}</Link>
              </span>
            </div>
            <div className="col">
              <span>{account.brand}</span>
            </div>
            <div className="col">
              <span>{account.role}</span>
            </div>
          </div>
        </div>
      </div>
  );
};

UserAccountView.propTypes = {
  account: PropTypes.shape({
    accountId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    brand: PropTypes.string,
    role: PropTypes.string
  }).isRequired,
  history: PropTypes.object.isRequired
};

export default memo(UserAccountView);
