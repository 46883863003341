import React from "react";
import PropTypes from "prop-types";
import GenericModalView from "./GenericModal-view";

/* eslint react/no-children-prop: 0 */

/**
 * Control the creation of the GenericModal component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const GenericModalContainer = ({
  title,
  onSave,
  onCancel,
  children,
  modalOpen,
  modalWidth,
  headerStyle = {},
  footerStyle = {},
  disabled
}) => {
  return (
    <GenericModalView
      title={title}
      children={children}
      onSave={onSave}
      onCancel={onCancel}
      modalOpen={modalOpen}
      headerStyle={headerStyle}
      footerStyle={footerStyle}
      modalWidth={modalWidth}
      disabled={disabled}
    />
  );
};

GenericModalContainer.defaultProps = {
  headerStyle: {},
  footerStyle: {},
  modalWidth: ""
};

GenericModalContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  headerStyle: PropTypes.any,
  footerStyle: PropTypes.any,
  modalWidth: PropTypes.string
};

export default GenericModalContainer;
