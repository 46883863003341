import React from "react";
import ScopesView from "./Scopes-view";
import useScopes from "./Scopes-logic";

const ScopesContainer = () => {
  const {
    scopes,
    setScopes,
    handleCreateScope,
    handleRemoveScope,
    handleScopeChange
  } = useScopes();

  return (
    <ScopesView
      scopes={scopes}
      setScopes={setScopes}
      handleCreateScope={handleCreateScope}
      handleRemoveScope={handleRemoveScope}
      handleScopeChange={handleScopeChange}
    />
  );
};

export default ScopesContainer;
