import React from "react";
import PropTypes from "prop-types";
import ContractDetailsView from "./ContractDetails-view";
import useContractDetails from "./ContractDetails-logic";

/**
 * Control the creation of the ContractDetails component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const ContractDetailsContainer = ({ contractId }) => {
  const { inputs, setInputs, handleSave, contract, isSaving, isValidContract, scopesValidity } = useContractDetails(contractId);

  return (
    <ContractDetailsView
      inputs={inputs}
      setInputs={setInputs}
      handleSave={handleSave}
      contract={contract}
      isSaving={isSaving}
      isValidContract={isValidContract}
      scopesValidity={scopesValidity}
    />
  );
};

ContractDetailsContainer.propTypes = {
  contractId: PropTypes.string.isRequired
};

export default ContractDetailsContainer;
