import React from "react";
import BrandAdminView from "./BrandAdmin-view";

/**
 * Control the creation of the BrandAdmin component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */

export const BrandAdminContext = React.createContext({});

const BrandAdminContainer = ({ match }) => {
  const { brandId } = match.params;
  
  return(
    <BrandAdminContext.Provider value={{ brandId }}>
      <BrandAdminView />
    </BrandAdminContext.Provider>
  );
};

export default BrandAdminContainer;
