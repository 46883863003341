import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { API } from "aws-amplify";
import { AccountAdminContext } from "components/AccountAdmin/AccountAdmin-container";
import { ModalContext } from "contexts/Modal";


const terminateContract = async (accountId, contractId, updateAccount, showModal) => {
    try {
      await API.del('AccountGatewayApi', `/account/${accountId}/contract/${contractId}`, { withCredentials: true });
      updateAccount(true);
      showModal("Terminate Contract", "Contract successfully terminated.");
    } catch (err) {
      console.error('error terminating contract', err);
      showModal("Terminate Contract", "Error terminating contract.");
    }
};

const useAccountAdminContract = (contractId, accountId) => {
  const history = useHistory();
  const { showModal, showConfirm } = useContext(ModalContext);
  const { updateAccount } = useContext(AccountAdminContext);

  const manageContract = () => {
    history.push(`/ContractAdmin/${contractId}`);
  };
  
  const handleTerminate = async (event) => {
    
    if (event) {
      event.preventDefault();
    }
    
    try {
      showConfirm(
        "Terminate contract",
        "Do you really want to terminate the contract?",
        () => {
          terminateContract(accountId, contractId, updateAccount, showModal);
        }
      );
    } catch (err) {
      console.error(
        "AccountAdminContract-logic.js handleTerminate error: Unknown error",
        err
      );
    }
  };
  
  return {
    manageContract, terminateContract: handleTerminate
  };
};

export default useAccountAdminContract;
