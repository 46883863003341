import React from "react";
import { accountManagePropTypes } from "constants/index";
import { useHistory } from "react-router-dom";
import UserAdminAccountView from "./UserAdminAccount-view";
import useUserAdminAccount from "./UserAdminAccount-logic";

const UserAdminAccountContainer = ({ account }) => {
  const history = useHistory();
  const {
    setModalOpen,
    modalOpen,
    inputs,
    setInputs,
    handleSaveProfile,
    initInputs,
    updateUser,
    loggedInUserProfile,
    availableRoles,
    userProfile
  } = useUserAdminAccount(account);
  return (
    <UserAdminAccountView
      account={account}
      history={history}
      userProfile={userProfile}
      setModalOpen={setModalOpen}
      modalOpen={modalOpen}
      inputs={inputs}
      setInputs={setInputs}
      handleSaveProfile={handleSaveProfile}
      initInputs={initInputs}
      updateUser={updateUser}
      loggedInUserProfile={loggedInUserProfile}
      availableRoles={availableRoles}
    />
  );
};

UserAdminAccountContainer.propTypes = {
  account: accountManagePropTypes.isRequired
};

export default UserAdminAccountContainer;
