import AccountAdminContract from "./AccountAdminContract-container";

/**
 * Export the top level component AccountAdminContract-container
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export default AccountAdminContract;
