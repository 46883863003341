import React from "react";
import DashboardView from "./Dashboard-view";

/**
 * Control the creation of the Dashboard component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const DashboardContainer = () => {
  return <DashboardView />;
};

export default DashboardContainer;
