import React, { memo } from "react";
import PropTypes from "prop-types";

const AccountAdminContractView = ({ name, manageContract, terminateContract, dates, products }) => {
  return (
    <div className="w-100 row pt-3 pb-3">
      <div className="col-3">
        <span>{dates}</span>
      </div>
      <div className="col-3">
        <span>{products}</span>
      </div>
      <div className="col">
      
      </div>
      <div className="col-auto pl-0 pr-2 m-0">
        <button
          type="button"
          className="button-primary float-right"
          onClick={manageContract}
        >
          Manage
        </button>
      </div>
      <div className="col-auto p-0 m-0">
        <button
          type="button"
          className="button-red  float-right"
          onClick={terminateContract}
        >
          Terminate
        </button>
      </div>
      
    </div>
  );
};

AccountAdminContractView.propTypes = {
  name: PropTypes.string.isRequired,
  manageContract: PropTypes.func.isRequired
};

export default memo(AccountAdminContractView);
