import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [isModalVisible, setModalIsVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [hasModalShown, setHasModalShown] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState();

  const showModal = (title, msg) => {
    setModalTitle(title);
    setModalMsg(msg);
    setIsConfirm(false);
    setConfirmCallback(null);
    setModalIsVisible(true);
  };

  const showConfirm = (title, msg, callback) => {
    setModalTitle(title);
    setModalMsg(msg);
    setIsConfirm(true);
    setConfirmCallback(() => callback);
    setModalIsVisible(true);
  };

  const onHide = () => setModalIsVisible(false);

  // Make the context object:
  const usersContext = {
    isModalVisible,
    setModalIsVisible,
    modalTitle,
    setModalTitle,
    modalMsg,
    setModalMsg,
    showModal,
    onHide,
    hasModalShown,
    setHasModalShown,
    showConfirm,
    isConfirm,
    confirmCallback
  };

  // pass the value in provider and return
  return <Context.Provider value={usersContext}>{children}</Context.Provider>;
};

Provider.propTypes = {
  children: PropTypes.object.isRequired
};

export const { Consumer } = Context;
