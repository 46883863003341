import React from "react";
import BrandAccountsView from "./BrandAccounts-view";
import useBrandAccounts from "./BrandAccounts-logic";

const BrandAccountsContainer = () => {
  const {
    accounts,
    modalOpen,
    setModalOpen,
    inputs,
    setInputs,
    handleSaveAccount,
    brandId,
    updateBrandAccounts,
    handleDeactivateAccount
  } = useBrandAccounts();
  
  return (
    <BrandAccountsView
      accounts={accounts}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      inputs={inputs}
      setInputs={setInputs}
      handleSaveAccount={handleSaveAccount}
      brandId={brandId}
      updateBrandAccounts={updateBrandAccounts}
      handleDeactivateAccount={handleDeactivateAccount}
    />
  );
  
};

export default BrandAccountsContainer;
