import React, { memo } from "react";
import PropTypes from "prop-types";
import handleInputChange from "util/forms";
import classes from "./BrandDetails.module.scss";

/**
 * Pure component that displays the BrandDetails component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const BrandDetailsView = ({ inputs, setInputs }) => {
  return (
    <div className={classes.brand}>
      <div className={classes["brand-header"]}>
        <div className={`bar-text ${classes["brand-header-name"]}`}>User</div>
      </div>
      <div className={classes["brand-content"]}>
        <div className="container-fluid">
          <div className="row pt-3">
            <div className="col-md-2 pr-0">
              <span className="float-right pt-2">Name</span>
            </div>
            <div className="col-md-10">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control-plaintext border rounded w-75 pl-2"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                  value={inputs.name}
                  onChange={e => handleInputChange(e, setInputs)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BrandDetailsView.propTypes = {
  inputs: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string
  }).isRequired,
  setInputs: PropTypes.func.isRequired
};

export default memo(BrandDetailsView);
