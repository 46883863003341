import { useState, useEffect, useContext } from "react";
import { API } from "aws-amplify";
import { ModalContext } from "contexts/Modal";

const emptyInputs = {
    agreement: '',
    datesStart: '',
    datesEnd: '',
    scopes: []
};

async function fetchContract(contractId, setContract, setInputs, reloadContract, setScopesValidity) {
    try {
        const contractData = await API.get('AccountGatewayApi', `/contract/${contractId}`, { withCredentials: true });
        setContract(contractData);
        setInputs({
            agreement: contractData.agreement || '',
            datesStart: contractData.dates.start ? new Date(contractData.dates.start) : null,
            datesEnd: contractData.dates.end ? new Date(contractData.dates.end) : null,
            scopes: contractData.scopes
        });
        setScopesValidity(contractData.scopes.map(scope => true));
        reloadContract(false);
    } catch (err) {
        console.error('error fetching contract', err);
    }
}

async function patchContract(contractId, patchObj, setContract, updateContract, showModal, setIsSaving) {
    try {
        setIsSaving(true);
        const updatedContract = await API.patch('AccountGatewayApi', `/contract/${contractId}`, {
          body: patchObj,
          withCredentials: true
        });
        setContract(updatedContract);
        updateContract(false);
        setIsSaving(false);
        showModal('Contract save successful', 'Contract updated.');
    } catch (err) {
        console.log('error updating contract', err);
        setIsSaving(false);
        showModal('Contract save unsuccessful', 'Error occurred while saving contract.');
    }
}

function validateInputs(inputs, showModal) {
    if (inputs.datesStart && inputs.datesEnd) {
        if (inputs.datesStart.valueOf() > inputs.datesEnd.valueOf()) {
            showModal("Save Account", "End date must be after start date.");
            return false;
        }
    }
    //TODO: evaluate if other validation rules are needed
    return true;
}

const useContractAdmin = (contractId) => {
    const [contract, setContract] = useState({});
    const [inputs, setInputs] = useState(emptyInputs);
    const [shouldReloadContract, reloadContract] = useState(false);
    const [shouldUpdateContract, updateContract] = useState(false);
    const [isSaving, setIsSaving ] = useState(false);
    const { showModal } = useContext(ModalContext);
    const [scopesValidity, setScopesValidity] = useState([]);
    
    useEffect(() => {
        if (contractId) {
            fetchContract(contractId, setContract, setInputs, reloadContract, setScopesValidity);
        }
    }, [contractId, shouldReloadContract]);
    
    useEffect(() => {
        if (contractId && shouldUpdateContract ) {
            let patchObj = {
                agreement: '',
                dates: {
                    start: null,
                    end: null
                },
                scopes: []
            };
            Object.keys(inputs).forEach(key => {
                switch (key) {
                    case 'agreement':
                        patchObj.agreement = inputs.agreement;
                        break;
                    case 'datesStart':
                        patchObj.dates.start = inputs.datesStart || contract.dates.start;
                        break;
                    case 'datesEnd':
                        patchObj.dates.end = inputs.datesEnd || contract.dates.end;
                        break;
                    case 'scopes':
                        patchObj.scopes = inputs.scopes || contract.scopes;
                        break;
                    default:
                        return;
                }
            });
            patchContract(contractId, patchObj, setContract, updateContract, showModal, setIsSaving);
        }
    }, [
        shouldUpdateContract, contract, contractId, inputs, showModal
    ]);
    
    function handleSave(event) {
        if (event) {
            event.preventDefault();
        }
        
        try {
            // Validate the inputs
            if (!validateInputs(inputs, showModal)) return;
            
            updateContract(true);
            
        } catch (err) {
            console.error(
            "ContractAdmin-logic.js: Error occurred while saving contract.",
            err
            );
            showModal("Save Contract", "Error occurred while saving contract.");
        }
    }
    
    return {
        contract,
        reloadContract,
        updateContract,
        inputs,
        setInputs,
        handleSave,
        isSaving,
        scopesValidity,
        setScopesValidity
    };
};

export default useContractAdmin;