import React from "react";
import BrandDetailsView from "./BrandDetails-view";
import useBrandDetails from "./BrandDetails-logic";

/**
 * Control the creation of the BrandDetails component
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
const BrandDetailsContainer = () => {
  const { inputs, setInputs, handleSave, handleDeactivate } = useBrandDetails();

  return (
    <BrandDetailsView
      inputs={inputs}
      setInputs={setInputs}
      handleSave={handleSave}
      handleDeactivate={handleDeactivate}
    />
  );
};

export default BrandDetailsContainer;
