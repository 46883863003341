import UserDetails from "./UserDetails-container";

/**
 * Export the top level component UserDetails-container
 *
 * @version 0.0.1
 * @author Steve Hunley
 *
 */
export default UserDetails;
