import AccountAdminConfigs from "./AccountAdminConfigs-container";

/**
 * Export the top level component AccountAdminConfigs-container
 *
 * @version 0.0.1
 * @author Joshua Lloyd
 *
 */
export default AccountAdminConfigs;
